import React from 'react';
import { useLocation } from 'react-router-dom';
import NewPassword from './NewPassword'; 
import EmailVerified from './EmailVerified'; 

const AuthPortal = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');

    switch (mode) {
        case 'resetPassword':
            return <NewPassword />;
        case 'verifyEmail':
            return <EmailVerified />;
        default:
            return <div>Invalid action. Please check the link and try again.</div>;
    }
};

export default AuthPortal;
