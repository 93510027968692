import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleToast } from "../Actions/ToggleToast";
import { auth } from '../Authentication/firebase';
import { clearUserEmail } from '../Actions/userActions'
import { withRouter } from 'react-router-dom';

class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogoutModalVisible: false
        };
    }

    toggleLogoutModal = () => {
        this.setState(prevState => ({
            isLogoutModalVisible: !prevState.isLogoutModalVisible
        }));
    }

    handleLogout = () => {
        auth.doSignOut()
            .then(() => {
                localStorage.removeItem('isLoggedIn');
                this.toggleLogoutModal(); 
                this.props.onLogout();
                this.props.history.push('/');
            })
            .catch((error) => {
                console.error('Logout Error:', error);
                
            });
    }

    navigateToUpdateProfile = () => {
        this.toggleLogoutModal(); 
        this.props.history.push('/profile');
    }

    render() {
        const displayName = (this.props.firstName !== 'None' && this.props.lastName !== 'None') ? `${this.props.firstName} ${this.props.lastName}` : this.props.email;
        return (
            <>
                <div className="logout-wrapper">
                    {this.state.isLogoutModalVisible && (
                        <div className="login-modal">
                            <button className='login-button' onClick={this.navigateToUpdateProfile}>My Profile</button>
                            <button className='login-button' onClick={this.handleLogout}>Logout</button>
                            
                        </div>
                    )}
                    <button className='profile-button' onClick={this.toggleLogoutModal}>
                        <div className='avatar-icon' />
                        {displayName}
                    </button>
                </div>
            </>    
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    toggleToast: (toastInfo) => dispatch(toggleToast(toastInfo))
});

export default withRouter(connect(null, mapDispatchToProps)(Logout));
