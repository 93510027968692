import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleToast } from "../Actions/ToggleToast";
import { auth } from '../Authentication/firebase';
import { withRouter } from 'react-router-dom';

class ResetPassword extends Component {
    state = {
        email: '',
    };

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = () => {
        const { email } = this.state;
        auth.doPasswordReset(email)
            .then(() => {
                this.props.toggleToast({ show: true, type: "success", message: "SUCCESS: Password reset email sent!" });
                this.props.history.push('/login'); // Redirect to the login page
            })
            .catch((error) => {
                console.error('Password Reset Error:', error);
                this.props.toggleToast({ show: true, type: "error", message: "ERROR: Error occurred during pasword reset, please try again." });
            });
    };

    render() {
        const { email } = this.state;
        return (
            <div className="login-page">
                <div className="login-container">
                    <div className='elipse'>
                        <div className='login-logo' />
                    </div>
                    <h1>Reset your password</h1>
                    <p>Enter your email address and we will send you intructions to reset your password</p>
                    <input
                        type="email" 
                        placeholder="Enter your email" 
                        name="email" 
                        value={email} 
                        onChange={this.handleInputChange} 
                    />
                    <button onClick={this.handleSubmit}>Send Reset Link</button>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    toggleToast: (options) => dispatch(toggleToast(options)),
});

export default withRouter(connect(null, mapDispatchToProps)(ResetPassword));
