import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { connect } from 'react-redux';
import CustomToastContainer from "./SharedComponents/CustomToastContainer";
import LoadingModal from "./SharedComponents/LoadingModal";
import { ScrollProvider } from './App/ScrollContext';


class App extends Component {
    scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    };
    render(){
        return(
            <ScrollProvider>
                <Router>
                    <div>
                        <LoadingModal name="loadingModalUploadedPictures" header="Uploading your picture..." message="Uploading your picture..." noClose/>
                        <LoadingModal name="loadingModalSavingCSVUpload" header="Saving file details..." message="Saving file details......" noClose/>
                        <div className="main">
                            <div>
                                <CustomToastContainer />
                                <Routes />
                            </div>
                        </div>
                    </div>
                </Router>
            </ScrollProvider>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(App);
