import React, {Component} from 'react'
import { withRouter } from 'react-router-dom';
import API_Root from '../Constants/API_Root';
import { ColorRing } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

class CheckoutSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerId: '',
            email: '',
            planType: '',
            planStartDate: '',
            loading: true,
        };
    }
    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search);
        const email = queryParams.get('email');
        const planType = queryParams.get('planType');
        const planStartDate = queryParams.get('planStartDate');
        const customerId = queryParams.get('customerId');
        console.log(customerId)

        // Update state with the query parameters
        this.setState({ customerId, email, planType, planStartDate });
        this.storeCheckoutInformation({ customerId, email, planType, planStartDate });
    }
    storeCheckoutInformation = (checkoutInfo) => {
        fetch(API_Root + 'store-checkout-information', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(checkoutInfo),
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            this.setState({loading: false});
        })
        .catch(error => {
            console.error('Error storing checkout information:', error)
            this.setState({loading: false});
        });
    };
    render() {
        const { email, planType, planStartDate, loading } = this.state;
        return (
            <>
            <div className="verify-page">
                <div className="verify-container">
                    <div className='elipse'>
                        <div className='login-logo' />
                    </div>
                    {loading ? (
                        <>
                            <h1>Processing Checkout...</h1>
                            <p>We are processing your checkout information..</p>
                            <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#3E826D', '#3E826D', '#3E826D', '#3E826D', '#3E826D']}
                            />
                        </>
                    ) : (
                        <>
                            <h1>Checkout successfully completed!</h1>
                            <p>Click the button below to continue</p>
                            <Link to="/chat" style={{ color: 'white' }} className="verify-container-button">Continue</Link>
                        </>
                    )}
                </div>
            </div>
            </>
        )
    }
}

export default CheckoutSuccess