import React, {Component} from 'react'
import { Link } from 'react-router-dom';


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth <= 768,
            showGetStartedButton: false, 
        };
    }

    updateMedia = () => {
        this.setState({ isMobile: window.innerWidth <= 768 });
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateMedia);
        this.updateMedia(); // Initial check

        this.setState({
            showGetStartedButton: this.shouldShowGetStartedButton() // Set based on the condition
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateMedia);
    }

    shouldShowGetStartedButton = () => {
        const today = new Date();
        const targetDate = new Date("2024-04-24");
        return today >= targetDate;
    };
    
    scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    render() {
        const { isMobile, showGetStartedButton } = this.state;
        return (
            
            <div className='footer'>
                <div className='footer-container'>
                    <div className='footer-row'>
                        <div className='footer-logo-item'>
                            
                            <div className='footer-logo-row'>
                                <div className='logo' />
                                <div className='logo-text'><Link to="/" style={{ color: '#3E826D' }}>E&S Solutions</Link></div>
                            </div>
                        </div>
                        <div className='sponsor-logo' />
                        {!isMobile && (
                            <>
                                <div className='footer-nav-item'>
                                    <div><Link to="/about-us" onClick={this.scrollToTop}>About E&S Solutions</Link></div>
                                    <div><Link to="/resources" onClick={this.scrollToTop}>Resources</Link></div>
                                    <div><Link to="/pricing" onClick={this.scrollToTop}>Pricing</Link></div>
                                </div>
                                <div className='footer-nav-item'>
                                    <div><Link to="/faqs" onClick={this.scrollToTop}>FAQs</Link></div>
                                    <div><Link to="/terms-policies" onClick={this.scrollToTop}>Terms & Policies</Link></div>
                                    <div><Link to="/privacy-policy" onClick={this.scrollToTop}>Privacy Policy</Link></div>
                                </div>
                            </>
                        )}
                        <div className='footer-logo-item'>
                            <div className='footer-logo-row'>
                                <button className='footer-button'><Link to="/contact-us" onClick={this.scrollToTop}>Contact Us</Link></button>
                            </div>
                        </div>
                    </div>
                    {isMobile && (
                        <div className='footer-links-container'>
                            <div className='footer-links-row'>
                                <div className='footer-nav-item'>
                                    <div><Link to="/faqs" onClick={this.scrollToTop}>FAQs</Link></div>
                                    <div><Link to="/terms-policies" onClick={this.scrollToTop}>Terms & Policies</Link></div>
                                    <div><Link to="/privacy-policy" onClick={this.scrollToTop}>Privacy Policy</Link></div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='footer-copyright-row'>© 2024 Environmental & Social Research Solutions Limited. All rights reserved.</div>
                </div>
            </div>
               
        )
    }
}

export default Footer