import React, {Component} from 'react';
import {connect} from "react-redux";
import {push} from "react-router-redux";

class NotFound extends Component {
    constructor(props){
        super(props);

        this.goToHomepage = this.goToHomepage.bind(this);
    }

    goToHomepage(e){
        this.props.navigateTo("/")
    }

    render() {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-3"> </div>
                        <div className="col-sm-6">
                            <h3>Oops! Looks like this page cannot exist.</h3>
                            <br/><br/><br/>
                            <h3 onClick={this.goToHomepage} className="thinHeading linkStyle">Homepage</h3>
                            <br/><br/><br/><br/><br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapActionsToProps = {
    navigateTo: push,
}

export default connect(null,mapActionsToProps)(NotFound);
