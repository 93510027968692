// API for testing or Development

const browserDomain = window.location.origin;

let API_Root;

if (browserDomain.includes("localhost")){
    API_Root = "http://127.0.0.1:80/";
}
else{
    //API_Root = "http://almasi-backend-env.eba-p5n7gdhb.us-east-1.elasticbeanstalk.com/";
    API_Root = "https://api.essolutions.ai/";
}

export default API_Root