import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

class TermsConditions extends Component {
    scrollTo = (id) => {
        const offset = 300; // Adjust this value to match your navbar's height plus some extra space
        const section = document.getElementById(id);
        const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
    
        window.scrollTo({
            top: sectionPosition - offset,
            behavior: 'smooth'
        });
    };

    render() {
        return (
            <div className='faq-grid-layout'>
               <Navbar />
               <div className='top-spacer' />
               <div className='banner'>
                    <div className='banner-container'>
                        <h1>Terms & Policies</h1>
                        <p></p>
                    </div>
               </div>
               <div className='mainsection'>
                    <div className='terms-container'>
                        <div className="terms-nav">
                            <a href="#definitions" onClick={() => this.scrollTo('definitions')}> 1. Definitions</a> | 
                            <a href="#services" onClick={() => this.scrollTo('services')}> 2. Services</a> | 
                            <a href="#licence" onClick={(e) => { e.preventDefault(); this.scrollTo('licence'); }}> 3. Licence</a> | 
                            <a href="#intellectualProperty" onClick={() => this.scrollTo('intellectualProperty')}> 4. Intellectual Property Rights</a> | 
                            <a href="#ordering" onClick={() => this.scrollTo('ordering')}> 5. Ordering, Fees, Invoicing and Payments</a> | 
                            <a href="#warranties" onClick={() => this.scrollTo('warranties')}> 6. Warranties</a> | 
                            <a href="#liability" onClick={() => this.scrollTo('liability')}> 7. Liability</a> | 
                            <a href="#indemnities" onClick={() => this.scrollTo('indemnities')}> 8. Indemnities</a> | 
                            <a href="#termination" onClick={() => this.scrollTo('termination')}> 9. Term and Termination</a> | 
                            <a href="#confidentialInfo" onClick={() => this.scrollTo('confidentialInfo')}> 10. Confidential Information</a> | 
                            <a href="#dataProtection" onClick={() => this.scrollTo('dataProtection')}> 11. Data Protection</a> | 
                            <a href="#thirdParties" onClick={() => this.scrollTo('thirdParties')}> 12. Third Parties</a> | 
                            <a href="#forceMajeure" onClick={() => this.scrollTo('forceMajeure')}> 13. Force Majeure</a> | 
                            <a href="#miscellaneous" onClick={() => this.scrollTo('miscellaneous')}> 14. Miscellaneous</a>
                        </div>
                        <h1>TERMS AND CONDITIONS</h1>
                        <p>Please read these Terms and Conditions, carefully before registering for a subscription for the myESRA services offered on this website operated by Environmental and Social Research Solutions Limited of Trident Park, Notabile Gardens, No. 2 - Level 3, Mdina Road, Zone 2, Central Business District, Birkirkara CBD 2010, Malta a company registered in Malta with registered company number, C 107883, VAT number, MT30901933.</p>
                        <p>By completing the online registration form for a chargeable subscription for the Services at <Link to="/">https://www.essolutions.ai</Link> and clicking on the accept buttons relating to our <Link to="/terms-policies">Terms and Conditions</Link>, <Link to="/data-processing">DPA</Link> and <Link to="/privacy-policy">Privacy Policy</Link>, you the Customer agree to be legally bound by these Terms and Conditions, DPA and Privacy Policy as they may be modified and posted on our website from time to time. In the event of any inconsistency between the content of the Terms and Conditions, DPA and the Privacy Policy, the Terms and Conditions shall prevail followed by the DPA, and then the Privacy Policy.</p>
                        <p>If you do not wish to be bound by these Terms and Conditions, DPA and Privacy Policy then you may not register to purchase a licence to access and use our Services.</p>
                        <div id="definitions">
                            <h2>1. Definitions</h2>
                        </div>
                        <p>In this Agreement, the following words shall have the following meanings:</p>
                        <div class="terms-row">
                            <h1>"Agreement"</h1>
                            <p>means these Terms and Conditions, DPA and Privacy Policy together with an Order Form;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Business Day"</h1>
                            <p>means Monday to Friday (excluding any national holiday in Malta) from 9 am to 5 pm (CET);</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Effective Date"</h1>
                            <p>means the date this Agreement starts, as set out in the Order Form;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Company"</h1>
                            <p>means Environmental and Social Research Solutions Limited;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Confidential Information"</h1>
                            <p>means any and all information in whatsoever form relating to the Company or the Customer, or the business, prospective business, finances, technical processes, computer software (both source code and object code), Intellectual Property Rights or finances of the Company or the Customer (as the case may be), or compilations of two or more items of such information, whether or not each individual item is in itself confidential, which comes into a party’s possession by virtue of its entry into this Agreement or provision of the Services, and which the party regards, or could reasonably be expected to regard, as confidential and any and all information which has been or may be derived or obtained from any such information;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Content"</h1>
                            <p>means the online knowledge database of the Company accessed via the Services;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Customer Data"</h1>
                            <p>means all data imported into the Services by the Customer or its users for the purpose of using the Services or facilitating the Customer’s use of the Services;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Customer"</h1>
                            <p>means the company or person named in the Order Form;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"DPA"</h1>
                            <p>means the data processing agreement of the Company published at: <Link to="/data-processing">https://www.essolutions.ai/data-processing</Link>, as amended from time to time;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Feedback"</h1>
                            <p>means feedback, innovations or suggestions provided by the Customer or users regarding the attributes, performance, suggested improvements or features of the Services;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Fees"</h1>
                            <p>means the fees set out in the Order Form charged for the Services purchased, calculated as set out in the Price List;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Force Majeure"</h1>
                            <p>means anything outside the reasonable control of a party, including but not limited to, acts of God, fire, storm, flood, earthquake, explosion, accident, acts of the public enemy, war, rebellion, insurrection, sabotage, epidemic, pandemic, quarantine restriction, labour dispute, labour shortage, power shortage, including without limitation where Company ceases to be entitled to access the Internet for whatever reason, transportation embargo, failure or delay in transportation, any act or omission (including laws, regulations, disapprovals or failures to approve) of any government or government agency;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Intellectual Property Rights"</h1>
                            <p>means all copyrights, patents, utility models, trademarks, service marks, registered designs, moral rights, design rights (whether registered or unregistered), technical information, know-how, database rights, semiconductor topography rights, business names and logos, computer data, generic rights, proprietary information rights and all other similar proprietary rights (and all applications and rights to apply for registration or protection of any of the foregoing) as may exist anywhere in the world;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Order Form"</h1>
                            <p>means the online order confirmation sent by email to the Customer, after the Customer registers online for a subscription to use the Services;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Price List"</h1>
                            <p>means the online price list of the Company, published at: <Link to="/pricing">https://www.essolutions.ai/pricing</Link>, as amended from time to time, setting out the Fees charged for the Services;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Privacy Policy"</h1>
                            <p>means the privacy policy of the Company published at: <Link to="/privacy-policy">https://www.essolutions.ai/privacy-policy</Link>, as amended from time to time;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Renewal Term"</h1>
                            <p>means the renewal term set out in the Order Form;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Services"</h1>
                            <p>means the software applications services of the Company, ordered online by the Customer and set out in the Order Form sent to the Customer which are made available to the Customer in accordance with the terms of this Agreement, including any computer software programmes and, if appropriate, Updates thereto;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Statistical Data"</h1>
                            <p>means aggregated, anonymised data derived from the Customer or user’s use of the Services which does not include any personal data or Customer Confidential Information;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Term"</h1>
                            <p>means all Renewal Terms together;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Terms and Conditions"</h1>
                            <p>means these terms and conditions of the Company, published at: <Link to="/terms-policies">https://www.essolutions.ai/terms-policies</Link>, as amended from time to time;</p>
                        </div>
                        <div class="terms-row">
                            <h1>"Updates"</h1>
                            <p>means any new or updated applications services or tools (including any computer software programmes) made available by the Company as part of the Services.</p>
                        </div>
                        
                        <div id="services">
                            <h2>2. Services</h2>
                        </div>
                        
                        <div className='terms-services-row'>
                            <h1>2.1</h1>
                            <p>The Customer engages the Company and the Company agrees to provide the Services and Content to the Customer from the Effective Date for the Term.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>2.2</h1>
                            <p>The Services and Content shall be made available to the Customer in accordance with and subject to the provisions of this Agreement.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>2.3</h1>
                            <p>Users are responsible for maintaining the confidentiality of their login information and ensuring that unauthorised access to the Services is prevented.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>2.4</h1>
                            <p>Use and access to the Services and Content is restricted to the registered user of each licence. No user logins or credentials may be shared. All licences are personal to each registered user and cannot be transferred or used by anyone other than the registered user.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>2.5</h1>
                            <p>Use of the Services and Content is subject to “fair use”. Where the Company acting reasonably determines that there is excessive usage of the Services or Content the Company may: (i) place restrictions on access and use of the Services or Content. Excessive usage means for these purposes, where the Company determines that the Customer's use of the Services or Content places excessive strain on the system, either in isolation or relative to usage by similar customers. Where there is excessive use of the Services or Content, the Company reserves the right to take corrective action, which includes but is not limited to service throttling or temporary suspension of the Services or access to the Content.</p>
                        </div>

                        <div id="licence">
                            <h2>3. Licence</h2>
                        </div>
                        <div class="terms-license-row">
                            <h1>3.1</h1>
                            <p>Subject to payment of the Fees, the Customer is granted a non-exclusive, non-transferable, worldwide, revocable licence to use the Services (including any associated software, Intellectual Property Rights and Confidential Information of the Company and Content) during the Term. Such licence permits the Customer to make cache copies of software or other information as are required for the Customer to receive the Services via the Internet. Where open source software is used as part of the Services, such software use by the Customer will be subject to the terms of the open source licences.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>3.2</h1>
                            <p>No right to modify, adapt, or translate the Services or Content or create derivative works from the Services or Content is granted to the Customer. Nothing in this Agreement shall be construed to mean, by inference or otherwise, that the Customer has any right to obtain source code for the software comprised within the Services.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>3.3</h1>
                            <p>Disassembly, decompilation or reverse engineering and other source code derivation of the software comprised within the Services is prohibited. To the extent that the Customer is granted the right by law to decompile such software in order to obtain information necessary to render the Services interoperable with other software (and upon written request by the Customer identifying relevant details of the Services(s) with which interoperability is sought and the nature of the information needed), the Company will provide access to relevant source code or information. The Company has the right to impose reasonable conditions including but not limited to the imposition of a reasonable fee for providing such access and information.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>3.4</h1>
                            <p>Unless otherwise specified in this Agreement, the Services and Content are provided and may be used solely by the Customer and its authorised users as part of the Customer’s website/desktop architecture. Except as specifically stated in this Agreement, the Customer may not: (i) lease, loan, resell or otherwise distribute the Services or Content save as permitted in writing by the Company; (ii) use the Services to provide ancillary services related to the Services; or (iii) permit access to or use of the Services by or on behalf of any third party.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>3.5</h1>
                            <p>The Customer is not permitted to: (i) scrape i.e., extract information from the Services and Content via any automated process, for example: a bot or webcrawler; or (ii) create a bot or API that integrates to myESRA for automated use or data extraction.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>3.6</h1>
                            <p>The Customer warrants and represents that it shall maintain reasonable security measures (as may change over time) covering, without limitation, confidentiality, authenticity and integrity to ensure that the access to the Services and Content granted under this Agreement is limited as set out under this Agreement.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>3.7</h1>
                            <p>The Company may restrict or suspend access to the Services and Content, or portion thereof, at any time for any reason, in the Company’s sole discretion and the Company shall have no liability to the Customer whatsoever for any claims relating to any restriction or suspension.</p>
                        </div>
                        <div id="intellectualProperty">
                            <h2>4. Intellectual Property Rights</h2>
                        </div>
                        <div class="terms-license-row">
                            <h1>4.1</h1>
                            <p>All Intellectual Property Rights and title to the Services (save to the extent incorporating any Customer Data, or Customer or third party owned item) shall remain with the Company and/or its licensors and subcontractors. No interest or ownership in the Services, Content, the Company’s Intellectual Property Rights or otherwise is transferred to the Customer under this Agreement.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>4.2</h1>
                            <p>The Customer shall retain sole ownership of all rights, title and interest in and to Customer Data and its pre-existing Intellectual Property Rights and shall have the sole responsibility for the legality, reliability, integrity, accuracy and quality of Customer Data. The Customer grants the Company a non-exclusive, licence to use Customer Data, Customer Intellectual Property Rights and any third party owned item from the Effective Date, for the Term, to the extent required for the Company to provide the Services.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>4.3</h1>
                            <p>The Customer is not allowed to remove any proprietary marks or copyright notices from the Services or Content.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>4.4</h1>
                            <p>The Customer grants the Company a non-exclusive, non-transferable, revocable licence to use the Customer’s name, logo and trademarks, as designated and/or amended by the Customer from time to time for the sole purpose of the Company providing the Services.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>4.5</h1>
                            <p>The Customer assigns all rights, title and interest in any Feedback to the Company. If for any reason such assignment is ineffective, the Customer shall grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and licence to use, reproduce, disclose, sub-licence, distribute, modify and exploit such Feedback without restriction.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>4.6</h1>
                            <p>The Customer acknowledges and agrees that the Company may retain the perpetual right to use Statistical Data, including questions and answers, for business and/or operating purposes, even after the termination or expiration of this Agreement. The Customer acknowledges that once the Statistical Data, including questions and answers has been anonymised, it is no longer bound by data protection legislation. However, the Company shall not share with any third party Statistical Data that could potentially reveal the identity of the Customer or the Customer’s Confidential Information. </p>
                        </div>
                        <div class="terms-license-row">
                            <h1>4.7</h1>
                            <p>The Company may take and maintain technical precautions to protect the Services or Content from improper or unauthorised use, distribution or copying.</p>
                        </div>

                        <div id="ordering">
                            <h2>5. Ordering, Fees, Invoicing and Payments</h2>
                        </div>
                        <div class="terms-license-row">
                            <h1>5.1</h1>
                            <p>The Company is entitled to refuse any order placed by a Customer. If an order is accepted, the Company shall confirm acceptance by sending the completed Order Form to the Customer via email.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>5.2</h1>
                            <p>The Company shall charge the Customer the Fees from the Effective Date for each Renewal Term.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>5.3</h1>
                            <p>Fees are calculated based upon the prices set out in the Price List on the date of each invoice issued.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>5.4</h1>
                            <p>Invoices shall be issued to the Customer in the currency set out in the Price List. All invoices shall be payable in full by the Customer. All Fees exclude any Value Added Tax legally (or other applicable sales tax) payable on the date of the invoice, which shall be paid by the Customer in addition, where applicable.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>5.5</h1>
                            <p>The Customer shall invoice all Fees in advance, as set out in the Order Form.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>5.6</h1>
                            <p>All Fees are payable by Stripe (or any other payment provider notified to the Customer during the Term), immediately on the date of each invoice.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>5.7</h1>
                            <p>Where payment of any Fees is not received when due, the Company may, without liability to the Customer, disable the Customer’s password, account and access to all or part of the Services and the Company shall be under no obligation to provide any or all of the Services while the invoice(s) concerned remains unpaid. The Company shall be entitled to charge interest on overdue Fees at the applicable statutory rate.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>5.8</h1>
                            <p>The Company reserves the right to recover any costs and reasonable legal fees it incurs in recovering overdue payments.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>5.9</h1>
                            <p>The Company is entitled to increase Fees upon giving the Customer 30 days prior written notice of changes. Increases shall apply from the start of the next applicable Renewal Term, unless the Customer terminates the Agreement before this date.</p>
                        </div>

                        <div id="warranties">
                            <h2>6. Warranties</h2>
                        </div>
                        <div class="terms-license-row">
                            <h1>6.1</h1>
                            <p>Each party warrants and represents that: (i) it has full corporate power and authority to enter into this Agreement and to perform the obligations required hereunder; (ii) the execution and performance of its obligations under this Agreement does not violate or conflict with the terms of any other agreement to which it is a party and is in accordance with any applicable laws; and (iii) it shall respect all applicable laws and regulations, governmental orders and court orders, which relate to this Agreement.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>6.2</h1>
                            <p>The Company warrants to the Customer that: (i) it has the right to license the Services; (ii) the Services shall be performed with reasonable skill and care; (iii) in performing the Services the Company will not infringe the Intellectual Property Rights of any third party. The foregoing warranties shall not: (a) cover deficiencies or damages relating to any third party components not furnished by the Company; or (b) any third party provided connectivity necessary for the provision or use of the Services.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>6.3</h1>
                            <p>Subject to the above warranties, the Services and Content are provided, “as is” on an “as available” basis and no warranty is made: (i) regarding the results of using the Services or Content; (ii) that the functionality of the Services will meet the requirements of the Customer; or (iii) that the Services will operate uninterrupted or error free.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>6.4</h1>
                            <p>The Customer warrants and represents to the Company that: (i) it rightfully owns the necessary user rights, copyrights and ancillary copyrights and permits required for it to fulfil its obligations under this Agreement; (ii) it shall maintain reasonable security measures (as may change over time) covering, without limitation, confidentiality, authenticity and integrity to ensure that the access to the Services granted under this Agreement is limited as set out under this Agreement. In particular the Customer and users shall treat any identification, password or username or other security device for use of the Services with due diligence and care and take all necessary steps to ensure that they are kept confidential, secure and are used properly and are not disclosed to unauthorised persons. Any breach of the above shall be immediately notified to the Company in writing. The Customer shall be liable for any breach of the terms of this Agreement by its users; and (iii) it shall ensure that its network and systems comply with the relevant specification provided by the Company from time to time and that it is solely responsible for procuring and maintaining its network connections and telecommunications links from the Customer’s systems to the Company’s data centres and all problems, conditions, delays, delivery failures and all other loss or damage arising from or relating to the Customer’s network connections or telecommunications links or caused by the Internet.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>6.5</h1>
                            <p>All Content or information provided by the Company via the Services, is provided “as is”. The Company provides no warranties in relation to such Content or information and shall have no liability whatsoever to the Customer for its use or reliance upon such Content or information. The Services are provided using models developed by OpenAI but the Company confirms that it is not affiliated with or in any way officially connected with OpenAI.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>6.6</h1>
                            <p>Except as expressly stated in this Agreement, all warranties and conditions, whether express or implied by statute, common law or otherwise (including but not limited to satisfactory quality and fitness for purpose), are hereby excluded to the fullest extent permitted by law.</p>
                        </div>

                        <div id="liability">
                            <h2>7. Liability</h2>
                        </div>
                        <div class="terms-license-row">
                            <h1>7.1</h1>
                            <p>Neither party excludes or limits its liability to the other for fraud, death or personal injury caused by any negligent act or omission, or wilful misconduct.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>7.2</h1>
                            <p>In no event shall either party be liable to the other whether arising under this Agreement or in tort (including negligence or breach of statutory duty), misrepresentation or however arising, for any Consequential Loss. (“Consequential Loss” shall for the purposes of this section mean: (i) pure economic loss; (ii) losses incurred by any client of the Customer or other third party; (iii) loss of profits (whether categorised as direct or indirect loss); (iv) losses arising from business interruption; (v) loss of business revenue, goodwill or anticipated savings; (vi) losses whether or not occurring in the normal course of business; or (vii) wasted management or staff time.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>7.3</h1>
                            <p>Subject to clauses 7.1 and 7.2, the total liability of the Company to the Customer in aggregate (whether in contract, tort or otherwise) under or in connection with this Agreement or based on any claim for indemnity or contribution shall be limited to one hundred (100) per cent of the total Fees (excluding any VAT, duty, sales or similar taxes) paid or payable by the Customer to the Company during the twelve (12) month period before the date on which any claim arose. If the duration of the Agreement has been less than twelve (12) months, such shorter period shall apply.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>7.4</h1>
                            <p>The Customer shall be liable for any breaches of this Agreement caused by the acts, omissions or negligence of any users who access the Services as if such acts, omissions or negligence had been committed by the Customer itself.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>7.5</h1>
                            <p>In no event shall the Customer raise any claim under this Agreement more than one (1) year after: (i) the discovery of the circumstances giving rise to such claim; or (ii) the effective date of the termination of this Agreement.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>7.6</h1>
                            <p>The parties acknowledge and agree that in entering into this Agreement, each had recourse to its own skill and judgement and they have not relied on any representation made by the other, its employees or agents.</p>
                        </div>

                        <div id="indemnities">
                            <h2>8. Indemnities</h2>
                        </div>
                        <div class="terms-license-row">
                            <h1>8.1</h1>
                            <p>The Customer shall defend, indemnify and hold the Company and its employees, sub-contractors and agents harmless from and against any cost, losses, fines, liabilities and expenses, including reasonable legal costs arising from any claim relating to or resulting directly or indirectly from: (i) any claimed infringement or breach by the Customer of any Intellectual Property Rights with respect to the Customer’s use of the Services outside the scope of this Agreement; (ii) use by the Company of any Customer Data or Customer or user provided item; (iii) the Customer’s breaches of its obligations under data protection law or regulations or the terms of the DPA; and (iv) any breach of the terms of this Agreement by the Customer’s users.</p>
                        </div>

                        <div id="termination">
                            <h2>9. Term and Termination</h2>
                        </div>
                        <div class="terms-license-row">
                            <h1>9.1</h1>
                            <p>This Agreement starts on the Effective Date and shall automatically renew from the Effective Date for successive Renewal Terms until either party terminates the Agreement in accordance with its rights set out below.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>9.2</h1>
                            <p>The Company may immediately terminate this Agreement and the provision of all Services at any time upon giving written notice to the Customer, if: (i) the Customer has used or permitted the use of the Services in breach of the terms of this Agreement; or (ii) the Company is prohibited, by law from providing the Services; or (iii) the Company has suspended the Services and decides to terminate the Agreement, for any reason.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>9.3</h1>
                            <p>The Customer may terminate this Agreement at any time, by giving written notice prior to the start of any Renewal Term. Such notice shall be effective from the start date of the next applicable Renewal Term.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>9.4</h1>
                            <p>Either party shall be entitled to terminate this Agreement on written notice to the other party if the other party: (i) goes into voluntary or involuntary liquidation (otherwise than for the purpose of a solvent reconstruction or amalgamation) or has a receiver or administrator or similar person appointed or is unable to pay its debts within the meaning of s268 Insolvency Act 1986 or ceases or threatens to cease to carry on business or if any event occurs which is analogous to any of the foregoing in another jurisdiction; or (ii) commits a material breach of any term of this Agreement which, if capable of remedy, is not remedied within seven (7) days of receipt of a written notice specifying the breach and requiring it to be remedied; (iii) is prevented by Force Majeure from fulfilling its obligations for more than seven (7) days.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>9.5</h1>
                            <p>Upon termination of this Agreement: (i) the Company shall immediately cease providing the Services to the Customer and all licences granted hereunder shall terminate; (ii) the Customer shall promptly pay the Company any unpaid Fees for the remainder of the Term. No Fees already paid shall be refunded to the Customer if the Agreement is terminated prior to the end of a Renewal Term; (iii) at the option of the Customer, following receipt of a request from the Customer delete (in accordance with the terms of the DPA) or return all Customer Data stored in the Company’s database in a machine readable format, free of charge, provided that such request is made within 30 days of termination. If the Customer requires any Customer Data to be returned in a different format the Company reserves the right to charge for this additional service on a time and materials basis.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>9.6</h1>
                            <p>Termination of this Agreement for whatever reason shall not affect the accrued rights of the parties. All clauses which by their nature should continue after termination shall, for the avoidance of doubt, survive the expiration or sooner termination of this Agreement and shall remain in force and effect.</p>
                        </div>

                        <div id="confidentialInfo">
                            <h2>10. Confidential Information</h2>
                        </div>
                        <div class="terms-license-row">
                            <h1>10.1</h1>
                            <p>The recipient of any Confidential Information from a disclosing party may only use the disclosing party’s Confidential Information for the purposes of this Agreement and must keep confidential all Confidential Information except to the extent (if any) the recipient of the Confidential Information is required by law to disclose the Confidential Information.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>10.2</h1>
                            <p>The recipient of Confidential Information may disclose the Confidential Information party to its employees and agents who need to know the Confidential Information for the purposes of this Agreement, but only if the employee or agent is bound by a confidentiality undertaking to keep the Confidential Information confidential.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>10.3</h1>
                            <p>Both parties agree to return all documents and other materials containing Confidential Information of the other party immediately upon completion of the Services.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>10.4</h1>
                            <p>The obligations of confidentiality under this Agreement do not extend to information that: (i) 	was rightfully in the possession of the recipient before the negotiations leading to this Agreement took place; (ii) is on the Effective Date, or later becomes public knowledge (other than as a result of the recipient’s breach of this Agreement); or (iii) the recipient is required by law to disclose.</p>
                        </div>

                        <div id="dataProtection">
                            <h2>11. Data Protection</h2>
                        </div>
                        <div class="terms-license-row">
                            <h1>11.1</h1>
                            <p>Each party undertakes to comply with its obligations under relevant applicable data protection laws, principles and agreements.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>11.2</h1>
                            <p>To the extent that personal data is processed when the Customer or it users, use the Services, the parties acknowledge that the Company is a data processor and the Customer is a data controller and the parties shall comply with their respective obligations under applicable data protection law and the terms of the DPA. You as the data controller, are solely responsible for any and all data used and submitted in connection with the Services. When submitting and processing personal details of others, The Company as a data processor, will establish a lawful basis for doing so and will disclose, in a written privacy policy all our processing activities in relation to our and/or similar services. </p>
                        </div>
                        <div class="terms-license-row">
                            <h1>11.3</h1>
                            <p>If a third party alleges infringement of its data protection rights, the Company shall be entitled to take measures necessary to prevent the infringement of a third party’s rights from continuing.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>11.4</h1>
                            <p>Where the Company collects and processes personal data of the Customer, as a data controller, when providing the Services to the Customer, for example when using the Customer’s personal data to issue invoices, such collection and processing shall be in accordance with the Company’s Privacy Policy.</p>
                        </div>

                        <div id="thirdParties">
                            <h2>12. Third Parties</h2>
                        </div>
                        <div class="terms-license-row">
                            <h1></h1>
                            <p>Nothing contained in this Agreement is intended to be enforceable by a third party, who is not a party to this Agreement, under the any statutory right they may have in any applicable jurisdiction.</p>
                        </div>

                        <div id="forceMajeure">
                            <h2>13. Force Majeure</h2>
                        </div>
                        <div class="terms-license-row">
                            <h1>13.1</h1>
                            <p>Excluding an obligation to make payments, if a party is wholly or partially prevented by Force Majeure from complying with its obligations under this Agreement, then that party’s obligation to perform in accordance with this Agreement will be suspended.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>13.2</h1>
                            <p>As soon as practicable after an event of Force Majeure arises, the party affected by the Force Majeure must notify the other party of the extent to which the notifying party is unable to perform its obligations under this Agreement. If the Force Majeure event last for more than 7 days either  party may terminate this Agreement with immediate effect, without penalty.</p>
                        </div>

                        <div id="miscellaneous">
                            <h2>14. Miscellaneous</h2>
                        </div>
                        <div class="terms-license-row">
                            <h1>14.1</h1>
                            <p>Should a provision of this Agreement be invalid or become invalid then the legal effect of the other provisions shall remain unaffected. A valid provision is deemed to have been agreed which comes closest to what the parties intended commercially and shall replace the invalid provision. The same shall apply to any omissions.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>14.2</h1>
                            <p>This Agreement constitutes the whole agreement and understanding between the parties and supersedes all prior agreements, representations, negotiations, and discussions between the parties relating to the subject matter thereof.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>14.3</h1>
                            <p>No party may assign or transfer its rights under this Agreement without the prior written consent of the other party, such consent shall not be unreasonably withheld. However, the Company shall be entitled to assign the Agreement to: (i) any company in the Company’s group of companies; or (ii) any entity that purchases the shares or assets of the Company as the result of a merger, takeover or similar event, who is not a competitor of the Customer.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>14.4</h1>
                            <p>The Company and the Customer are independent contractors and nothing in this Agreement will be construed as creating an employer-employee relationship.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>14.5</h1>
                            <p>Amendments to, or notices to be sent under this Agreement, shall be in writing and shall be deemed to have been duly given if: (i) sent by international courier to a party at the address given for that party in this Agreement; or (ii) to the email address of each party usually used to correspond for invoicing purposes. Notices shall be deemed to have been duly given: (a) on the day they are sent if they are emailed or delivered with a signature of receipt on a Business Day; or (b) if emailed on a non-Business Day, on the next Business Day. Notwithstanding the aforesaid, the Company may change or modify the terms of this Agreement upon giving the Customer 30 days’ notice via email. All changes shall be deemed to have been accepted by the Customer unless the Customer terminates the Agreement prior to the expiry of the 30-day period.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>14.6</h1>
                            <p>Neither party shall make any public statement, press release or other announcement relating to the terms or existence of this Agreement, or the business relationship of the parties, without the prior written consent of the other party. Notwithstanding the aforesaid the Company may use the Customer’s name and trademarks (logo only) to list the Customer as a client of the Company on its website and in other marketing materials and information.</p>
                        </div>
                        <div class="terms-license-row">
                            <h1>14.7</h1>
                            <p>This Agreement shall be governed by the laws of England and Wales. The courts of England shall have exclusive jurisdiction for the settlement of all disputes arising under this Agreement.</p>
                        </div>

                    </div>
               </div>
               <Footer />
            </div>
        )
    }
}

export default TermsConditions