import React, {Component} from 'react'
import Navbar from './Navbar';
import Footer from './Footer';
import BlogCarousel from './BlogCarousel';
import API_Root from '../Constants/API_Root';

class Resources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogItems: [],
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.fetchBlogUpdateItems();
    }
    openUrl = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    fetchBlogUpdateItems = () => {
        fetch(API_Root + 'get-blog-update-items')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ blogItems: data });
            })
            .catch(error => {
                console.error("Error fetching blog update items:", error);
            });
    };
    render() {
        const { blogItems } = this.state;
        return (
            <div className='faq-grid-layout'>
               <Navbar />
               <div className='top-spacer' />
               <div className='banner'>
                    <div className='banner-container'>
                        <h1>Resources</h1>
                    </div>
               </div>
               <div className='mainsection'>
               <BlogCarousel blogItems={blogItems} openUrl={this.openUrl} />
                    <div className='content-container'>
                        <div className='resources-row'>
                            <h5>1. How to Sign Up for myESRA</h5>
                        </div>
                        <br />
                        <iframe 
                            width="560" 
                            height="315" 
                            src="https://www.youtube.com/embed/qB-p6AmxaXk" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen
                            title="How to Sign Up for myESRA">
                        </iframe>
                        
                        <br /><br />

                        <div className='resources-row'>
                            <h5>2. How to Log in to myESRA</h5>
                        </div>
                        <br />
                        <iframe 
                            width="560" 
                            height="315" 
                            src="https://www.youtube.com/embed/gTRKFkHQwTk" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen
                            title="How to Log in to myESRA">
                        </iframe>
                        
                        <br /><br />

                        <div className='resources-row'>
                            <h5>2. How to Use myESRA</h5>
                        </div>
                        <br />
                        <iframe 
                            width="560" 
                            height="315" 
                            src="https://www.youtube.com/embed/PqUVcnbpTrM?si=GnrWoO1LK8MZAP6Z" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen
                            title="How to Log in to myESRA">
                        </iframe>

                        <br /><br />

                        <div className='resources-row'>
                            <h5>2. How to Open Reference Links in MyESRA</h5>
                        </div>
                        <br />
                        <iframe 
                            width="560" 
                            height="315" 
                            src="https://www.youtube.com/embed/uhl33aPdcTo?si=kVAjfrRBdjffdEHI" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen
                            title="How to Log in to myESRA">
                        </iframe>
                        <br />
                    </div>
               </div>
               <Footer />
            </div>
        )
    }
}

export default Resources