import React, { Component } from 'react';
import { getCurrentUser } from '../Authentication/firebase/auth';

class SignUpVerification extends Component {
    openGmail = () => {
        window.open('https://mail.google.com/', '_blank');
    }

    resendVerificationEmail = () => {
        const user = getCurrentUser(); // Use 'auth.currentUser' directly
        if (user) {
            user.sendEmailVerification().then(() => {
                console.log("Verification email resent.");
                // Inform the user
            }).catch((error) => {
                console.error("Error resending verification email:", error);
            });
        } else {
            console.error("No user signed in.");
        }
    };

    render() {
        // Access email from the location state
        const { email } = this.props.location.state || { email: 'your email' };

        return (
            <div className="verify-page">
                <div className="verify-container">
                    <div className='elipse'>
                        <div className='login-logo' />
                    </div>
                    <h1>Verify your email</h1>
                    <p>We sent an email to <strong>{email}</strong>. Click the link inside to get started.</p>
                    <button onClick={this.openGmail}><div className='gmail-icon' />Open Gmail</button>
                    <p><a href="#" onClick={this.resendVerificationEmail}>Resend Email</a></p>
                </div>
            </div>
        );
    }
}

export default SignUpVerification;


