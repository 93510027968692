import React, {Component} from 'react'
import ModalApp from './Modal'
import { TailSpin } from 'react-loader-spinner'

class LoadingModal extends Component {
    render() {
        const modalBody =
            <div style={{padding: '100px 0', textAlign: 'center'}}>
                <div style={{ margin: "0 auto", textAlign: "center"}}>

                </div>
                <h4 className="thinHeading" style={{color: 'white'}}>
                    <TailSpin
                        height="50"
                        width="50"
                        color="white"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
                        wrapperClass=""
                        visible={true}
                    />
                    <div style={{ color: "white", fontWeight: "700"}}>
                        {this.props.message}
                    </div>
                </h4>
            </div>;

        return (
            <div>
                <ModalApp name={this.props.name}
                          header={null}
                          body={modalBody}
                          noClose
                />
            </div>
        )
    }
}

export default LoadingModal