import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class EmailVerified extends Component {
    navigateToLogin = () => {
        this.props.history.push('/login'); 
    };
    
    render() {
        
        return (
            <div className="login-page">
                <div className="login-container">
                    <div className='elipse'>
                        <div className='login-logo' />
                    </div>
                    <h1>Email verified</h1>
                    <p>Your email was verified but you are no longer authenticated. Please return to the device where you began signing up and refresh the page, or login on this device to continue.</p>
                    <button onClick={this.navigateToLogin}>Login here</button>
                    
                </div>
            </div>
        );
    }
}

export default withRouter(EmailVerified);


