// ScrollContext.js
import React, { createContext, useState, useContext } from 'react';

const ScrollContext = createContext();
export const useScroll = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }) => {
  const [scrollToTopTrigger, setScrollToTopTrigger] = useState(false);

  const scrollToTop = () => {
    setScrollToTopTrigger(prev => !prev); // Toggle to trigger useEffect in Landing
  };

  return (
    <ScrollContext.Provider value={{ scrollToTop, scrollToTopTrigger }}>
      {children}
    </ScrollContext.Provider>
  );
};
