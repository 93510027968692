import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleToast } from "../Actions/ToggleToast";
import { auth } from '../Authentication/firebase';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { setUserEmail } from '../Actions/userActions';
import API_Root from '../Constants/API_Root';

class Signup extends Component {
    state = {
        email: '',
        password: '',
        step: 1,
        isReadOnly: false
    };

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const hashid = params.get('id');
    
        if (hashid) {
            fetch(`${API_Root}/get-email-from-hash?hashid=${hashid}`)
                .then(response => response.json())
                .then(data => {
                    if (data.email) {
                        this.setState({ email: data.email, step: 2, isReadOnly: true });
                    } else {
                        this.props.toggleToast({ show: true, type: "error", message: "ERROR: Invalid or expired signup link." });
                    }
                })
                .catch(error => {
                    console.error('Error fetching email:', error);
                    this.props.toggleToast({ show: true, type: "error", message: "ERROR: Failed to fetch email for signup." });
                });
        }
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleContinue = () => {
        // Check if email is entered, then proceed to next step
        if (this.state.email) {
            this.setState({ step: 2 });
        } else {
            this.props.toggleToast({ show: true, type: "error", message: "ERROR: Please enter an email." });
        }
    }

    handleSignup = () => {
        const { email, password } = this.state;
        auth.doCreateUserWithEmailAndPassword(email, password)
            .then(() => {
                
                fetch(API_Root + 'create-user', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_email: email, 
                    }),
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('User created in database:', data);
                    
                })
                .catch((error) => {
                    console.error('Error creating user in database:', error);
                    this.props.toggleToast({ show: true, type: "error", message: "ERROR: Failed to create user in database." });
                });

                this.props.setUserEmail(email);
                localStorage.setItem('isLoggedIn', 'true');
                this.props.history.push({
                    pathname: '/signup-verification',
                    state: { email: email }
                }); 
            })
            .catch((error) => {
                console.error('Signup Error:', error);
                this.props.toggleToast({ show: true, type: "error", message: "ERROR: Error occured during signup, please try again." });
            });
    }

    render() {
        const { step, email, password, isReadOnly } = this.state;
        return (
            <div className="login-page">
                <div className="login-container">
                    {step === 1 && (
                        <>
                            <div className='elipse'>
                                <div className='login-logo' /> 
                            </div>
                            <h1>Create Your Account</h1>
                            <p></p>
                            <input
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={email}
                                onChange={this.handleInputChange}
                                readOnly={isReadOnly}
                            />
                            <button onClick={this.handleContinue}>Continue</button>
                            <div className="login-footer">
                                <span style={{ color: '#000000', fontWeight: 'normal' }}>Already have an account? </span>
                                <Link to="/login">Log in</Link>
                            </div>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <div className='elipse'>
                                <div className='login-logo' /> 
                            </div>
                            <h1>Create Your Account</h1>
                            <p></p>
                            <input
                                type="email"
                                value={email}
                                readOnly
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={password}
                                onChange={this.handleInputChange}
                            />
                            <button onClick={this.handleSignup}>Sign Up</button>
                            <div className="login-footer">
                                <span style={{ color: '#000000', fontWeight: 'normal' }}>Already have an account? </span>
                                <Link to="/login">Log in</Link>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    toggleToast: (options) => dispatch(toggleToast(options)),
    setUserEmail: (email) => dispatch(setUserEmail(email)), 
});

export default withRouter(connect(null, mapDispatchToProps)(Signup));
