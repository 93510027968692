import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import API_Root from '../Constants/API_Root';
import { auth } from '../Authentication/firebase/firebase';
import { Link } from 'react-router-dom';
import { getNameList } from 'country-list';
import { toggleToast } from "../Actions/ToggleToast";

class UpdateProfile extends Component {
    state = {
        firstName: '',
        lastName: '',
        userEmail: null,
        workEmail: '',
        companyWebsite: '',
        jobTitle: '',
        city: '',
        country: '',
        countries: getNameList(),
        isEighteenOrOlder: false,
        subscription: null,
        profileLoaded: false ,
        formChanged: false,
        newsletterSubscribed: false,
    };

    componentDidMount() {
        auth.onAuthStateChanged(user => {
            if (user) {
              this.setState({
                userEmail: user.email,
              });
              this.fetchProfileInformation(user.email);
            } else {
              this.setState({
                userEmail: null,
              });
            }
        });
        console.log(this.state.countries)
    }

    fetchProfileInformation = (email) => {
        fetch(API_Root + 'get-profile-information', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.first_name !== null) {
                this.setState({
                    firstName: data.first_name,
                    lastName: data.last_name,
                    workEmail: data.work_email,
                    companyWebsite: data.company_website,
                    jobTitle: data.job_title,
                    city: data.city,
                    country: data.country,
                    subscription: data.subscription,
                    profileLoaded: true,
                    newsletterSubscribed: data.newsletterSubscribed
                });
            }
        })
        .catch(error => console.error('Error fetching profile information:', error));
    };

    handleInputChange = (event) => {
        const { name, type, checked, value } = event.target;
        this.setState({ [name]: type === 'checkbox' ? checked : value, formChanged: true });
    };

    handleSubmit = () => {
        const {
            userEmail, firstName, lastName,
            workEmail, companyWebsite, jobTitle,
            city, country, formChanged, newsletterSubscribed
        } = this.state;

        if (!firstName || !lastName || !workEmail || !companyWebsite || !jobTitle || !city || !country) {
            this.props.toggleToast({ show: true, type: "error", message: "ERROR: Please fill in ALL fields." });
            return; // Stop the handleSubmit function if validation fails
        }

        if (!formChanged) {
            this.props.toggleToast({ show: true, type: "error", message: "ERROR: Please make a change before updating." });
            return; // Stop the handleSubmit function if validation fails
        }
    
        fetch(API_Root + 'update-profile', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: userEmail,
                firstName,
                lastName,
                workEmail,
                companyWebsite,
                jobTitle,
                city,
                country,
                newsletterSubscribed,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data.message);
            this.props.history.push('/chat');
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
    };

    render() {
        const {
            firstName, lastName, workEmail,
            companyWebsite, jobTitle, city, country, countries, isEighteenOrOlder, profileLoaded, newsletterSubscribed
        } = this.state;
        const countryList = require('country-list');
        return (
            <div className="login-page">
                <div className="login-container">
                    <div className='elipse'>
                        <div className='login-logo' />
                    </div>
                    <h1>Tell us about yourself</h1>
                    <p></p>
                    <div className='login-row'>
                        <input
                            required
                            type="text"
                            name="firstName"
                            className="input-placeholder"
                            value={firstName}
                            onChange={this.handleInputChange}
                            placeholder={firstName ? firstName : "First Name"}
                        />
                        <input
                            required
                            type="text"
                            name="lastName"
                            className="input-placeholder"
                            value={lastName}
                            onChange={this.handleInputChange}
                            placeholder={lastName ? lastName : "Last Name"}
                        />
                    </div>
                    <input
                        required
                        type="text"
                        name="workEmail"
                        className="input-placeholder"
                        value={workEmail}
                        onChange={this.handleInputChange}
                        placeholder={workEmail ? workEmail : "Work Email"}
                    />
                    <input
                        required
                        type="text"
                        name="companyWebsite"
                        className="input-placeholder"
                        value={companyWebsite}
                        onChange={this.handleInputChange}
                        placeholder={companyWebsite ? companyWebsite : "Company Website"}
                    />
                    <input
                        required
                        type="text"
                        name="jobTitle"
                        className="input-placeholder"
                        value={jobTitle}
                        onChange={this.handleInputChange}
                        placeholder={jobTitle ? jobTitle : "Job Title"}
                    />
                    <input
                        required
                        type="text"
                        name="city"
                        className="input-placeholder"
                        value={city}
                        onChange={this.handleInputChange}
                        placeholder={city ? city : "City"}
                    />
                    <select className="input-placeholder" required name="country" value={this.state.country} onChange={this.handleInputChange}>
                        <option value="">Select Country</option>
                        {Object.entries(countries).map(([code, name]) => (
                            <option key={code} value={name}>{name}</option>
                        ))}
                    </select>
                </div>
                <div className="login-row-newsletter">
                    <input
                        className='newsletter-checkbox'
                        type="checkbox"
                        checked={this.state.newsletterSubscribed}
                        onChange={this.handleInputChange}
                        name="newsletterSubscribed"
                    />
                    <label htmlFor="newsletterSubscribed">{this.state.newsletterSubscribed ? 'Unsubscribe from newsletter' : 'Subscribe to newsletter'}</label>
                </div>
                {!profileLoaded && (
                    <div className='login-row-age'>
                        <input
                            className='age-checkbox'
                            type="checkbox"
                            name="isEighteenOrOlder"
                            checked={isEighteenOrOlder}
                            onChange={this.handleInputChange}
                        />
                        <label htmlFor="isEighteenOrOlder">I am at least 18 years old and I agree to the <Link to="/terms-policies">Terms</Link> and have read our <Link to="/privacy-policy">Privacy Policy</Link>.</label>
                    </div>
                )}
                <div className="login-container">
                    <button onClick={this.handleSubmit} disabled={!this.state.formChanged}>Update</button>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    toggleToast: (options) => dispatch(toggleToast(options)),
});

export default withRouter(connect(null, mapDispatchToProps)(UpdateProfile));
