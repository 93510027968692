import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

class DataProcessing extends Component {
    render() {
        return (
            <div className='faq-grid-layout'>
               <Navbar />
               <div className='top-spacer' />
               <div className='banner'>
                    <div className='banner-container'>
                        <h1>DPA</h1>
                    </div>
               </div>
               <div className='mainsection'>
                    <div className='content-container'>
                        <h1>DATA PROCESSING AGREEMENT</h1>
                        <p>This DPA is entered into between the Controller and the Processor and is incorporated into and governed by the terms of the Agreement.</p>
                    
                        <h2>1. Definitions</h2>
                        <p>Any capitalised term not defined in this DPA shall have the meaning given to it in the Agreement.</p>
                        <div class="terms-row">
                            <h1>“Affiliate”</h1>
                            <p>means any entity that directly or indirectly controls, is controlled by, or is under common control of a Party. “Control”, for purposes of this definition, means direct or indirect ownership or control of more than 50% of the voting interests of a Party;</p>
                        </div>
                        <div class="terms-row">
                            <h1>“Agreement”</h1>
                            <p>means the agreement between the Controller and the Processor for the provision of the Services;</p>
                        </div>
                        <div class="terms-row">
                            <h1>“Controller”</h1>
                            <p>means the Customer;</p>
                        </div>
                        <div class="terms-row">
                            <h1>“Data Protection Law”</h1>
                            <p>means all laws and regulations, including laws and regulations of the European Union, the European Economic Area, their member states and the United Kingdom, any amendments, replacements or renewals thereof, applicable to the processing of Personal Data, including where applicable the Maltese Data Protection Act 2018, The Data Protection, Privacy and Electronic Communications (Amendments etc.) (EU Exit) Regulations 2020, the EU GDPR, the UK GDPR, the UK Data Protection Act 2018, the FADP, The Personal Information Protection and Electronic Documents Act (‘PIPEDA’), US State Privacy Laws, The Australian Privacy Act 1988 (as amended) and any applicable national implementing laws, regulations and secondary legislation relating to the processing of Personal Data and the privacy of electronic communications, as amended, replaced or updated from time to time, including the Privacy and Electronic Communications Directive (2002/58/EC) and the Privacy and Electronic Communications (EC Directive) Regulations 2003 (SI 2003/2426);</p>
                        </div>
                        <div class="terms-row">
                            <h1>“Data Subject”</h1>
                            <p>shall have the same meaning as in Data Protection Law or means a “Consumer” as that term is defined in US State Privacy Laws;</p>
                        </div>
                        <div class="terms-row">
                            <h1>“DPA”</h1>
                            <p>means this data processing agreement together with Exhibits A, B and C;</p>
                        </div>
                        <div class="terms-row">
                            <h1>“EEA”</h1>
                            <p>means the European Economic Area;</p>
                        </div>
                        <div class="terms-row">
                            <h1>“EU GDPR”</h1>
                            <p>means Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, (General Data Protection Regulation);</p>
                        </div>
                        <div class="terms-row">
                            <h1>“FADP”</h1>
                            <p>means the Swiss Federal Act on Data Protection of the 1st of September 2023, and as amended from time to time;</p>
                        </div>
                        <div class="terms-row">
                            <h1>“Personal Data”</h1>
                            <p>means Personal Information and shall have the same meaning as in Data Protection Law;</p>
                        </div>
                        <div class="terms-row">
                            <h1>“Processor”</h1>
                            <p>means the Company, including as applicable any “Service Provider” as that term is defined in US State Privacy Laws;</p>
                        </div>
                        <div class="terms-row">
                            <h1>“Restricted Transfer”</h1>
                            <p>means: (i) where the EU GDPR applies, a transfer of Personal Data via the Services from the EEA either directly or via onward transfer, to any country or recipient outside of the EEA not subject to an adequacy determination by the European Commission; (ii) where the UK GDPR applies, a transfer of Personal Data via the Services from the United Kingdom either directly or via onward transfer, to any country or recipient outside of the UK not based on adequacy regulations pursuant to Section 17A of the United Kingdom Data Protection Act 2018; and (iii) a transfer of Personal Data via the Services from Switzerland either directly or via onward transfer, to any country or recipient outside of the EEA and/or Switzerland not subject to an adequacy determination by the European Commission;</p>
                        </div>
                        <div class="terms-row">
                            <h1>“Services”</h1>
                            <p>means all services, software applications and solutions provided to the Controller by the Processor under and as described in the Agreement;</p>
                        </div>
                        <div class="terms-row">
                            <h1>“SCCs”</h1>
                            <p>means: (i) where the EU GDPR applies, the standard contractual clauses annexed to the European Commission's Implementing Decision 2021/914 of 4 June 2021 on standard contractual clauses for the transfer of personal data to third countries published at https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32021D0914&amp;from=EN, (“EU SCCs”); and (ii) where the UK GDPR applies standard data protection clauses adopted pursuant to Article 46(2)(c) of the UK GDPR as set out in Exhibit C of this DPA, (“UK SCCs”); and (iii) where Personal Data is transferred from Switzerland to outside of Switzerland or the EEA, the EU SCCs as amended in accordance with guidance from the Swiss Data Protection Authority, (“Swiss SCCs”);</p>
                        </div>
                        <div class="terms-row">
                            <h1>“Sub-processor”</h1>
                            <p>means any third party (including the Processor’s Affiliates) engaged directly or indirectly by the Processor to process Personal Data under this DPA in the provision of the Services to the Controller;</p>
                        </div>
                        <div class="terms-row">
                            <h1>“Supervisory Authority”</h1>
                            <p>means a governmental or government chartered regulatory body having binding legal authority over a Party;</p>
                        </div>
                        <div class="terms-row">
                            <h1>“UK GDPR”</h1>
                            <p>means the EU GDPR as it forms part of the law of England and Wales, Scotland and Northern Ireland by virtue of section 3 of the European Union (Withdrawal) Act 2018;</p>
                        </div>
                        <div class="terms-row">
                            <h1>“US State Privacy Laws”</h1>
                            <p>means the following US state data protection or privacy laws and regulations applicable to the Party’s Processing of Personal Data: California Consumer Privacy Act (CCPA) as amended by the California Privacy Rights Act (CPRA), Virginia Consumer Data Protection Act (VCDPA), Colorado Privacy Act (CPA), Connecticut Data Privacy Act (CTDPA), and Utah Consumer Privacy Act (UCPA) and the Connecticut Data Privacy Act (CTDPA) in each case as may be amended or superseded from time to time.</p>
                        </div>

                        <h2>2. Purpose</h2>
                        <div className='terms-services-row'>
                            <h1>2.1</h1>
                            <p>The Processor has agreed to provide the Services to the Controller in accordance with the terms of the Agreement. In providing the Services, the Processor shall process Customer Data on behalf of the Controller. Customer Data may include Personal Data. The Processor will process and protect such Personal Data in accordance with the terms of this DPA.</p>
                        </div>

                        <h2>3. Scope</h2>
                        <div className='terms-services-row'>
                            <h1>3.1</h1>
                            <p>In providing the Services to the Controller pursuant to the terms of the Agreement, the Processor shall process Personal Data only to the extent necessary to provide the Services in accordance with the terms of the Agreement, this DPA and the Controller’s instructions documented in the Agreement and this DPA, as updated from time to time.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>3.2</h1>
                            <p>The Controller and Processor shall take steps to ensure that any natural person acting under the authority of the Controller or the Processor who has access to Personal Data does not process them except on the instructions from the Controller unless required to do so by any Data Protection Law.</p>
                        </div>

                        <h2>4. Processor’s Obligations</h2>
                        <div className='terms-services-row'>
                            <h1>4.1</h1>
                            <p>The Processor may collect, process or use Personal Data only within the scope of this DPA.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>4.2</h1>
                            <p>The Processor confirms that it shall process Personal Data on behalf of the Controller in accordance with the documented instructions of the Controller.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>4.3</h1>
                            <p>The Processor shall promptly inform the Controller, if in the Processor's opinion, any of the instructions regarding the processing of Personal Data provided by the Controller breach Data Protection Law.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>4.4</h1>
                            <p>The Processor shall ensure that all employees, agents, officers and contractors involved in the handling of Personal Data: (i) are aware of the confidential nature of the Personal Data and are contractually bound to keep the Personal Data confidential; (ii) have received appropriate training on their responsibilities as a data processor; and (iii) are bound by the terms of this DPA.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>4.5</h1>
                            <p>The Processor shall implement appropriate technical and organisational measures to protect Personal Data, taking into account: (i) the state of the art; (ii) the costs of implementation; (iii) the nature, scope, context and purposes of processing; and (iv) the risk of varying likelihood and severity for the rights and freedoms of natural persons.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>4.6</h1>
                            <p>The Processor shall implement appropriate technical and organisational measures to ensure a level of security appropriate to the risk, including inter alia as appropriate: (i) the pseudonymisation and encryption of Personal Data; (ii) the ability to ensure the ongoing confidentiality, integrity, availability and resilience of processing systems and services; (iii) the ability to restore the availability and access to Personal Data in a timely manner in the event of a physical or technical incident; (iv) a process for regularly testing, assessing and evaluating the effectiveness of technical and organisational measures for ensuring the security of the processing. In accessing the appropriate level of security, account shall be taken in particular of the risks that are presented by processing, in particular from accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to Personal Data transmitted, stored or otherwise processed.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>4.7</h1>
                            <p>The technical and organisational measures detailed in Exhibit B shall at all times be adhered to as a minimum security standard. The Controller accepts and agrees that the technical and organisational measures are subject to development and review and that the Processor may use alternative suitable measures to those detailed in the attachments to this DPA, provided such measures are at least equivalent to the technical and organisational measures set out in Exhibit B and appropriate pursuant to the Processor's obligations in clauses 4.5 and 4.6 above.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>4.8</h1>
                            <p>The Controller acknowledges and agrees that, in the course of providing the Services to the Controller, it may be necessary for the Processor to access the Personal Data to respond to any technical problems or Controller queries and to ensure the proper working of the Services. All such access by the Processor will be limited to those purposes.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>4.9</h1>
                            <p>Taking into account the nature of the processing and the information available to the Processor, the Processor shall assist the Controller by having in place appropriate technical and organisational measures, insofar as this is possible, for the fulfilment of the Controller's obligation to respond to requests for exercising the Data Subject's rights and the Controller’s compliance with the Controller’s data protection obligations in respect of the processing of Personal Data.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>4.10</h1>
                            <p>The Processor may not: (i) sell Personal Data; (ii) retain, use, or disclose Personal Data for commercial purposes other than providing the Services under the terms of the Agreement; or (iii) retain, use, or disclose Personal Data outside of the terms of the Agreement.</p>
                        </div>

                        <h2>5. Controller’s Obligations</h2>
                        <div class='terms-services-row'>
                            <h1>5.1</h1>
                            <p>The Controller represents and warrants that: (i) it shall comply with this DPA and its obligations under Data Protection Law; (ii) it has obtained any and all permissions and authorisations necessary to permit the Processor, its Affiliates and Sub-processors, to execute their rights or perform their obligations under this DPA; and (iii) all Affiliates of the Controller who use the Services shall comply with the obligations of the Controller set out in this DPA.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>5.2</h1>
                            <p>The Controller shall implement appropriate technical and organisational measures to protect Personal Data, taking into account: (i) the state of the art; (ii) the costs of implementation; (iii) the nature, scope, context and purposes of processing; and (iv) the risk of varying likelihood and severity for the rights and freedoms of natural persons.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>5.3</h1>
                            <p>The Controller shall implement appropriate technical and organisational measures to ensure a level of security appropriate to the risk, including inter alia as appropriate: (i) the pseudonymisation and encryption of Personal Data; (ii) the ability to ensure the ongoing confidentiality, integrity, availability and resilience of processing systems and services; (iii) the ability to restore the availability and access to Personal Data in a timely manner in the event of a physical or technical incident; (iv) a process for regularly testing, assessing and evaluating the effectiveness of technical and organisational measures for ensuring the security of the processing. In accessing the appropriate level of security, account shall be taken in particular of the risks that are presented by processing, in particular from accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to Personal Data transmitted, stored or otherwise processed.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>5.4</h1>
                            <p>The Controller acknowledges and agrees that some instructions from the Controller including the Processor assisting with audits, inspections, DPIAs or providing any assistance under this DPA, may result in additional fees. In such case the Processor shall notify the Controller of its fees for providing such assistance in advance and shall be entitled to charge the Controller for its reasonable costs and expenses in providing such assistance, unless agreed otherwise in writing.</p>
                        </div>
                        <h2>6. Sub-processors</h2>
                        <div class='terms-services-row'>
                            <h1>6.1</h1>
                            <p>The Controller acknowledges and agrees that the Processor may engage Sub-processors in connection with the provision of the Services.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>6.2</h1>
                            <p>All Sub-processors who process Personal Data in the provision of the Services to the Controller shall comply with the obligations of the Processor set out in this DPA.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>6.3</h1>
                            <p>The Controller authorises the Processor to use the Sub-processors included in the Sub-processor list published at: <Link to="/sub-processor-list">https://www.essolutions.ai/sub-processor-list</Link> to process the Personal Data. During the term of this DPA, the Processor shall provide the Controller with 30 days prior notification, via email, of any changes to the list of Sub-processors before authorising any new or replacement Sub-processor to process Personal Data in connection with provision of the Services.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>6.4</h1>
                            <p>The Controller may object to the use of a new or replacement Sub-processor, by notifying the Processor promptly in writing within thirty (30) days after receipt of the Processor’s notice. If the Controller objects to a new or replacement Sub-processor, the Controller may terminate the Agreement with respect to those Services which cannot be provided by the Processor without the use of the new or replacement Sub-processor. The Processor will refund the Controller any prepaid fees covering the remainder of the term of the Agreement following the effective date of termination with respect to such terminated Services.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>6.5</h1>
                            <p>All Sub-processors who process Personal Data shall comply with the obligations of the Processor set out in this DPA. The Processor shall prior to the relevant Sub-processor carrying out any processing activities in respect of the Personal Data: (i) appoint each Sub-processor under a written contract containing materially the same obligations to those of the Processor in this DPA enforceable by the Processor; and (ii) ensure each such Sub-processor complies with all such obligations.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>6.6</h1>
                            <p>The Controller agrees that the Processor and its Sub-processors may make Restricted Transfers of Personal Data for the purpose of providing the Services to the Controller in accordance with the Agreement. The Processor confirms that such Sub-processors: (i) are located in a third country or territory recognised by the EU Commission or a Supervisory Authority, as applicable, to have an adequate level of protection; or (ii) have entered into the applicable SCCs with the Processor; or (iii) have other legally recognised appropriate safeguards in place.</p>
                        </div>
                        <h2>7. Restricted Transfers</h2>
                        <div class='terms-services-row'>
                            <h1>7.1</h1>
                            <p>The Parties agree that, when a transfer of Personal Data occurs between the Controller and the Processor or from the Processor to a Sub-processor which is a Restricted Transfer, it shall be subject to the applicable SCCs.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>7.2</h1>
                            <p>The Parties agree that the EU SCCs shall apply to Restricted Transfers from the EEA. The EU SCCs shall be deemed entered into (and incorporated into this DPA by reference) and completed as follows:</p>
                        </div>
                        
                        <ul>
                            <li>
                                <p>
                                    <strong>(i) </strong> 
                                    Module Two (Controller to Processor) shall apply where the Customer is a Controller of Personal Data and the Company is processing Personal Data;
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>(ii) </strong> 
                                    Module Three (Processor to Processor) shall apply where the Company is a Processor of Personal Data and the Company uses a Sub-processor to process the Personal Data;
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>(iii) </strong> 
                                    Module Four (Processor to Controller) shall apply where the Company is processing Personal Data and the Customer is not subject to the EU GDPR or UK GDPR;
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>(iv) </strong> 
                                    In Clause 7 of the EU SCCs, the optional docking clause shall not apply;
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>(v) </strong> 
                                    In Clause 9 of the EU SCCs, Option 2 applies, and the time period for giving notice of Sub-processor changes shall be as set out in clause 6.3 of this DPA;
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>(vi) </strong> 
                                    In Clause 11 of the EU SCCs, the optional language shall not apply;
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>(vii) </strong> 
                                    In Clause 17 of the EU SCCs, Option 1 applies and the EU SCCs shall be governed by the laws of Malta;
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>(viii) </strong> 
                                    In Clause 18(b) of the EU SCCs, disputes shall be resolved by the courts of Malta;
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>(ix) </strong> 
                                    Annex I of the EU SCCs shall be deemed completed with the information set out in Exhibit A of this DPA;
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>(x) </strong> 
                                    Annex II of the EU SCCs shall be deemed completed with the information set out in Exhibit B of this DPA.
                                </p>
                            </li>
                        </ul>
                        <div class='terms-services-row'>
                            <h1>7.3</h1>
                            <p>The Parties agree that the EU SCCs as amended in clause 7.2 above, shall be adjusted as set out below where the FADP applies to any Restricted Transfer:</p>
                        </div>  
                        <ul>
                            <li>
                                <p>
                                    <strong>(i) </strong> 
                                    The Swiss Federal Data Protection and Information Commissioner (“FDPIC”) shall be the sole Supervisory Authority for Restricted Transfers exclusively subject to the FADP;
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>(ii) </strong> 
                                    Restricted Transfers subject to both the FADP and the EU GDPR, shall be dealt with by the EU Supervisory Authority named in Exhibit A of this DPA;
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>(iii) </strong> 
                                    The term ’member state’ must not be interpreted in such a way as to exclude Data Subjects in Switzerland from the possibility of suing for their rights in their place of habitual residence (Switzerland) in accordance with Clause 18(c) of the EU SCCs;
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>(iv) </strong> 
                                    Where Restricted Transfers are exclusively subject to the FADP, all references to the GDPR in the EU SCCs are to be understood to be references to the FADP;
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>(v) </strong> 
                                    Where Restricted Transfers are subject to both the FADP and the EU GDPR, all references to the GDPR in the EU SCCs are to be understood to be references to the FADP insofar as the Restricted Transfers are subject to the FADP;
                                </p>
                            </li>
                        </ul> 
                        <div class='terms-services-row'>
                            <h1>7.4</h1>
                            <p>The Parties agree that the UK SCCs shall apply to Restricted Transfers from the UK and the UK SCCs shall be deemed entered into (and incorporated into this DPA by reference), as set out in Exhibit C of this DPA.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>7.5</h1>
                            <p>In the event that any provision of this DPA contradicts directly or indirectly any SCCs, the provisions of the applicable SCCs shall prevail over the terms of the DPA.</p>
                        </div>

                        <h2>8. Data Subject Access Requests</h2>
                        <div class='terms-services-row'>
                            <h1>8.1</h1>
                            <p>The Controller may require correction, deletion, blocking and/or making available the Personal Data during or after termination of the Agreement. The Controller acknowledges and agrees that the Processor will process the request to the extent it is lawful and will reasonably fulfil such request in accordance with its standard operational procedures to the extent possible.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>8.2</h1>
                            <p>In the event that the Processor receives a request from a Data Subject in relation to Personal Data, the Processor will refer the Data Subject to the Controller unless otherwise prohibited by law. The Controller shall reimburse the Processor for all costs incurred resulting from providing reasonable assistance in dealing with a Data Subject request. In the event that the Processor is legally required to respond to the Data Subject, the Controller will fully cooperate with the Processor as applicable.</p>
                        </div>

                        <h2>9. Audit</h2>
                        <div class='terms-services-row'>
                            <h1>9.1</h1>
                            <p>The Processor shall make available to the Controller all information reasonably necessary to demonstrate compliance with its processing obligations and allow for and contribute to audits and inspections.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>9.2</h1>
                            <p>Any audit conducted under this DPA shall consist of examination of the most recent reports, certificates and/or extracts prepared by an independent auditor bound by confidentiality provisions similar to those set out in the Agreement. In the event that provision of the same is not deemed sufficient in the reasonable opinion of the Controller, the Controller may conduct a more extensive audit which shall be: (i) at the Controller’s expense; (ii) limited in scope to matters specific to the Controller and agreed in advance; (iii) carried out during the Processor’s usual business hours and upon reasonable notice which shall be not less than 4 weeks unless an identifiable material issue has arisen; and (iv) conducted in a way which does not interfere with the Processor’s day-to-day business.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>9.3</h1>
                            <p>This clause shall not modify or limit the rights of audit of the Controller, instead it is intended to clarify the procedures in respect of any audit undertaken pursuant thereto.</p>
                        </div>

                        <h2>10. Personal Data Breach</h2>
                        <div class='terms-services-row'>
                            <h1>10.1</h1>
                            <p>The Processor shall notify the Controller without undue delay after becoming aware of (and in any event within 72 hours of discovering) any breach of security leading to the accidental or unlawful destruction, loss, alteration or unauthorised disclosure or access to any Personal Data (“<strong>Personal Data Breach</strong>”).</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>10.2</h1>
                            <p>In the event of a Personal Data Breach, the Processor shall take all commercially reasonable measures to secure the Personal Data, to limit the effects of any Personal Data Breach, and to assist the Controller in meeting the Controller’s obligations under applicable law.</p>
                        </div>

                        <h2>11. Compliance, Cooperation and Response</h2>
                        <div class='terms-services-row'>
                            <h1>11.1</h1>
                            <p>The Processor will notify the Controller promptly of any request or complaint regarding the processing of Personal Data, which adversely impacts the Controller, unless such notification is not permitted under applicable law or a relevant court order.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>11.2</h1>
                            <p>The Processor may make copies of and/or retain Personal Data in compliance with any legal or regulatory requirement including, but not limited to, retention requirements.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>11.3</h1>
                            <p>The Processor shall reasonably assist the Controller in meeting the Controller’s obligation to carry out data protection impact assessments (DPIAs), taking into account the nature of the processing and the information available to the Processor.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>11.4</h1>
                            <p>The Controller shall notify the Processor within a reasonable time, of any changes to applicable data protection laws, codes or regulations which may affect the contractual duties of the Processor. The Processor shall respond within a reasonable timeframe in respect of any changes that need to be made to the terms of this DPA or to the technical and organisational measures to maintain compliance. If the Processor is unable to accommodate necessary changes, the Controller may terminate the part or parts of the Services which give rise to the non-compliance. To the extent that other parts of the Services provided are not affected by such changes, the provision of those Services shall remain unaffected.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>11.5</h1>
                            <p>The Controller and the Processor and, where applicable, their representatives, shall cooperate, on request, with a Supervisory Authority in the performance of their respective obligations under this DPA and Data Protection Law.</p>
                        </div>

                        <h2>12. Liability</h2>
                        <div class='terms-services-row'>
                            <h1>12.1</h1>
                            <p>The limitations on liability set out in the Agreement apply to all claims made pursuant to any breach of the terms of this DPA.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>12.2</h1>
                            <p>The Parties agree that the Processor shall be liable for any breaches of this DPA caused by the acts and omissions or negligence of its Sub-processors to the same extent the Processor would be liable if performing the services of each Sub-processor directly under the terms of the DPA, subject to any limitations on liability set out in the terms of the Agreement.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>12.3</h1>
                            <p>The Parties agree that the Controller shall be liable for any breaches of this DPA caused by the acts and omissions or negligence of its Affiliates as if such acts, omissions or negligence had been committed by the Controller itself.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>12.4</h1>
                            <p>The Controller shall not be entitled to recover more than once in respect of the same loss.</p>
                        </div>

                        <h2>13. Term and Termination</h2>
                        <div class='terms-services-row'>
                            <h1>13.1</h1>
                            <p>The Processor shall only process Personal Data for the term of the DPA. The term of this DPA shall commence on the Effective Date of the Agreement and this DPA shall terminate automatically together with termination or expiry of the Agreement.</p>
                        </div>

                        <h2>14. Deletion and Return of Personal Data</h2>
                        <div class='terms-services-row'>
                            <h1>14.1</h1>
                            <p>The Processor shall at the choice of the Controller, upon receipt of a written request received within 14 days of the end of the provision of the Services, delete or return Personal Data to the Controller. The Processor shall in any event delete all copies of Personal Data in its systems on expiry of such 14 day period, unless applicable law or regulations require the Processor to retain copies of any Personal Data after termination.</p>
                        </div>

                        <h2>15. General</h2>
                        <div class='terms-services-row'>
                            <h1>15.1</h1>
                            <p>This DPA sets out the entire understanding of the Parties with regards to the subject matter herein.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>15.2</h1>
                            <p>Should a provision of this DPA be invalid or become invalid then the legal effect of the other provisions shall be unaffected. A valid provision is deemed to have been agreed which comes closest to what the Parties intended commercially and shall replace the invalid provision. The same shall apply to any omissions.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>15.3</h1>
                            <p>Subject to any provision of the SCCs to the contrary, this DPA shall be governed by the laws of England and Wales. Any dispute arising in connection with this DPA, which the Parties are not able to resolve amicably, shall be submitted to the exclusive jurisdiction of the courts of England.</p>
                        </div>
                        <div class='terms-services-row'>
                            <h1>15.4</h1>
                            <p>The Parties agree that this DPA is incorporated into and governed by the terms of the Agreement.</p>
                        </div>
                        
                        <h3>Exhibit A</h3>
                        <h4>List of Parties, Description of Processing and Transfer of Personal Data, Competent Supervisory Authority</h4>
                        
                        <h2>A. LIST OF PARTIES</h2>
                        <p><strong>The Exporter:</strong></p>
                        <table>
                            <tr>
                                <th colspan="2">means the Customer.</th>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>As set out for the Customer in the Agreement.</td>
                            </tr>
                            <tr>
                                <td>Contact person’s name, position and contact details:</td>
                                <td>As provided by the Customer in its account and used for notification and invoicing purposes.</td>
                            </tr>
                            <tr>
                                <td>Activities relevant to the data transferred under the SCCs:</td>
                                <td>Use of the Services.</td>
                            </tr>
                            <tr>
                                <td>Signature and date:</td>
                                <td>By entering into the Agreement, the Exporter is deemed to have signed the SCCs incorporated into this DPA and including their Annexes, as of the Effective Date of the Agreement.</td>
                            </tr>
                            <tr>
                                <td>Role:</td>
                                <td>Controller.</td>
                            </tr>
                            <tr>
                                <td>Name of Representative (if applicable):</td>
                                <td>Any UK or EU representative named in the Exporter’s privacy policy.</td>
                            </tr>
                        </table>
                        <br />
                        <p><strong>The Importer:</strong></p>
                        <table>
                            <tr>
                                <th colspan="2">means Environmental and Social Research Solutions Limited</th>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>Trident Park, Notabile Gardens, No. 2 - Level 3, Mdina Road, Zone 2, Central Business District, Birkirkara CBD 2010, Malta</td>
                            </tr>
                            <tr>
                                <td>Contact person’s name, position and contact details:</td>
                                <td>
                                    Ildiko Almasi Simsic, Director<br />
                                    info@essolutions.ai
                                </td>
                            </tr>
                            <tr>
                                <td>Activities relevant to the data transferred under the SCCs:</td>
                                <td>The provision of cloud computing solutions to the Exporter under which the Importer processes Personal Data upon the instructions of the Exporter in accordance with the terms of the Agreement.</td>
                            </tr>
                            <tr>
                                <td>Signature and date:</td>
                                <td>By entering into the Agreement, the Importer is deemed to have signed the SCCs, incorporated into this DPA, including their Annexes, as of the Effective Date of the Agreement.</td>
                            </tr>
                            <tr>
                                <td>Role:</td>
                                <td>Processor.</td>
                            </tr>
                            <tr>
                                <td>Name of Representative (if applicable):</td>
                                <td>
                                    Laura Palmariello, InfoSafe Data Protection & IG Solutions<br />
                                    laura@info-safe.co.uk
                                </td>
                            </tr>
                        </table>
                        <br />
                        <h2>B. DESCRIPTION OF PROCESSING AND TRANSFERS</h2>
                        <table>
                        <tr>
                            <th>Categories of Data Subjects:</th>
                            <td>Users authorised by the Controller and its Affiliates to access or use the Services in accordance with the terms of the Agreement, who are natural persons.</td>
                        </tr>
                        <tr>
                            <th>Categories of Personal Data:</th>
                            <td>
                                The Controller may submit Personal Data to the Services, the extent of which is determined and controlled by the Controller. The Personal Data includes but is not limited to:
                                <ul>
                                    <li>Personal details, first name, middle name and surname, email addresses, telephone number and company name.</li>
                                    <li>Unique identifiers such as username, account number or password.</li>
                                    <li>Personal Data derived from a user’s use of the Services such as records and business intelligence information.</li>
                                    <li>Personal Data within email and messaging content which identifies or may reasonably be used to identify individuals.</li>
                                    <li>Meta data including logon, protocol, sent, to, from, date, time, subject, which may include Personal Data.</li>
                                    <li>Geolocation based upon IP address.</li>
                                    <li>File attachments that may contain Personal Data.</li>
                                    <li>Information offered by users of the Services as part of support enquiries.</li>
                                    <li>Other data added by the Controller from time to time.</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>Sensitive Data:</th>
                            <td>No special category data is processed.</td>
                        </tr>
                        <tr>
                            <th>The frequency of the processing and transfer (e.g., whether the data is transferred on a one-off or continuous basis):</th>
                            <td>Continuous basis for the duration of the Agreement.</td>
                        </tr>
                        <tr>
                            <th>Nature of the processing:</th>
                            <td>Processing operations include but are not limited to:
                            <ul>
                                <li>the processing of information to provide research for environmental social governance</li>
                            </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>Purpose(s) of the data transfer and further processing:</th>
                            <td>Personal Data is transferred to sub-contractors who need to process some of the Personal Data in order to provide their services to the Processor as part of the Services provided by the Processor to the Controller.</td>
                        </tr>
                        <tr>
                            <th>The period for which the Personal Data will be retained, or, if that is not possible, the criteria used to determine that period:</th>
                            <td>Unless agreed otherwise in writing, for the duration of the Agreement, subject to clause 14 of the DPA.</td>
                        </tr>
                        <tr>
                            <th>For transfers to (Sub-)processors, also specify subject matter, nature and duration of the processing:</th>
                            <td><Link to="/sub-processor-list">The Sub-processor list</Link> sets out the Personal Data processed by each Sub-processor and the services provided by each Sub-processor.</td>
                        </tr>
                        </table>
                        <br />
                        <h2>C. COMPETENT SUPERVISORY AUTHORITY</h2>
                        <table>
                            <tr>
                                <th>Identify the competent supervisory authority/ies (e.g. in accordance with Clause 13 of the SCCs)</th>
                                <td>
                                    <p>Where the EU GDPR applies, the Maltese Data Protection Authority - IDPC.</p>
                                    <p>Where the UK GDPR applies, the UK Information Commissioner's Office, (ICO).</p>
                                    <p>Where the FADP applies, the Swiss Federal Data Protection and Information Commissioner, (FDPIC).</p>
                                </td>
                            </tr>
                        </table>
                        <br />
                        
                        <h3>Exhibit B</h3>
                        <h4>Technical and Organisational Security Measures<br />(Including Technical and Organisational Measures to Ensure the Security of Data)</h4>
                        <p>Below is a description of the technical and organisational measures implemented by the Processor (including any relevant certifications) to ensure an appropriate level of security, taking into account the nature, scope, context and purpose of the processing, and the risks for the rights and freedoms of natural persons.</p>
                        <p>Where applicable this Exhibit B will serve as Annex II to the SCCs.</p>
                        <table>
                            <tr>
                                <th><strong>Measure</strong></th>
                                <td><strong>Description</strong></td>
                            </tr>
                            <tr>
                                <th>Measures of pseudonymisation and encryption of Personal Data</th>
                                <td>
                                    <p>For the purpose of transfer control, an encryption technology is used (e.g. remote access to the company network via two factor VPN tunnel and full disk encryption). The suitability of an encryption technology is measured against the protective purpose.</p>
                                    <p>The Controller is assigned a unique encryption key, generated using a FIPS 140-2 compliant crypto library, which is used to encrypt and decrypt all of the Controller’s archived data. In addition to the unique encryption keys, all data being written to the storage grid includes the Controller’s unique account code. The Processor’s systems that write data to the storage grid retrieve the encryption key from one system and the customer code from another, which serves as a cross check against two independent systems. The Controller’s encryption key is further encrypted with a Processor key stored within a centralised and restricted key management system. In order for the Processor to access Personal Data via the master key, the key management system provisions individual keys following a strict process of approval that includes multiple levels of executive authorisation. Use of these master encryption keys is limited to senior production engineers and all access is logged, monitored, and configured for alerting by security via a centralised Security Incident and Event Management (“SIEM”) system.</p>
                                    <p>The Controller’s archived data is encrypted at rest using AES256 bit encryption</p>
                                    <p>Data in transit is protected by Transport Layer Security (“TLS”).</p>
                                </td>
                            </tr>
                            <tr>
                                <th>Measures for ensuring ongoing confidentiality, integrity, availability and resilience of processing systems and services</th>
                                <td>
                                    <p>Access to data necessary for the performance of the particular task is ensured within the systems and applications by a corresponding role and authorisation concept. In accordance to the “least privilege” and "need-to-know" principles, each role has only those rights which are necessary for the fulfilment of the task to be performed by the individual person.</p>
                                    
                                    <p>To maintain data access control, state of the art encryption technology is applied to the Personal Data itself where deemed appropriate to protect sensitive data based on risk.</p>
                                </td>
                            </tr>
                            <tr>
                                <th>Measures for ensuring the ability to restore the availability and access to Personal Data in a timely manner in the event of a physical or technical incident</th>
                                <td>All our applications are built stateless by using Cloud-formation templates and can be easily recreated in different geographical regions. Data is stored in triplicate across 2 data centres, with 2 separate cross connections. The data centres can be switched in the event of flooding, earthquake, fire or other physical destruction or power outage protect Personal Data against accidental destruction and loss.
                                    <br /><br />
                                    The Processor maintains redundancy throughout its IT infrastructure in order to minimize the lack of availability to or loss of data. Backups are maintained hourly and daily in accordance with our backup procedures. The Processor maintains a disaster recovery policy and at least once per calendar year practice executing the policy.
                                </td>
                            </tr>
                            <tr>
                                <th>Processes for regularly testing, assessing and evaluating the effectiveness of technical and organisational measures in order to ensure the security of the processing</th>
                                <td>The Processor conducts multiple internal audits. The Processor strives to automate audits hence the majority of our monitoring of its infrastructure is automated and running 24/7 and based on various frameworks (CIS, NEST etc.). The Processor obtains an external security and compliance audit once per calendar year.</td>
                            </tr>
                            <tr>
                                <th>Measures for user identification and authorisation</th>
                                <td>Remote access to the data processing systems is only possible through the Processor’s secure VPN tunnel. If the users first authenticate to the secure VPN tunnel, after successful authentication authorisation is executed by providing a unique user name and password to a centralised directory service. All access attempts, successful and unsuccessful are logged and monitored.</td>
                            </tr>
                            <tr>
                                <th>Measures for the protection of data during transmission</th>
                                <td>Data in transit is protected by Transport Layer Security (“TLS”).</td>
                            </tr>
                            <tr>
                                <th>Measures for the protection of data during storage</th>
                                <td>
                                    <p>Personal Data is only retained internally, and on the third party data centre servers, which are covered by AWS certifications. </p>
                                    
                                    <p>The Controller’s archived data is encrypted at rest using AES256 bit encryption and data in transit is protected by Transport Layer Security (“TLS”).</p>
                                </td>
                            </tr>
                            <tr>
                                <th>Measures for ensuring physical security of locations at which Personal Data are processed</th>
                                <td>
                                    <p>Due to their respective security requirements, business premises and facilities are subdivided into different security zones with different access authorisations. Third party data centres are monitored by security personnel. Access for employees is only possible with an encoded ID with a photo on it. All other persons have access only after having registered before (e.g. at the main entrance).</p>
                                    <p>Access to special security areas for remote maintenance is additionally protected by a separate access area. The constructional and substantive security standards comply with the security requirements for data centres.</p>
                                </td>
                            </tr>
                            <tr>
                                <th>Measures for ensuring events logging</th>
                                <td>System inputs are recorded in the form of log files therefore it is possible to review retroactively whether and by whom Personal Data was entered, altered or deleted.</td>
                            </tr>
                            <tr>
                                <th>Measures for ensuring system configuration, including default configuration</th>
                                <td>Our system configuration is based on the Security Technical Implementation Guides (STIG). System configuration is applied and maintained by software tools that ensure the system configurations do not deviate from the specifications. Deviations will be fixed automatically and reported to our SOC.</td>
                            </tr>
                            <tr>
                                <th>Measures for internal IT and IT security governance and management</th>
                                <td>Employees are instructed to collect, process and use Personal Data only within the framework and for the purposes of their duties (e.g., service provision). At a technical level, multi-client capability includes separation of functions as well as appropriate separation of testing and production systems.<br /><br />The Controller’s Personal Data is stored in a way that logically separates it from other customer data.</td>
                            </tr>
                            <tr>
                                <th>Measures for certification/assurance of processes and products</th>
                                <td>The Processor utilises third party data centres that maintain current ISO 27001 certifications. The Processor will not utilise third party data centres that do not maintain the aforementioned certifications and/or attestations, or other substantially similar or equivalent certifications and/or attestations.<br /><br />Upon the Controller’s written request (no more than once in any 12 month period), the Processor shall provide within a reasonable time, a copy of the most recently completed certification and/or attestation reports (to the extent that to do so does not prejudice the overall security of the Services). Any audit report submitted to the Controller shall be treated as Confidential Information and subject to the confidentiality provisions of the Agreement between the Parties</td>
                            </tr>
                            <tr>
                                <th>Measures for ensuring data minimisation</th>
                                <td>If Personal Data is no longer required for the purposes for which it was processed, it is deleted promptly. It should be noted that with each deletion, the Personal Data is only locked in the first instance and is then deleted for good with a certain delay. This is done in order to prevent accidental deletions or possible intentional damage. </td>
                            </tr>
                            <tr>
                                <th>Measures for ensuring data quality</th>
                                <td>All of the data that the Processor possesses is provided by the Controller. The Processor does not assess the quality of the data provided by the Controller. The Processor provides reporting tools within our product to help the Controller understand and validate the data that is stored.</td>
                            </tr>
                            <tr>
                                <th>Measures for ensuring limited data retention</th>
                                <td>The Processor uses a data classification scheme for all data that it stores and our retention policy specifies how each type of data is retained. When a record with Personal Data is deleted then it will be permanently evicted from our active databases. The data is retained in our backups until they are rotated out by more recent backups per the data retention policy.</td>
                            </tr>
                            <tr>
                                <th>Measures for ensuring accountability</th>
                                <td>The Processor internally reviews its information security policies semi-annually to ensure they it is still relevant and are being followed. All employees that handle sensitive data must acknowledge the information security policies. These employees are re-trained on information security policies once per year. A disciplinary policy is in place for employees that do not adhere to information security policies.</td>
                            </tr>
                            <tr>
                                <th>Measures for allowing data portability and ensuring erasure</th>
                                <td>The Services have built-in tools that allows the Controller to export and permanently erase data.</td>
                            </tr>
                            <tr>
                                <th>Measures to be taken by the (Sub-) processor to be able to provide assistance to the Controller (and, for transfers from a Processor to a Sub-processor, to the Data Exporter).</th>
                                <td>The transfer of Personal Data to a third party (e.g. customers, sub-contractors, service providers) is only made if a corresponding contract exists, and only for the specific purposes. If Personal Data is transferred outside the EEA, the Processor provides that an adequate level of data protection exists at the target location or organisation in accordance with the European Union's data protection requirements, e.g. by employing contracts based on the EU SCCs.</td>
                            </tr>
                        </table>
                        <br />
                        <h3>Exhibit C</h3>
                        <h4>International Data Transfer Addendum to the EU Commission Standard Contractual Clauses</h4>
                        <br />
                        <p><strong>VERSION B1.0, in force 21 March 2022</strong></p>
                        <p>This Addendum has been issued by the Information Commissioner for Parties making Restricted Transfers. The Information Commissioner considers that it provides Appropriate Safeguards for Restricted Transfers when it is entered into as a legally binding contract.</p>
                    
                        <p><strong>Part 1: Tables</strong></p>
                        <br />
                        <p><strong><u>Table 1: Parties</u></strong></p>
                        <table>
                            <tr>
                                <th>Start date</th>
                                <td colspan="2">The date set out in Annex I of the Approved EU SCCs.</td>
                            </tr>
                            <tr>
                                <th>The Parties</th>
                                <td>Exporter (who sends the Restricted Transfer)</td>
                                <td>Importer (who receives the Restricted Transfer)</td>
                            </tr>
                            <tr>
                                <th>Parties’ details</th>
                                <td>As set out in Annex I of the Approved EU SCCs.</td>
                                <td>As set out in Annex I of the Approved EU SCCs.</td>
                            </tr>
                            <tr>
                                <th>Key Contact</th>
                                <td>As set out in Annex I of the Approved EU SCCs.</td>
                                <td>As set out in Annex I of the Approved EU SCCs.</td>
                            </tr>
                            <tr>
                                <th>Signature (if required for the purposes of Section 2)</th>
                                <td>See the Agreement.</td>
                                <td>See the Agreement.</td>
                            </tr>
                        </table>
                        <br /><br />
                        <p><strong><u>Table 2: Selected SCCs, Modules and Selected Clauses</u></strong></p>
                        <table>
                            <tr>
                                <th>Addendum EU SCCs</th>
                                <td colspan="5">the Approved EU SCCs, including the Appendix Information and with only the following modules, clauses or optional provisions of the Approved EU SCCs brought into effect for the purposes of this Addendum: </td>
                            </tr>
                            <tr>
                                <th><strong>Module</strong></th>
                                <th><strong>Module in operation</strong></th>
                                <th><strong>Clause 11 (Option)</strong></th>
                                <th><strong>Clause 9a (General Authorisation)</strong></th>
                                <th><strong>Clause 9a (Time period)</strong></th>
                                <th><strong>Is personal data received from the Importer combined with personal data collected by the Exporter?</strong></th>
                            </tr>
                            <tr>
                                <th>1</th>
                                <td>No</td>
                                <td>Not used</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>2</th>
                                <td>Yes</td>
                                <td>Not used</td>
                                <td>Yes</td>
                                <td>30 Days</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>3</th>
                                <td>Yes</td>
                                <td>Not used</td>
                                <td>Yes</td>
                                <td>30 Days</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>4</th>
                                <td>Yes</td>
                                <td>Not used</td>
                                <td></td>
                                <td></td>
                                <td>No</td>
                            </tr>
                        </table>
                        <br /><br />
                        <p><strong><u>Table 3: Appendix Information</u></strong></p>
                        <p>“<strong>Appendix Information</strong>” means the information which must be provided for the selected modules as set out in the Appendix of the Approved EU SCCs (other than the Parties), and which for this Addendum is set out in:</p>
                        <p style={{ paddingLeft: '40px' }}><strong>Annex 1A</strong>: List of Parties: As set out in Annex I of the Approved EU SCCs</p>
                        <p style={{ paddingLeft: '40px' }}><strong>Annex 1B</strong>: Description of Transfer: As set out in Annex I of the Approved EU SCCs</p>
                        <p style={{ paddingLeft: '40px' }}><strong>Annex II</strong>: Technical and organisational measures including technical and organisational measures to ensure the security of the data: As set out in Annex II of the Approved EU SCCs</p>
                        <p style={{ paddingLeft: '40px' }}><strong>Annex III</strong>: List of Sub-processors: As set out in Annex I of the Approved EU SCCs</p>
                        
                        <br /><br />
                        <p><strong><u>Table 4: Ending this Addendum when the Approved Addendum Changes</u></strong></p>
                        <table>
                            <tr>
                                <th>Ending this Addendum when the Approved Addendum changes</th>
                                <td>Which Parties may end this Addendum as set out in Section 19:<br />
                                Importer<br />
                                Exporter</td>
                            </tr>
                        </table>

                        <br /><br />
                        <p><strong>Part 2: Mandatory Clauses</strong></p>
                        <p><strong><u>Entering into this Addendum</u></strong></p>
                        <div className='terms-services-row'>
                            <h1>1.</h1>
                            <p>Each Party agrees to be bound by the terms and conditions set out in this Addendum, in exchange for the other Party also agreeing to be bound by this Addendum.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>2.</h1>
                            <p>Although Annex 1A and Clause 7 of the Approved EU SCCs require signature by the Parties, for the purpose of making Restricted Transfers, the Parties may enter into this Addendum in any way that makes them legally binding on the Parties and allows data subjects to enforce their rights as set out in this Addendum. Entering into this Addendum will have the same effect as signing the Approved EU SCCs and any part of the Approved EU SCCs.</p>
                        </div>
                        <br />
                        <p><strong><u>Interpretation of this Addendum</u></strong></p>
                        <div className='terms-services-row'>
                            <h1>3.</h1>
                            <p>Where this Addendum uses terms that are defined in the Approved EU SCCs those terms shall have the same meaning as in the Approved EU SCCs. In addition, the following terms have the following meanings:</p>
                        </div>
                        <table>
                            <tr>
                                <th>Addendum </th>
                                <td>This International Data Transfer Addendum which is made up of this Addendum incorporating the Addendum EU SCCs.</td>
                            </tr>
                            <tr>
                                <th>Addendum EU SCCs</th>
                                <td>The version(s) of the Approved EU SCCs which this Addendum is appended to, as set out in Table 2, including the Appendix Information.</td>
                            </tr>
                            <tr>
                                <th>Appendix Information</th>
                                <td>As set out in Table 3.</td>
                            </tr>
                            <tr>
                                <th>Appropriate Safeguards</th>
                                <td>The standard of protection over the personal data and of data subjects’ rights, which is required by UK Data Protection Laws when you are making a Restricted Transfer relying on standard data protection clauses under Article 46(2)(d) UK GDPR.</td>
                            </tr>
                            <tr>
                                <th>Approved Addendum</th>
                                <td>The template Addendum issued by the ICO and laid before Parliament in accordance with s119A of the Data Protection Act 2018 on 2 February 2022, as it is revised under Section 18.</td>
                            </tr>
                            <tr>
                                <th>Approved EU SCCs</th>
                                <td>The Standard Contractual Clauses set out in the Annex of Commission Implementing Decision (EU) 2021/914 of 4 June 2021.</td>
                            </tr>
                            <tr>
                                <th>ICO</th>
                                <td>The Information Commissioner.</td>
                            </tr>
                            <tr>
                                <th>Restricted Transfer</th>
                                <td>A transfer which is covered by Chapter V of the UK GDPR.</td>
                            </tr>
                            <tr>
                                <th>UK </th>
                                <td>The United Kingdom of Great Britain and Northern Ireland.</td>
                            </tr>
                            <tr>
                                <th>UK Data Protection Laws </th>
                                <td>All laws relating to data protection, the processing of personal data, privacy and/or electronic communications in force from time to time in the UK, including the UK GDPR and the Data Protection Act 2018.</td>
                            </tr>
                            <tr>
                                <th>UK GDPR </th>
                                <td>As defined in section 3 of the Data Protection Act 2018.</td>
                            </tr>
                        </table>
                        <br /><br />
                        <div className='terms-services-row'>
                            <h1>4.</h1>
                            <p>This Addendum must always be interpreted in a manner that is consistent with UK Data Protection Laws and so that it fulfils the Parties’ obligation to provide the Appropriate Safeguards.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>5.</h1>
                            <p>If the provisions included in the Addendum EU SCCs amend the Approved SCCs in any way which is not permitted under the Approved EU SCCs or the Approved Addendum, such amendment(s) will not be incorporated in this Addendum and the equivalent provision of the Approved EU SCCs will take their place.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>6.</h1>
                            <p>If there is any inconsistency or conflict between UK Data Protection Laws and this Addendum, UK Data Protection Laws applies.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>7.</h1>
                            <p>If the meaning of this Addendum is unclear or there is more than one meaning, the meaning which most closely aligns with UK Data Protection Laws applies. </p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>8.</h1>
                            <p>Any references to legislation (or specific provisions of legislation) means that legislation (or specific provision) as it may change over time. This includes where that legislation (or specific provision) has been consolidated, re-enacted and/or replaced after this Addendum has been entered into. </p>
                        </div>

                        <br />
                        <p><strong><u>Hierarchy </u></strong></p>
                        <div className='terms-services-row'>
                            <h1>9.</h1>
                            <p>Although Clause 5 of the Approved EU SCCs sets out that the Approved EU SCCs prevail over all related agreements between the parties, the parties agree that, for Restricted Transfers, the hierarchy in Section 10 will prevail.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>10.</h1>
                            <p>Where there is any inconsistency or conflict between the Approved Addendum and the Addendum EU SCCs (as applicable), the Approved Addendum overrides the Addendum EU SCCs, except where (and in so far as) the inconsistent or conflicting terms of the Addendum EU SCCs provides greater protection for data subjects, in which case those terms will override the Approved Addendum.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>11.</h1>
                            <p>Where this Addendum incorporates Addendum EU SCCs which have been entered into to protect transfers subject to the General Data Protection Regulation (EU) 2016/679 then the Parties acknowledge that nothing in this Addendum impacts those Addendum EU SCCs.</p>
                        </div>

                        <br />
                        <p><strong><u>Incorporation of and changes to the EU SCCs</u></strong></p>
                        <div className='terms-services-row'>
                            <h1>12.</h1>
                            <p>This Addendum incorporates the Addendum EU SCCs which are amended to the extent necessary so that:</p>
                        </div>
                        
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>a.</h1>
                            <p style={{ paddingLeft: '40px' }}>together they operate for data transfers made by the data exporter to the data importer, to the extent that UK Data Protection Laws apply to the data exporter’s processing when making that data transfer, and they provide Appropriate Safeguards for those data transfers; </p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>b.</h1>
                            <p style={{ paddingLeft: '40px' }}>Sections 9 to 11 override Clause 5 (Hierarchy) of the Addendum EU SCCs; and</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>c.</h1>
                            <p style={{ paddingLeft: '40px' }}>this Addendum (including the Addendum EU SCCs incorporated into it) is (1) governed by the laws of England and Wales and (2) any dispute arising from it is resolved by the courts of England and Wales, in each case unless the laws and/or courts of Scotland or Northern Ireland have been expressly selected by the Parties.</p>
                        </div>

                        <div className='terms-services-row'>
                            <h1>13.</h1>
                            <p>Unless the Parties have agreed alternative amendments which meet the requirements of Section 12, the provisions of Section 15 will apply.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>14.</h1>
                            <p>No amendments to the Approved EU SCCs other than to meet the requirements of Section 12 may be made.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>15.</h1>
                            <p>The following amendments to the Addendum EU SCCs (for the purpose of Section 12) are made: </p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>a.</h1>
                            <p style={{ paddingLeft: '40px' }}>References to the “Clauses” means this Addendum, incorporating the Addendum EU SCCs;</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>b.</h1>
                            <p style={{ paddingLeft: '40px' }}>In Clause 2, delete the words:<br />“and, with respect to data transfers from controllers to processors and/or processors to processors, standard contractual clauses pursuant to Article 28(7) of Regulation (EU) 2016/679”;</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>c.</h1>
                            <p style={{ paddingLeft: '40px' }}>Clause 6 (Description of the transfer(s)) is replaced with:<br />
                            “The details of the transfers(s) and in particular the categories of personal data that are transferred and the purpose(s) for which they are transferred) are those specified in Annex I.B where UK Data Protection Laws apply to the data exporter’s processing when making that transfer.”;</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>d.</h1>
                            <p style={{ paddingLeft: '40px' }}>Clause 8.7(i) of Module 1 is replaced with:<br />
                            “it is to a country benefitting from adequacy regulations pursuant to Section 17A of the UK GDPR that covers the onward transfer”;</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>e.</h1>
                            <p style={{ paddingLeft: '40px' }}>Clause 8.8(i) of Modules 2 and 3 is replaced with:<br />
                            “the onward transfer is to a country benefitting from adequacy regulations pursuant to Section 17A of the UK GDPR that covers the onward transfer;”</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>f.</h1>
                            <p style={{ paddingLeft: '40px' }}>References to “Regulation (EU) 2016/679”, “Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (General Data Protection Regulation)” and “that Regulation” are all replaced by “UK Data Protection Laws”. References to specific Article(s) of “Regulation (EU) 2016/679” are replaced with the equivalent Article or Section of UK Data Protection Laws;</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>g.</h1>
                            <p style={{ paddingLeft: '40px' }}>References to Regulation (EU) 2018/1725 are removed;</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>h.</h1>
                            <p style={{ paddingLeft: '40px' }}>References to the “European Union”, “Union”, “EU”, “EU Member State”, “Member State” and “EU or Member State” are all replaced with the “UK”;</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>i.</h1>
                            <p style={{ paddingLeft: '40px' }}>The reference to “Clause 12(c)(i)” at Clause 10(b)(i) of Module one, is replaced with “Clause 11(c)(i)”;</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>j.</h1>
                            <p style={{ paddingLeft: '40px' }}>Clause 13(a) and Part C of Annex I are not used; </p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>k.</h1>
                            <p style={{ paddingLeft: '40px' }}>The “competent supervisory authority” and “supervisory authority” are both replaced with the “Information Commissioner”;</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>l.</h1>
                            <p style={{ paddingLeft: '40px' }}>In Clause 16(e), subsection (i) is replaced with:<br />
                            “the Secretary of State makes regulations pursuant to Section 17A of the Data Protection Act 2018 that cover the transfer of personal data to which these clauses apply;”;</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>m.</h1>
                            <p style={{ paddingLeft: '40px' }}>Clause 17 is replaced with:<br />
                            “These Clauses are governed by the laws of England and Wales.”;</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>n.</h1>
                            <p style={{ paddingLeft: '40px' }}>Clause 18 is replaced with:<br />
                            “Any dispute arising from these Clauses shall be resolved by the courts of England and Wales. A data subject may also bring legal proceedings against the data exporter and/or data importer before the courts of any country in the UK. The Parties agree to submit themselves to the jurisdiction of such courts.”; and</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>o.</h1>
                            <p style={{ paddingLeft: '40px' }}>The footnotes to the Approved EU SCCs do not form part of the Addendum, except for footnotes 8, 9, 10 and 11. </p>
                        </div>

                        <br />
                        <p><strong><u>Amendments to this Addendum </u></strong></p>
                        <div className='terms-services-row'>
                            <h1>16.</h1>
                            <p>The Parties may agree to change Clauses 17 and/or 18 of the Addendum EU SCCs to refer to the laws and/or courts of Scotland or Northern Ireland.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>17.</h1>
                            <p>If the Parties wish to change the format of the information included in Part 1: Tables of the Approved Addendum, they may do so by agreeing to the change in writing, provided that the change does not reduce the Appropriate Safeguards.</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1>18.</h1>
                            <p>From time to time, the ICO may issue a revised Approved Addendum which:</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>a.</h1>
                            <p style={{ paddingLeft: '40px' }}>makes reasonable and proportionate changes to the Approved Addendum, including correcting errors in the Approved Addendum; and/or</p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>b.</h1>
                            <p style={{ paddingLeft: '40px' }}>reflects changes to UK Data Protection Laws;</p>
                        </div>
                        <p>The revised Approved Addendum will specify the start date from which the changes to the Approved Addendum are effective and whether the Parties need to review this Addendum including the Appendix Information. This Addendum is automatically amended as set out in the revised Approved Addendum from the start date specified. </p>
                    
                        <div className='terms-services-row'>
                            <h1>19.</h1>
                            <p>If the ICO issues a revised Approved Addendum under Section 18, if any Party selected in Table 4 “Ending the Addendum when the Approved Addendum changes”, will as a direct result of the changes in the Approved Addendum have a substantial, disproportionate and demonstrable increase in: </p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>a.</h1>
                            <p style={{ paddingLeft: '40px' }}>its direct costs of performing its obligations under the Addendum; and/or </p>
                        </div>
                        <div className='terms-services-row'>
                            <h1 style={{ paddingLeft: '40px' }}>b.</h1>
                            <p style={{ paddingLeft: '40px' }}>its risk under the Addendum,</p>
                        </div>
                        <p>and in either case it has first taken reasonable steps to reduce those costs or risks so that it is not substantial and disproportionate, then that Party may end this Addendum at the end of a reasonable notice period, by providing written notice for that period to the other Party before the start date of the revised Approved Addendum.</p>
                    
                        <div className='terms-services-row'>
                            <h1>20.</h1>
                            <p>The Parties do not need the consent of any third party to make changes to this Addendum, but any changes must be made in accordance with its terms.</p>
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
        )
    }
}

export default DataProcessing