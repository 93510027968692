import React, { Component } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import API_Root from '../Constants/API_Root';
import { toggleToast } from "../Actions/ToggleToast";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class ContactUs extends Component {
    state = {
        email: '',
        subject: '',
        message: ''
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
    
        const { email, subject, message } = this.state;
    
        // Simple validation to ensure fields are not empty
        if (!email || !subject || !message) {
            this.props.toggleToast({ show: true, type: "error", message: "ERROR: Please fill in ALL fields." });
            return; 
        }
    
        fetch(API_Root + 'submit-contact-us', { 
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                subject,
                message,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data.message); // Handle the response data
            this.props.toggleToast({ show: true, type: "success", message: "SUCCESS: Contact Us form has been submitted!" });
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
            
        });
    };

    render() {
        return (
            <div className='faq-grid-layout'>
                <Navbar />
                <div className='top-spacer' />
                <div className='banner'>
                    <div className='banner-container'>
                        <h1>Contact Us</h1>
                        <p>Please fill out the form below to contact us.</p>
                    </div>
                </div>
                <div className='mainsection'>
                    <div className='contactus-container'>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                <label>Email Address:</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    required
                                />
                            </div>
                            <div className="form-row">
                                <label>Subject:</label>
                                <input
                                    type="text"
                                    name="subject"
                                    value={this.state.subject}
                                    onChange={this.handleChange}
                                    required
                                />
                            </div>
                            <div className="form-row">
                                <label>Message:</label>
                                <textarea
                                    name="message"
                                    value={this.state.message}
                                    onChange={this.handleChange}
                                    required
                                ></textarea>
                            </div>
                            <button onSubmit={this.handleSubmit} className="contactus-button" type="submit">Submit</button>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    toggleToast: (options) => dispatch(toggleToast(options)),
});

export default withRouter(connect(null, mapDispatchToProps)(ContactUs));
