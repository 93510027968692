import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleToast} from "../Actions/ToggleToast";
import { withRouter } from 'react-router-dom';
import '../newdesign.css'

import { BsLayoutSidebarInset } from "react-icons/bs";
import { MdOutlineChat } from "react-icons/md";
import { HiDotsVertical } from "react-icons/hi";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { IoDocumentLockOutline } from "react-icons/io5";
import { AiOutlineQuestionCircle } from "react-icons/ai";

import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

class AppIndexUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false
        };
        this.textareaRef = React.createRef();
    }
    toggleSidebar = () => {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed
        }));
    }
    render() {
        const { collapsed } = this.state;
        return (
            <div className='new-container'>
                <Sidebar backgroundColor="#fff" collapsed={collapsed} collapsedWidth="0">
                    <div className='new-sidebar'>
                        <div className='new-sidebar-header'>
                            <button className='sidebar-toggle-menu' onClick={this.toggleSidebar}>
                                <BsLayoutSidebarInset style={{ color: '#3E826D', fontSize: '24px' }} />
                            </button>
                            <span className='new-logo' />
                            <span className='new-logo-text'>myESRA</span>
                        </div>
                        <div className='new-sidebar-header-active'>
                            <button className='new-sidebar-new-chat-button'>New Chat</button>
                        </div>
                        <div className='new-sidebar-menu-title'>PREVIOUS 30 DAYS</div>
                        <Menu className='new-sidebar-menu'>
                            {/* <SubMenu label="Charts">
                                <MenuItem> Pie charts </MenuItem>
                                <MenuItem> Line charts </MenuItem>
                            </SubMenu> */}
                            

                            {/* <MenuItem className='menuitem' icon={<MdOutlineChat className='new-convo-icon'/>}> Conversation #1 </MenuItem>
                            <MenuItem> Conversation #2 </MenuItem> */}
                            <button className='new-menuitem'>
                                <MdOutlineChat className='new-convo-icon'/>
                                <div className='new-convo-text'>Conversation #1 jsdbfsldjnfs</div>
                                <button className='new-sidebar-button'><HiDotsVertical /></button>
                            </button>
                            <button className='new-menuitem'>
                                <MdOutlineChat className='new-convo-icon'/>
                                <div className='new-convo-text'>Conversation #2</div>
                                <button className='new-sidebar-button'><HiDotsVertical /></button>
                            </button>
                        </Menu>
                        <div className='new-sidebar-footer'>
                            <button className='new-menuitem link'>
                                <AiOutlineQuestionCircle className='new-convo-icon'/>
                                <div className='new-convo-text'>Help</div>
                            </button>
                            <button className='new-menuitem link'>
                                <IoDocumentLockOutline className='new-convo-icon'/>
                                <div className='new-convo-text'>myESRA Private</div>
                            </button>
                            <button className='new-menuitem'>
                                <span className='new-sidebar-profile-icon'/>
                                <div className='new-sidebar-footer-text'>John Doe</div>
                                <button className='new-sidebar-button'><HiDotsVertical /></button>
                            </button>
                        </div>
                    </div>
                </Sidebar>
                <div className='new-main'>
                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {toggleToast: toggleToast}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(AppIndexUpdate));