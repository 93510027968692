import React, { Component } from 'react';
import { auth } from '../Authentication/firebase';
import { toggleToast } from "../Actions/ToggleToast";
import { withRouter } from 'react-router-dom';

class NewPassword extends Component {
    state = {
        password: '',
        confirmPassword: '',
        oobCode: '',
        error: null,
    };

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const oobCode = urlParams.get('oobCode');
        if (!oobCode) {
            this.setState({ error: 'Error: Code missing from URL' });
        } else {
            this.setState({ oobCode });
        }
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = () => {
        const { password, confirmPassword, oobCode } = this.state;
        if (password !== confirmPassword) {
            this.setState({ error: 'Passwords do not match.' });
            return;
        }

        auth.confirmPasswordReset(oobCode, password)
            .then(() => {
                this.props.history.push('/login');
            })
            .catch(error => {
                this.setState({ error: error.message });
            });
    };

    render() {
        const { password, confirmPassword, error } = this.state;
        return (
            <div className="login-page">
                <div className="login-container">
                    <div className='elipse'>
                        <div className='login-logo' />
                    </div>
                    <h1>Set New Password</h1>
                    <p>Enter a new password below to change your password.</p>
                    {error && <span style={{ color: 'red', fontSize: '13px' }}>{error}</span>}
                    <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={this.handleInputChange}
                        placeholder="New Password"
                    />
                    <input
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={this.handleInputChange}
                        placeholder="Confirm New Password"
                    />
                    <button onClick={this.handleSubmit}>Submit</button>
                </div>
            </div>
        );
    }
}

export default withRouter(NewPassword);
