import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import API_Root from '../Constants/API_Root';
import { auth } from '../Authentication/firebase/firebase';
import { Link } from 'react-router-dom';
import { getNameList } from 'country-list';
import { toggleToast } from "../Actions/ToggleToast";

class Newsletter extends Component {
    state = {
        firstName: '',
        lastName: '',
        userEmail: null,
        workEmail: '',
        companyWebsite: '',
        isLoggedIn: false,
        jobTitle: '',
        city: '',
        country: '',
        countries: getNameList(),
        isEighteenOrOlder: false,
    };
    componentDidMount() {
        auth.onAuthStateChanged(user => {
            if (user) {
                this.setState({
                    userEmail: user.email,
                    isLoggedIn: true,
                }, () => {
                    this.handleSubmitLoggedIn(); 
                });
            } else {
                this.setState({
                    userEmail: null,
                    isLoggedIn: false,
                });
            }
        });
    }

    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleSubmitLoggedIn = () => {
        const { userEmail, isLoggedIn } = this.state;

        fetch(API_Root + 'subscribe-to-newsletter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: userEmail,
                isLoggedIn,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            this.props.toggleToast({ show: true, type: "success", message: "SUCCESS: You have subscribed to the newsletter!" });
            this.props.history.push('/');
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
    };

    handleSubmit = () => {
        const { userEmail, firstName, lastName, workEmail, companyWebsite, jobTitle, city, country, isLoggedIn, isEighteenOrOlder } = this.state;

        if (!this.validateEmail(workEmail)) {
            this.props.toggleToast({ show: true, type: "error", message: "ERROR: Please fill in a valid email." });
            return; // Stop the handleSubmit function if validation fails
        }

        if (!firstName || !lastName || !city || !country) {
            this.props.toggleToast({ show: true, type: "error", message: "ERROR: Please fill in ALL fields." });
            return; // Stop the handleSubmit function if validation fails
        }

        if (!isEighteenOrOlder) {
            this.props.toggleToast({ show: true, type: "error", message: "You must be 18 years of age or older to continue." });
            return;
        }
    
        fetch(API_Root + 'subscribe-to-newsletter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: userEmail,
                firstName,
                lastName,
                workEmail,
                companyWebsite,
                jobTitle,
                city,
                country,
                isLoggedIn,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            this.props.toggleToast({ show: true, type: "success", message: "SUCCESS: You have subscribed to the newsletter!" });
            this.props.history.push('/');
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
    };

    render() {
        const { isLoggedIn } = this.state;
        if (isLoggedIn) {
            return (
                <div className="login-page">
                    <div className="login-container">
                        <div className='elipse'>
                            <div className='login-logo' />
                        </div>
                        <h1>Successfully subscribed to the newsletter!</h1>
                    </div>
                </div>    
            );
        } else {
            const { firstName, lastName, workEmail, companyWebsite, jobTitle, city, country, countries, isEighteenOrOlder } = this.state;
            const countryList = require('country-list');
            return (
                <div className="login-page">
                    <div className="login-container">
                        <div className='elipse'>
                            <div className='login-logo' />
                        </div>
                        <h1>Tell us about yourself</h1>
                        <p>to receive updates</p>
                        <div className='login-row'>
                            <input
                                required
                                type="text"
                                name="firstName"
                                value={firstName}
                                onChange={this.handleInputChange}
                                placeholder="First Name"
                            />
                            <input
                                required
                                type="text"
                                name="lastName"
                                value={lastName}
                                onChange={this.handleInputChange}
                                placeholder="Last Name"
                            />
                        </div>
                        <input
                            required
                            type="text"
                            name="workEmail"
                            value={workEmail}
                            onChange={this.handleInputChange}
                            placeholder="Email"
                        />
                        <input
                            required
                            type="text"
                            name="companyWebsite"
                            value={companyWebsite}
                            onChange={this.handleInputChange}
                            placeholder="Company Website"
                        />
                        <input
                            required
                            type="text"
                            name="jobTitle"
                            value={jobTitle}
                            onChange={this.handleInputChange}
                            placeholder="Job Title"
                        />
                        <input
                            required
                            type="text"
                            name="city"
                            value={city}
                            onChange={this.handleInputChange}
                            placeholder="City"
                        />
                        <select required name="country" value={this.state.country} onChange={this.handleInputChange}>
                            <option value="">Select Country</option>
                            {Object.entries(countries).map(([code, name]) => (
                                <option key={code} value={name}>{name}</option>
                            ))}
                        </select>
                    </div>
                    
                    <div className='login-row-age'>
                        <input
                            className='age-checkbox'
                            type="checkbox"
                            name="isEighteenOrOlder"
                            checked={isEighteenOrOlder}
                            onChange={this.handleInputChange}
                        />
                        <label htmlFor="isEighteenOrOlder">I am at least 18 years old and I agree to the <Link to="/terms-policies">Terms</Link> and have read our <Link to="/privacy-policy">Privacy Policy</Link>.</label>
                    </div>
                    <div className="login-container">
                        <button onClick={this.handleSubmit}>Subscribe</button>
                    </div>
                </div>
            );
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    toggleToast: (options) => dispatch(toggleToast(options)),
});

export default withRouter(connect(null, mapDispatchToProps)(Newsletter));
