import React, { Component } from 'react';
import { connect } from 'react-redux';
import { auth } from '../Authentication/firebase/firebase';
import { withRouter, Link } from 'react-router-dom';
import API_Root from '../Constants/API_Root';
import { toggleToast } from "../Actions/ToggleToast";
import { Button } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BlogCarousel from './BlogCarousel';

class SearchFunction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            userEmail: "",
            pocAccess: false,
            session_id: null,
            query: "",
            searchResults: [],
            isLoading: false,
            blogItems: [],
            newTitle: "",
            newUrl: "",
            newImageUrl: "",
            selectedIndex: "new-index",
            inviteEmail: "",
            generatedLink: "",
            inviteOrgId: "",
            planType: "Annual",
            testers: [],
            organizations: [],
            startDate: "",
            endDate: "",
            pineconeIndexes: [],
            email: ""
        };
    }

    componentDidMount() {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        if (!isLoggedIn) {
            this.props.history.push('/login');
        }
        this.setState({ isLoggedIn });

        auth.onAuthStateChanged(user => {
            if (user) {
                this.setState({
                    isLoggedIn: true,
                    userEmail: user.email,
                });
                this.fetchProfileInformation(this.state.userEmail)
            } else {
                this.setState({
                    isLoggedIn: false,
                    userEmail: null,
                });
            }
        });
        this.fetchBlogUpdateItems();
        this.fetchOrganizations();
        this.fetchTesterList();
    }
    handleStartDateChange = (event) => {
        this.setState({ startDate: event.target.value });
    };

    handleEndDateChange = (event) => {
        this.setState({ endDate: event.target.value });
    };
    fetchPineconeIndexes = () => {
        const { startDate, endDate, email } = this.state;

        if (!email) {
            this.props.toggleToast({ show: true, type: "error", message: "Please enter your email address." });
            return;
        }

        this.setState({ pineconeIndexes: [] });

        fetch(API_Root + 'get-pinecone-index-ids', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                start_date: startDate,
                end_date: endDate,
                email: email
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            this.setState({ pineconeIndexes: data.indexes });
        })
        .catch(error => {
            console.error("Error fetching pinecone indexes:", error);
            this.props.toggleToast({ show: true, type: "error", message: "Error fetching Pinecone indexes." });
        });
    };
    fetchTesterList = () => {
        fetch(API_Root + 'get-tester-list')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ testers: data.testers });
            })
            .catch(error => {
                console.error("Error fetching tester list:", error);
                this.props.toggleToast({ show: true, type: "error", message: "Error fetching tester list." });
            });
    };
    fetchOrganizations = () => {
        fetch(API_Root + 'get-organizations')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ organizations: data.organizations });
            })
            .catch(error => {
                console.error("Error fetching organizations:", error);
                this.props.toggleToast({ show: true, type: "error", message: "Error fetching organizations." });
            });
    };

    fetchBlogUpdateItems = () => {
        fetch(API_Root + 'get-blog-update-items')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ blogItems: data });
            })
            .catch(error => {
                console.error("Error fetching blog update items:", error);
            });
    };

    fetchProfileInformation = (email) => {
        fetch(API_Root + 'get-profile-information', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({
                    pocAccess: data.access || false
                }, () => {
                    if (!this.state.pocAccess) {
                        this.props.history.push('/');
                        return null;
                    }
                });
            })
            .catch(error => {
            });
    };

    handleInputChange = (event) => {
        this.setState({ query: event.target.value });
    };

    handleSearch = () => {
        this.setState({ isLoading: true });
        fetch(API_Root + 'get-search-function-results', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: this.state.query,
                index: this.state.selectedIndex
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ searchResults: data.results, isLoading: false });
            })
            .catch(error => {
                console.error("Error fetching search results:", error);
                this.setState({ isLoading: false });
                this.props.toggleToast({ show: true, type: "error", message: error });
            });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleSearch();
        }
    };

    openUrl = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    handleNewTitleChange = (event) => {
        this.setState({ newTitle: event.target.value });
    };

    handleNewUrlChange = (event) => {
        this.setState({ newUrl: event.target.value });
    };

    handleImageUrlChange = (event) => {
        this.setState({ newImageUrl: event.target.value });
    };

    

    handleSubmit = () => {
        const { newTitle, newUrl, newImageUrl } = this.state;
        const formData = {
            title: newTitle,
            url: newUrl,
            img_url: newImageUrl
        };

        fetch(API_Root + 'create-blog-item', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            // Refresh the blog items list
            this.fetchBlogUpdateItems();
            // Clear the input fields
            this.setState({ newTitle: "", newUrl: "", newImageUrl: "" });
            this.props.toggleToast({ show: true, type: "success", message: data.message });
        })
        .catch(error => {
            console.error("Error creating blog item:", error);
            this.props.toggleToast({ show: true, type: "error", message: error });
        });
    };

    handleDelete = (id) => {
        fetch(API_Root + 'delete-blog-item', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                blog_id: id
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            // Refresh the blog items list
            this.props.toggleToast({ show: true, type: "success", message: data.message });
            this.fetchBlogUpdateItems();
        })
        .catch(error => {
            console.error("Error deleting blog item:", error);
            this.props.toggleToast({ show: true, type: "error", message: "Failed to delete blog item" });
        });
    };

    handleIndexChange = (event) => {
        this.setState({ selectedIndex: event.target.value });
    };

    handleInviteEmailChange = (event) => {
        this.setState({ inviteEmail: event.target.value });
    };
    
    handleInviteOrgIdChange = (event) => {
        this.setState({ inviteOrgId: event.target.value });
    };

    handlePlanTypeChange = (event) => {
        this.setState({ planType: event.target.value });
    };

    handleSendInvite = () => {
        const { inviteEmail, inviteOrgId, planType } = this.state;
    
        if (!inviteEmail || !inviteOrgId || !planType) {
            this.props.toggleToast({ show: true, type: "error", message: "Please enter both email address and Org ID." });
            return;
        }
    
        fetch(API_Root + 'generate-signup-link', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: inviteEmail, org_id: inviteOrgId, plan_type: planType }),
        })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(({ status, body }) => {
            if (status >= 400) {
                const errorMessage = body.error || "Failed to generate signup link.";
                this.props.toggleToast({ show: true, type: "error", message: errorMessage });
            } else if (body.signup_link) {
                this.setState({ generatedLink: body.signup_link });
            } else {
                this.props.toggleToast({ show: true, type: "error", message: "Failed to generate signup link." });
            }
        })
        .catch(error => {
            console.error("Error generating signup link:", error);
            this.props.toggleToast({ show: true, type: "error", message: "Error generating signup link." });
        });
    };

    render() {
        const { searchResults, isLoading, blogItems, planType, newTitle, newUrl, newImageUrl, selectedIndex, inviteEmail, inviteOrgId, generatedLink, userEmail, organizations, testers, pineconeIndexes, startDate, endDate, email } = this.state;
        // const responsive = {
        //     superLargeDesktop: {
        //         breakpoint: { max: 4000, min: 3000 },
        //         items: 6
        //     },
        //     desktop: {
        //         breakpoint: { max: 3000, min: 1024 },
        //         items: 5
        //     },
        //     tablet: {
        //         breakpoint: { max: 1024, min: 464 },
        //         items: 2
        //     },
        //     mobile: {
        //         breakpoint: { max: 464, min: 0 },
        //         items: 1
        //     }
        // };
        const allowedEmails = ['joseph@woyera.com', 'elizabeth@woyera.com', 'lizzaykim@gmail.com', 'cheezusk@gmail.com'];
        return (
            <div className='search-container'>
                <div className='search-box'>
                    <span><strong>Search Function</strong></span>
                    <div className='search-input'>
                        <input
                            type="text"
                            placeholder="Type your search query here"
                            value={this.state.query}
                            onChange={this.handleInputChange}
                            onKeyPress={this.handleKeyPress}
                        />
                        {/* <select value={selectedIndex} onChange={this.handleIndexChange}>
                            <option value="old-index">Old Index</option>
                            <option value="new-index">New Index</option>
                        </select> */}
                        <button onClick={this.handleSearch} disabled={isLoading}>
                            {isLoading ? <div className="loader"></div> : 'Search'}
                        </button>
                    </div>
                    <div className='results-container'>
                        {searchResults && searchResults.length > 0 ? (
                            <ul className='results-list'>
                                <li className='result-item'>
                                    <span className='result-id'><strong>#</strong></span>
                                    <span className='result-filename'><strong>Document</strong></span>
                                    <span className='result-url'><strong>Url</strong></span>
                                </li>
                                {searchResults.map((result, index) => (
                                    <li key={index} className='result-item'>
                                        <span className='result-id'>{index + 1}</span>
                                        <span className='result-filename'>{result.filename}</span>
                                        <span className='result-url'>
                                            <button onClick={() => this.openUrl(result.url)}><i className="fas fa-globe"></i></button>
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No results found</p>
                        )}
                    </div>
                    <span><strong>Carousel</strong></span>
                    <BlogCarousel blogItems={blogItems} openUrl={this.openUrl} />
                    <span><strong>Add a blog</strong></span>
                    <div className="new-blog-item-form">
                        <input
                            className='new-blog-item-input'
                            type="text"
                            placeholder="Enter title"
                            value={newTitle}
                            onChange={this.handleNewTitleChange}
                        />
                        <input
                            className='new-blog-item-input'
                            type="text"
                            placeholder="Enter URL"
                            value={newUrl}
                            onChange={this.handleNewUrlChange}
                        />
                        <input
                            className='new-blog-item-input'
                            type="text"
                            placeholder="Enter Image URL"
                            value={newImageUrl}
                            onChange={this.handleImageUrlChange}
                        />
                        <button className='new-blog-item-button' variant="primary" onClick={this.handleSubmit}>
                            Submit
                        </button>
                    </div>
                    <div className='blog-container'>
                        <ul className='blog-list'>
                            <li className='blog-item'>
                                <span className='blog-id'><strong>#</strong></span>
                                <span className='blog-filename'><strong>Title</strong></span>
                                <span className='blog-url'><strong>Url</strong></span>
                                <span className='blog-delete'><strong>Delete</strong></span>
                            </li>
                            {blogItems.map((item) => (
                                <li key={item.id} className='blog-item'>
                                    <span className='blog-id'>{item.id}</span>
                                    <span className='blog-filename'>{item.title}</span>
                                    <span className='blog-url'>
                                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                                            {item.url}
                                        </a>
                                    </span>
                                    <span className='blog-delete'>
                                        <button onClick={() => this.handleDelete(item.id)}><i className="fas fa-trash-alt"></i></button>
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {allowedEmails.includes(userEmail) && (
                        <div className='invite-container'>
                            <span><strong>Send Invite</strong></span><br />
                            {generatedLink && (
                                <div className='generated-link'>
                                    <p>Signup Link:</p>
                                    <div className='link-box'>{generatedLink}</div>
                                </div>
                            )}
                            <div className='invite-input-container'>
                                <input
                                    type="email"
                                    placeholder="Enter email address"
                                    value={inviteEmail}
                                    onChange={this.handleInviteEmailChange}
                                />
                                <input
                                    type="text"
                                    placeholder="Enter Org ID"
                                    value={inviteOrgId}
                                    onChange={this.handleInviteOrgIdChange}
                                />
                                <select value={planType} onChange={this.handlePlanTypeChange}>
                                    <option value="Daily">Daily</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Annual">Annual</option>
                                    <option value="Date">Date Range</option>
                                </select>
                                <button onClick={this.handleSendInvite}>
                                    Send Invite
                                </button>
                            </div>
                        </div>
                    )}
                    <div className='blog-container'>
                        <ul className='blog-list'>
                            <li className='organizations-item'>
                                <span className='blog-id'><strong>ID</strong></span>
                                <span className='blog-filename'><strong>Organization</strong></span>
                                <span className='blog-url'><strong>Daily Available</strong></span>
                                <span className='blog-delete'><strong>Monthly Available</strong></span>
                                <span className='blog-delete'><strong>Annual Available</strong></span>
                                <span className='blog-delete'><strong>Date Available</strong></span>
                            </li>
                            {organizations.map(org => (
                                <li key={org.id} className='organizations-item'>
                                    <span>{org.id}</span>
                                    <span>{org.organization}</span>
                                    <span>{org.total_daily_available}</span>
                                    <span>{org.total_monthly_available}</span>
                                    <span>{org.total_annual_available}</span>
                                    <span>{org.total_date_available}</span>
                                </li>
                            ))}
                            
                        </ul>
                    </div>
                    <span><strong>List of Testers</strong></span>
                    <div className='blog-container'>
                        <ul className='blog-list'>
                            <li className='testers-item'>
                                <span className='blog-id'><strong>ID</strong></span>
                                <span className='blog-filename'><strong>Email</strong></span>
                                <span className='blog-url'><strong>Role</strong></span>
                            </li>
                            {testers.map(tester => (
                                <li key={tester.id} className='testers-item'>
                                    <span>{tester.id}</span>
                                    <span>{tester.email}</span>
                                    <span>{tester.role}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <span><strong>List of Pinecone Indexes</strong></span>
                    <span>You will receive an email with multiple xlsx documents attached if there are too many results to display below.</span>
                    <div className='pinecone-index-container'>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => this.setState({ email: e.target.value })}
                            className="email-input"
                        />
                        <input
                            type="date"
                            value={startDate}
                            onChange={this.handleStartDateChange}
                            className="start-date-input"
                        />
                        <input
                            type="date"
                            value={endDate}
                            onChange={this.handleEndDateChange}
                            className="end-date-input"
                        />
                        <button className='fetch-index-button' onClick={this.fetchPineconeIndexes}>
                            Fetch Indexes
                        </button>
                    </div>

                    <div className='blog-container'>
                        <ul className='blog-list'>
                            <li className='pinecone-item'>
                                <span className='blog-id'><strong>id</strong></span>
                                <span className='blog-filename'><strong>s3key</strong></span>
                                <span className='blog-url'><strong>s3bucket</strong></span>
                                <span className='blog-url'><strong>pinecone_id</strong></span>
                                <span className='blog-url'><strong>created_dt</strong></span>
                            </li>
                            {pineconeIndexes.map(index => (
                                <li key={index.id} className='pinecone-item'>
                                    <span>{index.id}</span>
                                    <span>{index.s3key}</span>
                                    <span>{index.s3bucket}</span>
                                    <span>{index.pinecone_id}</span>
                                    <span>{index.created_dt}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = { toggleToast };

export default withRouter(connect(mapStateToProps, mapActionsToProps)(SearchFunction));
