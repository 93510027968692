import React, {Component} from 'react'
import Navbar from './Navbar';
import Footer from './Footer';

class AboutUs extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className='faq-grid-layout'>
               <Navbar />
               <div className='top-spacer' />
               <div className='banner'>
                    <div className='banner-container'>
                        <h1>About Us</h1>
                    </div>
               </div>
               <div className='mainsection'>
                    <div className='main-container'>
                        <h1>About Us</h1>
                        <p>E&S Solutions is a woman-owned business founded in 2024 by Ildiko Almasi Simsic. Its mission is to leverage modern AI technology to enable E&S performance practitioners to work more efficiently and ultimately deliver more impact.</p>
                        <p>The idea for E&S Solutions was inspired by the recent technological breakthroughs in AI that have yet to reach the E&S industry. As an industry insider, Ildiko is well aware of the most time-consuming tasks that are necessary but not the best use of time for technical experts. She wanted to enable industry professionals to streamline their research and information gathering process to free up time to focus on what matters most. That is, making high-quality professional judgments, analysis, and strategic decisions faster than they previously could and ultimately completing more projects.</p>
                        <p>The company's first product, myESRA™, is the world’s first AI-powered research assistant designed specifically for E&S work. It delivers fully cited, reliable answers for E&S technical experts and analysts.</p>
                        <h1>Our Founder</h1>
                        <p>Ildiko Almasi Simsic is a seasoned social development specialist renowned for her nearly 15-year journey working with esteemed International Financial Institutions such as IFC, World Bank, EBRD, and AIIB. With unparalleled expertise in social impact management and assessment, resettlement, labor, and supply chain management, Ildiko's voice resonates as a driving force in the realm of social safeguards. She has steered high-risk, complex projects across five continents, spanning diverse sectors, including energy, mining, transportation, waste management, water and sanitation, education, healthcare, financial intermediaries, agriculture (cotton sector), and infrastructure development.  She is the author of the award-winning book What is a Social Impact?, and her writing has been featured in many publications including Forbes, Reuters, and Fast Company.</p>
                        <p>
                            <a href="https://www.ildiko-almasi.com/" target="_blank" rel="noopener noreferrer">https://www.ildiko-almasi.com/</a>
                        </p>
                        <p>
                            <a href="https://www.whatisasocialimpact.com/" target="_blank" rel="noopener noreferrer">
                                https://www.whatisasocialimpact.com/
                            </a>
                        </p>
                    </div>
               </div>
               <Footer />
            </div>
        )
    }
}

export default AboutUs