import React, { Component } from 'react';
import { connect } from 'react-redux';
import { auth } from '../Authentication/firebase/firebase';
import { withRouter, Link } from 'react-router-dom';
import API_Root from '../Constants/API_Root';
import { toggleToast } from "../Actions/ToggleToast";
import '../private-chatbot.css'

class PrivateChatbot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            messages: [],
            isLoading: false,
            threadId: null,
            isSidebarOpen: true,
            darkMode: false,
            isLoggedIn: false,
            userEmail: "",
            selectedFiles: [],
            filenames: [],
            pocAccess: false,
            generatedUserId: null,
            session_id: null,
            sessions: [],
            isOwner: false,
            instanceExists: false,
            isAdmin: false,
            groups: [],
            showTeamMembers: false,
            isModalVisible: false,
            modalPosition: { x: 0, y: 0 },
            ingestionJobStatus: null
        };
    }

    componentDidMount() {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        if (!isLoggedIn) {
            this.props.history.push('/login');
        }
        this.setState({ isLoggedIn });

        auth.onAuthStateChanged(user => {
            if (user) {
                this.setState({
                    isLoggedIn: true,
                    userEmail: user.email,
                });
                this.fetchProfileInformation(this.state.userEmail)
                this.fetchUserSessions(this.state.userEmail)
                this.checkOwnership(this.state.userEmail)
                this.checkAdminRole(this.state.userEmail);
                this.fetchUserGroups(this.state.userEmail);  // Fetch user groups
                this.fetchFiles(this.state.userEmail); // Fetch files
                this.checkIngestionJobStatus(this.state.userEmail);
            } else {
                this.setState({
                    isLoggedIn: false,
                    userEmail: null,
                });
            }
        });

    }

    toggleSidebar = () => {
        this.setState(prevState => ({
            isSidebarOpen: !prevState.isSidebarOpen,
            showTeamMembers: prevState.isSidebarOpen ? false : prevState.showTeamMembers
        }), () => {
            this.forceUpdate();
        });
    }

    handleContextMenu = (event, sessionId) => {
        event.preventDefault();
        
        const menuWidth = 200; // Adjust based on the expected width of your menu
        const menuHeight = 500; // Adjust based on the expected height of your menu
    
        let x = event.clientX;
        let y = event.clientY;
    
        // Adjust for any potential offsets
        const offsetX = 0;
        const offsetY = -50;
    
        // Check if the menu would overflow the right edge of the viewport
        if (x + menuWidth > window.innerWidth) {
            x = window.innerWidth - menuWidth;
        }
    
        // Check if the menu would overflow the bottom edge of the viewport
        if (y + menuHeight > window.innerHeight) {
            y = window.innerHeight - menuHeight;
        }
    
        this.setState({
            isModalVisible: true,
            modalPosition: { x: x + offsetX, y: y + offsetY },
            selectedSessionId: sessionId
        });
    };
    
    closeModal = () => {
        this.setState({
            isModalVisible: false,
            modalPosition: { x: 0, y: 0 },
            selectedSessionId: null
        });
    };

    checkIngestionJobStatus = (email) => {
        fetch(API_Root + 'get-ingestion-job-status', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Ingestion Job Status:', data);
    
            const status = data.ingestionJob?.status;
    
            if (status === 'IN_PROGRESS') {
                this.props.toggleToast({ show: true, type: "info", message: "The chatbot is preparing a new environment. Please try again at a later time." });
            } else {
                this.setState({ ingestionJobStatus: data });
            }
        })
        .catch(error => {
            console.error('Error checking ingestion job status:', error);
        });
    }

    checkOwnership = (email) => {
        fetch(API_Root + 'check-ownership', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ isOwner: data.isOwner, instanceExists: data.instanceExists });
            })
            .catch(error => {
                console.error('Error checking ownership:', error);
            });
    }

    checkAdminRole = (email) => {
        fetch(API_Root + 'check-admin-role', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ isAdmin: data.isAdmin });
            })
            .catch(error => {
                console.error('Error checking admin role:', error);
            });
    }

    createInstance = () => {
        const { userEmail } = this.state;

        this.setState({ isLoading: true });

        fetch(API_Root + 'create-private-instance', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userEmail }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ isLoading: false });
                this.props.toggleToast({ show: true, type: "success", message: "Private instance created successfully" });
                this.checkOwnership(userEmail); // Refresh ownership and instance status
            })
            .catch(error => {
                console.error('Error creating instance:', error);
                this.setState({ isLoading: false });
                this.props.toggleToast({ show: true, type: "error", message: "Error creating private instance." });
            });
    }

    fetchProfileInformation = (email) => {
        fetch(API_Root + 'get-profile-information', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                ip_address: this.state.ip_address,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({
                    firstName: data.first_name || 'None',
                    lastName: data.last_name || 'None',
                    pocAccess: data.access || false
                }, () => {
                    if (!this.state.pocAccess) {
                        this.props.history.push('/');
                        return null;
                    }
                });
            })
            .catch(error => {
            });
    };

    fetchUserGroups = (email) => {
        fetch(API_Root + 'get-private-chatbot-groups', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Fetched groups:', data.groups);
            this.setState({ groups: data.groups || [] });
        })
        .catch(error => {
            console.error('Error fetching user groups:', error);
        });
    }

    handleFileUpload = () => {
        const { selectedFiles, userEmail } = this.state;
        if (selectedFiles.length === 0) {
            this.props.toggleToast({ show: true, type: "warning", message: "Please select files for upload." });
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach(fileObj => {
            formData.append('files', fileObj.file);
        });
        formData.append('userEmail', userEmail); // Add userEmail to FormData

        this.setState({ isLoading: true });

        fetch(API_Root + 'upload-private-files', {
            method: 'POST',
            body: formData,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const updatedFiles = selectedFiles.map(file => {
                    const status = data.fileStatuses[file.file.name] || 'Unknown';
                    return { ...file, status };
                });

                this.setState({ isLoading: false, selectedFiles: updatedFiles });
                this.props.toggleToast({ show: true, type: "success", message: "Files uploaded successfully" });
            })
            .catch(error => {
                console.error('Error uploading files:', error);
                this.setState({ isLoading: false });
                this.props.toggleToast({ show: true, type: "error", message: "Error uploading files, please try again." });
            });
    }

    handleFileChange = (event) => {
        const files = Array.from(event.target.files).map(file => ({ file, status: 'Pending' }));
        this.setState({ selectedFiles: [...this.state.selectedFiles, ...files] });
    }

    updateFileStatus = (status) => {
        this.setState(prevState => ({
            selectedFiles: prevState.selectedFiles.map(fileObj => ({ ...fileObj, status }))
        }));
    }

    handleFileDelete = (index) => {
        this.setState(prevState => {
            const selectedFiles = prevState.selectedFiles.filter((_, i) => i !== index);
            return { selectedFiles };
        });
    }

    handleMessageChange = (event) => {
        this.setState({ message: event.target.value });
    }

    getChatResponse = () => {
        const { message, userEmail, session_id } = this.state;
        if (!message.trim()) {
            return;
        }

        this.setState({ isLoading: true });

        const fetchChatResponse = (sessionId) => {
            fetch(API_Root + 'private-chat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prompt: message,
                    email: userEmail,
                    use_knowledge_base: true,
                    session_id: sessionId
                }),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    const newMessage = { sender: 'Bot', text: data.response, type: 'response' };
                    this.setState(prevState => ({
                        messages: [...prevState.messages, { sender: 'You', text: message, type: 'sent' }, newMessage],
                        message: '',
                        isLoading: false,
                    }));
                })
                .catch(error => {
                    console.error('Error getting chat response:', error);
                    this.setState({ isLoading: false });
                    this.props.toggleToast({ show: true, type: "error", message: "Error getting chat response, please try again." });
                });
        };

        if (!session_id) {
            this.createNewChatSession().then(newSessionId => {
                fetchChatResponse(newSessionId);
            }).catch(error => {
                console.error('Error creating new chat session:', error);
                this.setState({ isLoading: false });
                this.props.toggleToast({ show: true, type: "error", message: "Error creating new chat session" });
            });
        } else {
            fetchChatResponse(session_id);
        }
    }

    createNewChatSession = () => {
        const { userEmail } = this.state;

        this.setState({ isLoading: true });

        return fetch(API_Root + 'private-chatbot-create-session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: userEmail }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ session_id: data.session_id, messages: [], message: '', isLoading: false });
                this.fetchUserSessions(userEmail);
                return data.session_id;
            })
            .catch(error => {
                console.error('Error creating new chat session:', error);
                this.setState({ isLoading: false });
                this.props.toggleToast({ show: true, type: "error", message: "Error creating new chat session" });
                throw error;
            });
    }

    fetchUserSessions = (email) => {
        fetch(API_Root + 'private-chatbot-sessions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ sessions: data.sessions });
            })
            .catch(error => {
                console.error('Error fetching user sessions:', error);
            });
    }

    fetchSessionConversations = (session_id) => {
        fetch(API_Root + 'private-chatbot-conversations', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ session_id }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            const messages = data.conversations.flatMap(conversation => [
                { sender: `${conversation.first_name} ${conversation.last_name}`, text: conversation.prompt, type: 'sent' },
                { sender: 'Bot', text: conversation.response, type: 'response' }
            ]);
            this.setState({ messages });
        })
        .catch(error => {
            console.error('Error fetching session conversations:', error);
        });
    }

    fetchFiles = (email) => {
        fetch(API_Root + 'get-all-private-files', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            if (data.error) {
                throw new Error(data.error);
            }
            this.setState({ filenames: data.filenames });
        })
        .catch(error => {
            console.error('Error fetching files:', error);
        });
    }

    selectChatSession = (session_id) => {
        this.setState({ session_id, messages: [] });
        this.fetchSessionConversations(session_id);
    }

    truncateSessionName = (name) => {
        const words = name.split(' ');
        if (words.length <= 3) {
            return name;
        }
        return words.slice(0, 3).join(' ') + '...';
    }

    deleteSession = () => {
        const { selectedSessionId } = this.state;
    
        fetch(API_Root + 'delete-private-chatbot-session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ session_id: selectedSessionId }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                // Refresh the session list
                this.fetchUserSessions(this.state.userEmail);
                this.closeModal();
            })
            .catch(error => {
                console.error('Error deleting session:', error);
                this.props.toggleToast({ show: true, type: "error", message: "Error deleting session, please try again." });
            });
    };

    handleDeleteAll = () => {
        const { userEmail } = this.state;
    
        fetch(API_Root + 'private-chatbot-delete-all', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: userEmail }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.props.toggleToast({ show: true, type: "success", message: "All files and vectors deleted successfully" });
                this.setState({ filenames: [] });
            })
            .catch(error => {
                console.error('Error deleting all files:', error);
                this.props.toggleToast({ show: true, type: "error", message: "Error deleting all files, please try again." });
            });
    }

    render() {

        const { message, messages, isLoading, isSidebarOpen, selectedFiles, sessions, isOwner, instanceExists, isAdmin, groups, showTeamMembers, isModalVisible, modalPosition } = this.state;
        console.log(this.state)
        return (
            <div>
                <header className="header">
                    <div className="header-left">
                        <button onClick={this.toggleSidebar} className="menu-icon-btn" aria-label='Expand or shrink sidebar button'>
                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" className="menu-icon1">
                                <g><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></g>
                            </svg>

                        </button>
                        <div className='logo' />
                        <span className='logo-text' role="heading"><Link to="/" style={{ color: '#3E826D' }}>myESRA™</Link></span>
                    </div>
                </header>
                <div className='content'>
                    <div className={`sidebar${isSidebarOpen ? ' open' : ''}`} id="sidebar" role="sidebar" aria-label="Sidebar">
                        <div className="hidden-sidebar">
                            <div className='top-sidebar'>
                                <button className='sidebar-item' aria-label='New chat button example' onClick={this.createNewChatSession}>
                                    <div className='sidebar-item-left'>
                                        <svg className='sidebar-icon' xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>

                                    </div>
                                    <div className='sidebar-item-right'>
                                        New Chat
                                    </div>
                                </button>
                            </div>
                            <div className='middle-sidebar'>
                                {sessions.map(session => (
                                    <button
                                        key={session.id}
                                        className='sidebar-item'
                                        aria-label='Chat conversation example'
                                        onClick={() => this.selectChatSession(session.id)}
                                        onContextMenu={(event) => this.handleContextMenu(event, session.id)}
                                    >
                                        <div className='sidebar-item-left'>
                                            <svg className="sidebar-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                <path fill-rule="evenodd" d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6.616l-2.88 2.592C8.537 20.461 7 19.776 7 18.477V17H5a2 2 0 0 1-2-2V6Zm4 2a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H7Zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm-8 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Zm5 0a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                        <div className='sidebar-item-right'>
                                            {this.truncateSessionName(session.chat_name)}
                                        </div>
                                    </button>
                                ))}
                            </div>
                            {isModalVisible && (
                                <div
                                    className="context-menu"
                                    style={{
                                        position: 'absolute',
                                        top: modalPosition.y,
                                        left: modalPosition.x,
                                        backgroundColor: 'white',
                                        border: '1px solid #ccc',
                                        padding: '10px',
                                        zIndex: 1000,
                                    }}
                                >
                                    <button className="delete-session-btn" onClick={this.deleteSession}>Delete</button>
                                </div>
                            )}
                            <div className='bottom-sidebar'>
                                <div className={`button-container ${showTeamMembers ? 'expanded' : ''}`}>
                                    <button className='sidebar-item' aria-label='Show Team Members' onClick={() => this.setState(prevState => ({ showTeamMembers: !prevState.showTeamMembers }))}>
                                        <div className='sidebar-item-left'>
                                            <svg className="sidebar-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 640 512">
                                                <path d="M0 24C0 10.7 10.7 0 24 0H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 48 0 37.3 0 24zM0 488c0-13.3 10.7-24 24-24H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM83.2 160a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM32 320c0-35.3 28.7-64 64-64h96c12.2 0 23.7 3.4 33.4 9.4c-37.2 15.1-65.6 47.2-75.8 86.6H64c-17.7 0-32-14.3-32-32zm461.6 32c-10.3-40.1-39.6-72.6-77.7-87.4c9.4-5.5 20.4-8.6 32.1-8.6h96c35.3 0 64 28.7 64 64c0 17.7-14.3 32-32 32H493.6zM391.2 290.4c32.1 7.4 58.1 30.9 68.9 61.6c3.5 10 5.5 20.8 5.5 32c0 17.7-14.3 32-32 32h-224c-17.7 0-32-14.3-32-32c0-11.2 1.9-22 5.5-32c10.5-29.7 35.3-52.8 66.1-60.9c7.8-2.1 16-3.1 24.5-3.1h96c7.4 0 14.7 .8 21.6 2.4zm44-130.4a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM321.6 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" />
                                            </svg>
                                        </div>
                                        <div className='sidebar-item-right'>
                                            Team
                                        </div>
                                    </button>
                                    <div className={`team-container ${showTeamMembers ? 'expanded' : ''}`}>
                                        {showTeamMembers && (
                                            <div className="team-members expanded">
                                                <div className="team-member-header">
                                                    <div className="team-member-column">Name</div>
                                                    <div className="team-member-column">Role</div>
                                                </div>
                                                {groups.map((member, index) => (
                                                    <div key={index} className="team-member">
                                                        <div className="team-member-column-left">{member.first_name} {member.last_name}</div>
                                                        <div className="team-member-column-right">{member.user_role}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {isOwner && !instanceExists && (
                                    <button className='sidebar-item' aria-label='Create Instance' onClick={this.createInstance}>
                                        <div className='sidebar-item-left'>
                                            <svg className="sidebar-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                <path fill-rule="evenodd" d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6.616l-2.88 2.592C8.537 20.461 7 19.776 7 18.477V17H5a2 2 0 0 1-2-2V6Zm4 2a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H7Zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm-8 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Zm5 0a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                        <div className='sidebar-item-right'>
                                            Create Instance
                                        </div>
                                    </button>
                                )}

                                {/* {groups && groups.length > 0 && (
                                    <div className='groups-section'>
                                        {groups.map(group => (
                                            <div key={group.group_id} className='group'>
                                                <button className='sidebar-item'>
                                                    <div className='sidebar-item-left'>
                                                        <svg className="sidebar-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 640 512">
                                                            <path d="M0 24C0 10.7 10.7 0 24 0H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 48 0 37.3 0 24zM0 488c0-13.3 10.7-24 24-24H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM83.2 160a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM32 320c0-35.3 28.7-64 64-64h96c12.2 0 23.7 3.4 33.4 9.4c-37.2 15.1-65.6 47.2-75.8 86.6H64c-17.7 0-32-14.3-32-32zm461.6 32c-10.3-40.1-39.6-72.6-77.7-87.4c9.4-5.5 20.4-8.6 32.1-8.6h96c35.3 0 64 28.7 64 64c0 17.7-14.3 32-32 32H493.6zM391.2 290.4c32.1 7.4 58.1 30.9 68.9 61.6c3.5 10 5.5 20.8 5.5 32c0 17.7-14.3 32-32 32h-224c-17.7 0-32-14.3-32-32c0-11.2 1.9-22 5.5-32c10.5-29.7 35.3-52.8 66.1-60.9c7.8-2.1 16-3.1 24.5-3.1h96c7.4 0 14.7 .8 21.6 2.4zm44-130.4a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM321.6 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" />
                                                        </svg>
                                                    </div>
                                                    {isSidebarOpen && (
                                                        <div className='sidebar-item-right'>
                                                            {group.group_name}
                                                        </div>
                                                    )}
                                                </button>
                                                {isSidebarOpen && (
                                                    <ul>
                                                        {group.members.map(member => (
                                                            <li key={member.user_id}>{member.first_name} {member.last_name}</li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )} */}


                                {/* {groups && groups.length > 0 && (
                                <div className='groups-section'>
                                    {groups.map(group => (
                                        <div key={group.group_id} className='group'>
                                            <button className='sidebar-item'>
                                                <div className='sidebar-item-left'>
                                                    <svg className="sidebar-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 640 512">
                                                        <path d="M0 24C0 10.7 10.7 0 24 0H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 48 0 37.3 0 24zM0 488c0-13.3 10.7-24 24-24H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM83.2 160a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM32 320c0-35.3 28.7-64 64-64h96c12.2 0 23.7 3.4 33.4 9.4c-37.2 15.1-65.6 47.2-75.8 86.6H64c-17.7 0-32-14.3-32-32zm461.6 32c-10.3-40.1-39.6-72.6-77.7-87.4c9.4-5.5 20.4-8.6 32.1-8.6h96c35.3 0 64 28.7 64 64c0 17.7-14.3 32-32 32H493.6zM391.2 290.4c32.1 7.4 58.1 30.9 68.9 61.6c3.5 10 5.5 20.8 5.5 32c0 17.7-14.3 32-32 32h-224c-17.7 0-32-14.3-32-32c0-11.2 1.9-22 5.5-32c10.5-29.7 35.3-52.8 66.1-60.9c7.8-2.1 16-3.1 24.5-3.1h96c7.4 0 14.7 .8 21.6 2.4zm44-130.4a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM321.6 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" />
                                                    </svg>
                                                </div>
                                                {isSidebarOpen && (
                                                    <div className='sidebar-item-right'>
                                                        {group.group_name}
                                                    </div>
                                                )}
                                            </button>
                                            {isSidebarOpen && (
                                                <ul>
                                                    {group.members.map(member => (
                                                        <li key={member.user_id}>{member.username}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )} */}

                                {isAdmin && (
                                    <button className='sidebar-item' aria-label='File Upload' onClick={() => this.fileInputRef.click()}>
                                        <div className='sidebar-item-left'>
                                            <svg className="sidebar-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 96 960 960">
                                                <path d="M480 776q-17 0-28.5-11.5T440 736V564l-69 69q-12 12-28 12t-28-12q-11-11-11.5-27.5T315 578l161-161q6-6 13.5-9.5T504 404q8 0 15.5 3.5T533 417l160 161q12 12 11.5 28T693 634q-11 12-28 12t-28-12l-69-69v172q0 17-11.5 28.5T480 776ZM240 936q-33 0-56.5-23.5T160 856V696q0-17 11.5-28.5T200 656q17 0 28.5 11.5T240 696v160h480V696q0-17 11.5-28.5T760 656q17 0 28.5 11.5T800 696v160q0 33-23.5 56.5T720 936H240Z"/>
                                            </svg>
                                        </div>
                                        <div className='sidebar-item-right'>
                                            Upload Files
                                        </div>
                                        <input
                                            type="file"
                                            multiple
                                            style={{ display: 'none' }}
                                            ref={(ref) => this.fileInputRef = ref}
                                            onChange={this.handleFileChange}
                                        />
                                    </button>
                                )}

                                <button className='sidebar-item' aria-label='Profile button'>
                                    <div className='sidebar-item-left'>
                                        <svg className="sidebar-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd" d="M4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4Zm10 5a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm-8-5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm1.942 4a3 3 0 0 0-2.847 2.051l-.044.133-.004.012c-.042.126-.055.167-.042.195.006.013.02.023.038.039.032.025.08.064.146.155A1 1 0 0 0 6 17h6a1 1 0 0 0 .811-.415.713.713 0 0 1 .146-.155c.019-.016.031-.026.038-.04.014-.027 0-.068-.042-.194l-.004-.012-.044-.133A3 3 0 0 0 10.059 14H7.942Z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <div className='sidebar-item-right'>
                                        Profile
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <main id="main-content" role="main" aria-label="Main content">

                        <div className="chatbox">
                            <div className="chat-messages">
                                {messages.length === 0 ? (
                                    <div className='welcome-message'>
                                        <div className="welcome-logo" />
                                        <span role="heading"><h1>Welcome to myESRA™ Private</h1></span>
                                        <div className='sample-prompts-container'>
                                            <ol>
                                                    <li>Upload files to your private knowledge base</li>
                                                    <li>Resolve errors, if any, to complete upload</li>
                                                    <li>Ask the PrivateBot questions about the knowledge base</li>
                                                    <li>Delete files & conversation history</li>
                                            </ol>
                                        </div>
                                    </div>
                                ) : (
                                    messages.map((msg, index) => (
                                        <div key={index} className={`message-${msg.type}`}>
                                            <span className="user-name">{msg.sender}:</span> {typeof msg.text === 'string' ? msg.text : msg.text}
                                        </div>
                                    ))
                                )}
                            </div>
                            <div className="message-input">
                                <input
                                    id="message-input"
                                    type="text"
                                    aria-label='Input bar. Type your prompt here'
                                    value={message}
                                    onChange={this.handleMessageChange}
                                    placeholder="Type a message..."
                                />
                                <button id="send-btn" aria-label='Send prompt/question button' onClick={this.getChatResponse} disabled={isLoading}>
                                    {isLoading ? (
                                        <div className="loader"></div>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h13M12 5l7 7-7 7" /></svg>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className="right-section">
                                <div className="right-table">
                                    {selectedFiles.length > 0 && (
                                        <div className="file-bubbles">
                                            {selectedFiles.map((fileObj, index) => (
                                                <div key={index} className="file-bubble">
                                                    <div className="file-info">
                                                        <span className="file-name">{fileObj.file.name}</span>
                                                        <span className="file-status">{fileObj.status}</span>
                                                    </div>
                                                    <button className="delete-btn" onClick={() => this.handleFileDelete(index)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                                        </svg>
                                                    </button>
                                                </div>
                                            ))}
                                            <button className="upload-btn" onClick={this.handleFileUpload} disabled={isLoading}>
                                                {isLoading ? 'Uploading...' : 'Upload'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                                
                                <div className="file-list">
                                    <h1>Files in Database</h1>
                                    <ul>
                                        {this.state.filenames.map((filename, index) => (
                                            <li key={index}>{filename}</li>
                                        ))}
                                    </ul>
                                    {isAdmin && (
                                        <button className="delete-session-btn" onClick={this.handleDeleteAll}>Delete All Files and Conversations</button>
                                    )}
                                </div>
                                
                            </div>
                    </main>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = { toggleToast };

export default withRouter(connect(mapStateToProps, mapActionsToProps)(PrivateChatbot));