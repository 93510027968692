import React, {Component} from 'react'
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';

class Faqs extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className='faq-grid-layout'>
               <Navbar />
               <div className='top-spacer' />
               <div className='banner'>
                    <div className='banner-container'>
                        <h1>FAQs</h1>
                    </div>
               </div>
               <div className='mainsection'>
                    <div className='faqs-container'>
                        <h1>What is myESRA™?</h1>
                        <p><strong>myESRA™</strong> is an AI-powered E&S research assistant. It comprises a deep and broad knowledge base of E&S relevant documents and data from many sources accessed by a chatbot that has been fine-tuned for this specific work.</p>

                        <h1>Do I need to upload data?</h1>
                        <p>No, users do not upload documents and are not chatting with their own PDFs. E&S Solutions collects, processes, and stores all the data used by <strong>myESRA™</strong>. The data is sourced from hundreds of websites globally and covers the full spectrum of E&S relevant information.</p>

                        <h1>What data is in your knowledge base?</h1>
                        <p>The types of data sources <strong>myESRA™</strong> utilizes include E&S country-level statistics, past project documents, industry best practices, policies and country legislation, guidance notes, survey data and templates for surveys, E&S baseline data from disclosure documents, ESAPs and publicly disclosed project documents, report templates, and much more.</p>
                    
                        <h1>How up to date is the data?</h1>
                        <p>Our objective is to have the most complete and up to date knowledge base in the industry. Data sources are checked regularly, and the latest data is added to our knowledge base. New sources are added as they become available.</p>
                    
                        <h1>Who is myESRA™ designed for?</h1>
                        <p><strong>myESRA™</strong> is designed for E&S performance practitioners who need to deliver impact assessments, due diligence reports, ESAPs, management plans, and project documents for development projects. </p>
                    
                        <h1>What are examples of use cases myESRA™ can help with?</h1>
                        <p>Just some of the ways <strong>myESRA™</strong> can assist with current E&S workflows:</p>
                        <ul>
                            <li>Quick and easy access to ESIA baseline data, past project data, and legal data.</li>
                            <li>Build surveys for primary data collection including socio-economic household surveys, asset inventories, biodiversity assessment and monitoring surveys, and groundwater measurement surveys.</li>
                            <li>Policy comparison with local legislation for E&S aspects.</li>
                            <li>Access institutional knowledge that is deeply buried and hard to find.</li>
                            <li>Easily find resettlement valuation data for different types of land based on national valuation methodology, international valuation methodology and past projects.</li>
                        </ul>
                    
                        <h1>Can I submit data or recommend a source to be included in your knowledge base?</h1>
                        <p>Yes. We welcome feedback and recommendations for new sources to add to our knowledge base. Feel free to <Link to="/contact-us">contact us</Link> with any recommendations or feedback. Currently, we only work with publicly available, free sources. </p>
                    
                        <h1>How are the files renamed?</h1>
                        <p>We collect, process, and store all the data used by <strong>myESRA™</strong>.  As part of this procedure, we have used AI to rename all the files in our unique format to make reference file names consistent and easy to understand at a glance.</p>
                        <p>Each file name includes the document type, IFI or publisher, year, country, sector, project name, client name and language. For statistical data we included the year of publication and the name of the URL. All files listed as sources are clickable with the original document opening in a new tab. This helps you verify the information and provides more context if needed.</p>

                        <h1>How is <strong>myESRA™</strong> different from ChatGPT?</h1>
                        <p><strong>myESRA™</strong> combines advanced LLM technology with a proprietary knowledge base that is used to fine-tune the model specifically for global E&S risk and impact management projects. This looks and feels like ChatGPT but differs in a very important way.</p>
                        <p>Unlike <strong>myESRA™</strong>, ChatGPT has been trained on the entire internet, which may include incorrect or misleading information and information that lacks important domain-specific context.  This limits the usefulness of such a general model. ChatGPT is also not up to date and does not include citations, which makes it very difficult to verify its output.</p>
                    
                        <h1>Can I trust the accuracy of the information provided?</h1>
                        <p>All responses include a citation with a link to the original source document. The user can open this document to verify information or get more context around the answer. Documents and responses can also be downloaded.</p>
                    
                        <h1>Do you only use English sources?</h1>
                        <p>No. <strong>myESRA™</strong> has access to local language sources that are translated into English before being added to the knowledge base.</p>
                    
                        <h1>Do I need to include a disclaimer regarding the use of AI in my project report?</h1>
                        <p><strong>myESRA™</strong> is a research assistant designed to make the research process much more efficient by consolidating all the necessary documents and making them easier to access. It does not write reports, analyze data, or interpret information. Therefore, the decision to include a disclaimer would be up to individual or company policy.</p>
                    
                        <br />
                        <h2>General support questions:</h2>
                        
                        <h1>How do I sign up?</h1>
                        <p>Click on the ‘Get started’ button and follow the instructions.</p>

                        <h1>How do I log in?</h1>
                        <p><strong>myESRA™</strong> can be accessed through the ‘Get started’ button that will take you to the login page.</p>

                        <h1>How can I cancel my subscription?</h1>
                        <p>Subscriptions can be canceled at any time; however, you will only lose access to <strong>myESRA™</strong> at the end of the subscription period. </p>
                    
                        <h1>What should I do if I encounter any issues or need troubleshooting assistance?</h1>
                        <p>The ‘Help’ button will take you to the page with the most frequent issues and solutions. If you still have questions, you can <Link to="/contact-us">contact us</Link>, and we will get back to you. </p>
                    
                        <h1>How do you store my personal data? </h1>
                        <p>Please refer to our privacy policy and data protection policy for more details.</p>

                        <h1>Do you share my data with third parties?</h1>
                        <p>Please refer to our privacy policy and data protection policy for more details.</p>

                        <h1>What is the naming format used for downloadable source files?</h1>
                        <p>The name contains the following information for each file (where available): </p>
                        <ul>
                            <li>Document Type</li>
                            <li>IFI or publisher or consultancy</li>
                            <li>Year</li>
                            <li>Country or countries</li>
                            <li>Sector</li>
                            <li>Project Name</li>
                            <li>Client Name</li>
                            <li>Language</li>
                        </ul>

                        <h1>What are the definitions of the acronyms found within the downloadable file name?</h1>
                        <br />
                        <table border="1" cellpadding="5" cellspacing="0">
                            <thead>
                                <tr>
                                <th><strong>Code</strong></th>
                                <th><strong>Document Type</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td>A</td><td>Audit</td></tr>
                                <tr><td>ADD</td><td>Addendum</td></tr>
                                <tr><td>AMR</td><td>Annual Monitoring Report</td></tr>
                                <tr><td>APFS</td><td>Audited Project Financial Statements</td></tr>
                                <tr><td>BAP</td><td>Biodiversity Action Plan</td></tr>
                                <tr><td>BD</td><td>Board Document</td></tr>
                                <tr><td>CA</td><td>Contract Award</td></tr>
                                <tr><td>CA</td><td>Completion Audit</td></tr>
                                <tr><td>CEGDP</td><td>Community and Ethnic Group Development Plan</td></tr>
                                <tr><td>CEMP</td><td>Construction Environmental Management Plan</td></tr>
                                <tr><td>CORR</td><td>Corrigendum</td></tr>
                                <tr><td>CP</td><td>Concept Paper</td></tr>
                                <tr><td>DD</td><td>Due Diligence or DDR or Due Diligence Report or EDDR</td></tr>
                                <tr><td>EA</td><td>Environmental Assessment</td></tr>
                                <tr><td>EARF</td><td>Environmental Assessment and Review Framework</td></tr>
                                <tr><td>ECAR</td><td>Environmental Compliance Audit Report</td></tr>
                                <tr><td>EDDR</td><td>EIA Due Diligence Report</td></tr>
                                <tr><td>EGDF</td><td>Ethnic Group Development Framework</td></tr>
                                <tr><td>EHIA</td><td>Environmental and Health Impact Assessment</td></tr>
                                <tr><td>EIA</td><td>Environmental Impact Assessment</td></tr>
                                <tr><td>EOI</td><td>Expression of Interest</td></tr>
                                <tr><td>ESAP</td><td>Environmental and Social Action Plan</td></tr>
                                <tr><td>ESCAP</td><td>Environmental and Social Action Plan</td></tr>
                                <tr><td>ESCP</td><td>Environmental and Social Compliance</td></tr>
                                <tr><td>ESD</td><td>Environmental and Social Documents</td></tr>
                                <tr><td>ESDDR</td><td>Environmental and Social Due Diligence Report</td></tr>
                                <tr><td>ESIA</td><td>Environmental and Social Impact Assessment</td></tr>
                                <tr><td>ESMF</td><td>Environmental and Social Management Framework or Environmental and Social Management Plan Framework</td></tr>
                                <tr><td>ESMP</td><td>Environmental and Social Management Plan</td></tr>
                                <tr><td>ESMS</td><td>Environmental and Social Management System Assessment</td></tr>
                                <tr><td>ESR</td><td>Environmental and Social Review</td></tr>
                                <tr><td>FS</td><td>Feasibility Study</td></tr>
                                <tr><td>GAP</td><td>Gender Action Plan</td></tr>
                                <tr><td>GPN</td><td>Good Practice Note</td></tr>
                                <tr><td>IEE</td><td>Initial Environmental Evaluation</td></tr>
                                <tr><td>IFB</td><td>Invitation for Bids</td></tr>
                                <tr><td>IFT</td><td>Invitation for Tenders</td></tr>
                                <tr><td>IPPF</td><td>Indigenous Peoples Planning Framework</td></tr>
                                <tr><td>IPSA</td><td>Initial Poverty and Social Analysis</td></tr>
                                <tr><td>LA</td><td>Loan Agreement</td></tr>
                                <tr><td>LAF</td><td>Land Acquisition Framework</td></tr>
                                <tr><td>LALRP</td><td>Land Acquisition and Livelihood Restoration Plan</td></tr>
                                <tr><td>LAP</td><td>Land Acquisition Program</td></tr>
                                <tr><td>LRF</td><td>Livelihood Restoration Framework</td></tr>
                                <tr><td>LRP</td><td>Livelihood Restoration Plan</td></tr>
                                <tr><td>NCMF</td><td>Noise Compensation Management Framework</td></tr>
                                <tr><td>NTS</td><td>Non-Technical Summary</td></tr>
                                <tr><td>P</td><td>Policy</td></tr>
                                <tr><td>PAM</td><td>Project Administration Manual</td></tr>
                                <tr><td>PCN</td><td>Project Completion Note</td></tr>
                                <tr><td>PD</td><td>Project Document or Approval Project Document or APD</td></tr>
                                <tr><td>PIMR</td><td>Project Implementation Monitoring Report</td></tr>
                                <tr><td>PMR</td><td>Project Monitoring Report</td></tr>
                                <tr><td>PP</td><td>Procurement Plan or IPP</td></tr>
                                <tr><td>PSI</td><td>Project Summary or Project Summary Information or PSD or Project Summary Document</td></tr>
                                <tr><td>R</td><td>Research</td></tr>
                                <tr><td>RAP</td><td>Resettlement Action Plan</td></tr>
                                <tr><td>REOI</td><td>Request for Expressions of Interest</td></tr>
                                <tr><td>RFP</td><td>Request for Proposals</td></tr>
                                <tr><td>RIPP</td><td>Resettlement and Indigenous Peoples Plan</td></tr>
                                <tr><td>RPF</td><td>Resettlement Policy Framework or Resettlement Framework</td></tr>
                                <tr><td>SB</td><td>Supplemental Bulletin</td></tr>
                                <tr><td>SCA</td><td>Social Compliance Audit</td></tr>
                                <tr><td>SEP</td><td>Stakeholder Engagement Plan</td></tr>
                                <tr><td>SIA</td><td>Social Impact Assessment</td></tr>
                                <tr><td>SMR</td><td>Social Monitoring Report</td></tr>
                                <tr><td>SMRPF</td><td>Social Management and Resettlement Planning Framework</td></tr>
                                <tr><td>SPN</td><td>Special Procurement Notice</td></tr>
                                <tr><td>SS</td><td>Sector Strategy or Sector Strategy Project Assessment or SSPAR</td></tr>
                                <tr><td>TAR</td><td>Technical Assistance Report</td></tr>
                                <tr><td>TOR</td><td>Terms of Reference</td></tr>
                                <tr><td>TPPF</td><td>Tribal Population Planning Framework</td></tr>
                            </tbody>
                        </table>

                    </div>
               </div>
               <Footer />
            </div>
            
        )
    }
}

export default Faqs