import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleToast } from "../Actions/ToggleToast";
import { auth } from '../Authentication/firebase';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { setUserEmail } from '../Actions/userActions';
import API_Root from '../Constants/API_Root';

class Login extends Component {
    state = {
        email: '',
        password: '',
        step: 1,
        twoFACode: '',
        is2FAVerified: false,
    };

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleContinue = () => {
        // Check if email is entered, then proceed to next step
        if (this.state.email) {
            this.setState({ step: 2 });
        } else {
            // Show an error or toast notification to enter an email
            this.props.toggleToast({ show: true, type: "error", message: "ERROR: Please enter an email address." });
        }
    }
    handleLogin = () => {
        const { email, password } = this.state;
        auth.doSignInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                this.setState({ step: 2 });
                this.send2FACode(email);
            })
            .catch((error) => {
                console.error('Login Error:', error);
                this.props.toggleToast({ show: true, type: "error", message: "ERROR: Invalid login credentials." }); // Ensure you're showing error.message
            });
    }

    // New method for Google Sign-In
    handleGoogleSignIn = () => {
        auth.doSignInWithGoogle()
            .then((user) => {
                // After successful sign in with Google, send user information to backend
                fetch(API_Root + 'google-signin', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_email: user.email, 
                    }),
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('User created in database:', data);
                    localStorage.setItem('isLoggedIn', 'true');
                    this.props.history.push('/chat'); 
                })
                .catch((error) => {
                    console.error('Error creating user in database:', error);
                    this.props.toggleToast({ show: true, type: "error", message: "ERROR: Failed to create user in database." });
                });
            })
            .catch((error) => {
                console.error("Error during Google Sign In:", error);
                this.props.toggleToast({ show: true, type: "error", message: "ERROR: Failed to sign in with Google." });
            });
    }

    send2FACode = (email) => {
        fetch(API_Root + 'send-2fa-code', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
        .then(response => {
            if (response.ok) {
                console.log('2FA code sent successfully.');
                this.props.toggleToast({ show: true, type: "success", message: "SUCCESS: Sent 2FA code to " + email });
            } else {
                
                throw new Error('Failed to send 2FA code.');
            }
        })
        .catch(error => {
            console.error('Error sending 2FA code:', error);
            this.props.toggleToast({ show: true, type: "error", message: "ERROR: Failed to send 2FA code." });
        });
    };

    verify2FACode = () => {
        const { email, twoFACode } = this.state;
        // Call your backend endpoint to verify the 2FA code
        fetch(API_Root + 'verify-2fa-code', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, code: twoFACode }),
        })
        .then(response => response.json())
        .then(data => {
            console.log("Backend response:", data);
            if (data.success) {
                this.setState({ is2FAVerified: true });
                localStorage.setItem('isLoggedIn', 'true');
                this.props.history.push('/chat');
            } else {
                throw new Error('2FA code verification failed');
            }
        })
        .catch(error => {
            console.error('2FA Verification Error:', error);
            this.props.toggleToast({ show: true, type: "error", message: "ERROR: Failed to verify 2FA code." });
        });
    };

    render() {
        const { step, email, password, twoFACode } = this.state;
        return (
            <div className="login-page">
                <div className='login-back-button'>
                    <Link to="/" style={{ textDecoration: 'none', color: '#898797' }}>
                        <span className="back-arrow"></span>Back
                    </Link>
                </div>
                <div className="login-container">
                    {step === 1 && (
                        <>
                            <div className='elipse'>
                                <div className='login-logo' />
                            </div>
                            <h1>Welcome back</h1>
                            <p>Welcome back to myESRA</p>
                            <input
                                type="email" 
                                placeholder="Email or username" 
                                name="email" 
                                value={email} 
                                onChange={this.handleInputChange} 
                            />
                            <input 
                                type="password" 
                                placeholder="Password" 
                                name="password" 
                                value={password} 
                                onChange={this.handleInputChange} 
                            />
                            <div className='login-container-2'>
                                <Link to="/reset-password">Forgot password?</Link>
                            </div>
                            <button onClick={this.handleLogin}>Continue</button>
                            <div className="login-footer">
                                <span style={{ color: '#000000', fontWeight: 'normal' }}>Don't have an account? </span>
                                <Link to="/signup">Sign up</Link>
                            </div>
                            <br />
                            <span style={{ color: '#110E20', fontWeight: 'normal', fontSize: '12px' }}>OR</span>
                            <br />
                            <div className="alternative-login">
                                <button onClick={this.handleGoogleSignIn}>
                                    <div className='google-icon' />
                                    Continue with Google
                                </button>
                            </div>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <div className='elipse'>
                                <div className='login-logo' />
                            </div>
                            <h1>Two Factor Authentication</h1>
                            <p>Please enter the verification code sent to your email.</p>
                            <input 
                                type="text" 
                                placeholder="Verification Code" 
                                name="twoFACode" 
                                value={twoFACode} 
                                onChange={this.handleInputChange} 
                            />
                            <button onClick={this.verify2FACode}>Verify Code</button>
                            <div className="alternative-login">
                                <button onClick={() => this.send2FACode(email)}>
                                    Resend Code
                                </button>
                            </div>
                            <br />
                            <p>Please check your spam folder if you do not find our email in your inbox.</p>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    toggleToast: (options) => dispatch(toggleToast(options)),
});

export default withRouter(connect(null, mapDispatchToProps)(Login));
