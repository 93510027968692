import { auth } from './firebase';
import firebase from 'firebase/app';

//Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password)
        .then(userCredential => {
            // User created successfully, now send verification email
            userCredential.user.sendEmailVerification()
                .then(() => {
                    console.log("Verification email sent.");
                    // Email verification sent
                    // Additional logic or state updates can go here if needed
                })
                .catch(error => {
                    // Handle errors in sending the email verification here
                    console.error("Error sending verification email:", error);
                });
            return userCredential.user; // Return the user object for further use
        })
        .catch(error => {
            // Handle signup errors here (e.g., email already in use)
            console.error("Signup error:", error);
            throw error; // Re-throw the error if you need to handle it in the calling component
        });
};
//Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
    auth.signInWithEmailAndPassword(email, password);

//Sign Out
export const doSignOut = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    return auth.signOut();
}

export const doPasswordReset = (email) =>
    auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) =>
    auth.currentUser.updatePassword(password);

// Reset Password sent via email
export const confirmPasswordReset = (code, updatedPassword) =>
    auth.confirmPasswordReset(code, updatedPassword);

// Delete User
export const deleteUser = () =>
    auth.currentUser.delete()

// Get Current User
export const getCurrentUser = () => {
    return auth.currentUser;
};

export const userEmail = () => {
    return auth.getUserEmail;
};

export const doSignInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return auth.signInWithPopup(provider)
        .then((result) => {
            const token = result.credential.accessToken;
            const user = result.user;
        console.log(user);
        return user;
        }).catch((error) => {
        // Handle Errors here.
        console.error("Error during Google Sign In:", error);
        throw error;
        });
};
