import React, {Component} from 'react'
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';

class SubProcessorList extends Component {
    render() {
        return (
            <div className='faq-grid-layout'>
               <Navbar />
               <div className='top-spacer' />
               <div className='banner'>
                    <div className='banner-container'>
                        <h1>Sub-processor List</h1>
                    </div>
               </div>
               <div className='mainsection'>
                    <div className='content-container'>
                        <h1>Sub-processor List</h1>
                        <br />
                        <p>The Company uses the following sub-processors to assist in providing the Services. Depending upon the Services ordered as set out in the Agreement, the sub-processors include:</p>
                        <table border="1">
                        <tr>
                            <th><strong>Sub-processor</strong></th>
                            <th><strong>Purpose</strong></th>
                            <th><strong>Data</strong></th>
                            <th><strong>Sub-processor Location</strong></th>
                            <th><strong>Transfer Mechanism</strong></th>
                            <th><strong>Supplemental Measures</strong></th>
                            <th><strong>Contact Details</strong></th>
                        </tr>
                        <tr>
                            <td><strong>Amazon Web Services, Inc.</strong></td>
                            <td>cloud services provider and data storage</td>
                            <td>All application data except payment details and authentication passwords</td>
                            <td>USA</td>
                            <td><a href='https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt0000000TOWQAA4&status=Active' target='_blank'>DPF</a></td>
                            <td>Not required.<br /><br /><a href='https://aws.amazon.com/blogs/security/aws-gdpr-data-processing-addendum/' target='_blank'>DPA</a></td>
                            <td>dataprivacyframework@amazon.com<br /><br />2021 7TH Ave , Seattle, WA 98121, USA</td>
                        </tr>
                        <tr>
                            <td><strong>Google Analytics</strong></td>
                            <td>used to track user behaviour data</td>
                            <td>Clicks, navigation, site usage</td>
                            <td>USA</td>
                            <td><a href='https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt0000000TOWQAA4&status=Active' target='_blank'>DPF</a></td>
                            <td><a href='https://aws.amazon.com/blogs/security/aws-gdpr-data-processing-addendum/' target='_blank'>DPA</a></td>
                            <td><a href='https://support.google.com' target='_blank'>support.google.com</a><br /><br />Support chatbot<br /><br />1600 Amphitheatre Parkway in Mountain View, California</td>
                        </tr>
                        <tr>
                            <td><strong>Google Firebase</strong></td>
                            <td>used to store and manage user account data</td>
                            <td>First and last name, work email, company website, job title, city and country</td>
                            <td>USA</td>
                            <td><a href='https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt0000000TOWQAA4&status=Active' target='_blank'>DPF</a></td>
                            <td><a href='https://aws.amazon.com/blogs/security/aws-gdpr-data-processing-addendum/' target='_blank'>DPA</a></td>
                            <td><a href='https://support.google.com' target='_blank'>support.google.com</a><br /><br />Support chatbot<br /><br />1600 Amphitheatre Parkway in Mountain View, California</td>
                        </tr>
                        <tr>
                            <td><strong>Open AI OpCo LLC.</strong></td>
                            <td>Provides embedding and LLM technology</td>
                            <td>Name, contact information, demographic information, or other information provided by the user in unstructured data.</td>
                            <td>USA</td>
                            <td>SCCs</td>
                            <td>Encryption of data in transit and at rest.<br /><br /><a href='https://aws.amazon.com/blogs/security/aws-gdpr-data-processing-addendum/' target='_blank'>DPA</a></td>
                            <td>privacy@openai.com<br /><br />Head of Commercial Legal, 180 18th St., San Francisco, CA 94110, USA</td>
                        </tr>
                        <tr>
                            <td><strong>Pinecone Systems Inc.</strong></td>
                            <td>Vector database services</td>
                            <td>Product knowledge base (no data related to customer names, usage, payments etc.)</td>
                            <td>USA</td>
                            <td>SCCs</td>
                            <td><a href='https://aws.amazon.com/blogs/security/aws-gdpr-data-processing-addendum/' target='_blank'>DPA</a></td>
                            <td>legal@pinecone.io<br /><br />548 Market StPMB 19327San Francisco, CA 94104-5401, USA</td>
                        </tr>
                        <tr>
                            <td><strong>Stripe Inc</strong></td>
                            <td>Payment processing</td>
                            <td>First Name, Last Name, Email Address, Billing Address.</td>
                            <td>USA</td>
                            <td><a href='https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt0000000TOWQAA4&status=Active' target='_blank'>DPF</a></td>
                            <td>Not required.<br /><br /><a href='https://aws.amazon.com/blogs/security/aws-gdpr-data-processing-addendum/' target='_blank'>DPA</a></td>
                            <td>dpo@stripe.com<br /><br />354 Oyster Point Boulevard<br /><br />South San Francisco, California, 94080, USA</td>
                        </tr>
                        </table>

                    
                    </div>
               </div>
               <Footer />
            </div>
            
        )
    }
}

export default SubProcessorList