// BlogCarousel.js

import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

class BlogCarousel extends Component {
    render() {
        const { blogItems, openUrl } = this.props;
        const responsive = {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 3000 },
                items: 5
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1
            }
        };

        return (
            <div className='carousel-container'>
                
                <Carousel responsive={responsive}>
                    {blogItems.map((item, index) => (
                        <div className="carousel-content" key={index}>
                            <div className='carousal-img'>
                                {item.img_url && (
                                    <img src={item.img_url} alt={`Slide ${index + 1}`} />
                                )}
                            </div>
                            <div className='carousel-content-title'>{item.title}</div>
                            <div className='carousal-btn-container'>
                                <Button className='carousal-btn' variant="primary" onClick={() => openUrl(item.url)}>Read More</Button>
                            </div>
                        </div>
                    ))}
                </Carousel>
                
            </div>
        );
    }
}

export default BlogCarousel;
