import { SET_USER_EMAIL, CLEAR_USER_EMAIL } from './actionTypes';

export const setUserEmail = email => {
    return {
        type: SET_USER_EMAIL,
        payload: email,
    };
};

export const clearUserEmail = () => ({
    type: CLEAR_USER_EMAIL,
});