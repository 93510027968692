import React, { Component } from 'react';
import API_Root from '../Constants/API_Root';

class ChatSessionList extends Component {
  state = {
    chatSessions: [],
    isLoading: false,
    showModal: false,
    selectedSessionId: null,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    // console.log("chatsession: ", this.props.email)
    // if (this.props.email) {
    //     this.fetchChatSessions();
    // }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (this.props.email !== prevProps.email) {
        console.log('Email prop has been updated:', this.props.email);
        this.fetchChatSessions();
    }
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.showModal) {
      this.closeModal();
    }
  }

  handleSessionClick(chatSessionId) {
    fetch(API_Root + "get-conversations", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ chat_session_id: chatSessionId })
    })
    .then(response => response.json())
    .then(data => {
      this.props.onChatSessionSelected(data.chatHistory, chatSessionId);
    })
    .catch(error => console.error('Error fetching conversations:', error));
  }
  fetchChatSessions = () => {
    this.setState({ isLoading: true });
    const email = this.props.email; 
    
    fetch(API_Root + "get-chat-sessions", {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      const chatSessions = Array.isArray(data['chatSessions']) ? data['chatSessions'] : [];
      this.setState({
        chatSessions,
        isLoading: false
      });
    })
    .catch(error => {
      console.error('Error fetching chat sessions:', error);
      this.setState({ isLoading: false });
    });
  };

  // Function to trim the chat name to the first 3 words
  trimChatName = (chatName) => {
    const words = chatName.split(" ");
    return words.length > 5 ? `${words.slice(0, 5).join(" ")}...` : chatName;
  }

  // delete chat session/modal stuffs

  handleOptionsClick = (sessionId, e) => {
    e.stopPropagation();
    this.setState({ showModal: true, selectedSessionId: sessionId });
  };

  closeModal = () => {
    this.setState({ showModal: false, selectedSessionId: null });
  };

  deleteChatSession = () => {
    const { selectedSessionId } = this.state;
    fetch(API_Root + "delete-chat-session", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: selectedSessionId }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(() => {
      this.closeModal();
      this.fetchChatSessions(); // Refresh the chat sessions list
    })
    .catch(error => {
      console.error('Error deleting chat session:', error);
    });
  };

  handleExport = async (sessionName, sessionId) => {
    const analyticsData = {
      actionType: "export_chat_session",
      chatSessionId: sessionId,
      // You might not need conversationId for this action, 
      // but ensure to include any other relevant data needed by your backend
    };
  
    try {
      // Optionally, you can log the analytics before starting the export process
      await fetch(API_Root + "store-analytics-info", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(analyticsData),
      });
  
      const response = await fetch(API_Root + "export-chat-session", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ chat_session_id: sessionId, chat_session_name: sessionName }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `chat_session_${sessionId}.docx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
  
      console.log("Export action and analytics logging succeeded.");
    } catch (error) {
      console.error('Error exporting chat session:', error);
    }
  };

  render() {
    const { chatSessions, isLoading, showModal } = this.state;
    const { email } = this.props;
    

    if (!email) {
      return <div></div>;
    }

    if (isLoading) {
      return <div>...</div>;
    }

    return (
      <>
        {chatSessions.length > 0 ? (
        chatSessions.map((session) => (
          <div key={session.id} className='chatsession-item'>
            <button 
              className='chatsession-button' 
              onClick={() => this.handleSessionClick(session.id)}
            >
              <div className="chatsession-icon" />
              <span className="chatsession-name">{session.chat_name}</span>
              <button 
                className='chatsession-options-button'
                onClick={(e) => {
                  e.stopPropagation(); 
                  this.handleOptionsClick(session.id, e); 
                }}
              >
                <div className='dots-icon' /> 
              </button>
            </button>
            {this.state.selectedSessionId === session.id && (
              <div ref={this.setWrapperRef} className="delete-modal">
                <button className='delete-modal-button' onClick={this.deleteChatSession}>Delete</button>
                <button className='delete-modal-button' 
                        onClick={(e) => {
                          e.stopPropagation(); 
                          this.handleExport(session.chat_name, session.id); 
                        }}
                >
                  Export
                </button>
              </div>
            )}
          </div>
        ))
      ) : (
        <div>No chat sessions available.</div>
      )}
        
      </>
    );
  }
}

export default ChatSessionList;