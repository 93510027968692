export function generateUniqueSessionId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const sessionIdLength = 16;
    let sessionId = '';
  
    for (let i = 0; i < sessionIdLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      sessionId += characters.charAt(randomIndex);
    }
  
    return sessionId;
  }