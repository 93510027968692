import React, {Component} from 'react'
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';

class ScrapePortal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prefix: "",
            keywords: "",
        };
    }
    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleDeleteSubmit = () => {
        fetch('http://almasi-scraping-env.eba-pygu2i4n.us-east-1.elasticbeanstalk.com/delete-files-from-s3', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                prefix: this.state.prefix,
                keywords: this.state.keywords,
             }),
        })
        .then(response => {
            if (response.ok) {
                console.log('Operation successfull.');
            } else {
                throw new Error('Operation unsuccessfull.');
            }
        })
        .catch(error => {
            console.error(error);
        });
    };
    handleInsertSubmit = () => {
        console.log("Insert started...")
        fetch('http://almasi-scraping-env.eba-pygu2i4n.us-east-1.elasticbeanstalk.com/upload-to-pinecone', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                prefix: this.state.prefix,
                keywords: this.state.keywords,
             }),
        })
        .then(response => {
            if (response.ok) {
                console.log('Operation successfull.');
            } else {
                throw new Error('Operation unsuccessfull.');
            }
        })
        .catch(error => {
            console.error(error);
        });
    };
    render() {
        const { prefix, keywords } = this.state;
        return ( 
            <>
                <div>
                    <p>Delete</p>
                    <input
                        type="prefix" 
                        placeholder="Prefix" 
                        name="prefix" 
                        value={prefix} 
                        onChange={this.handleInputChange} 
                    />
                    <input
                        type="keywords" 
                        placeholder="Keywords" 
                        name="keywords" 
                        value={keywords} 
                        onChange={this.handleInputChange} 
                    />
                    <button onClick={this.handleDeleteSubmit}>
                        Submit
                    </button>
                </div>
                <div>
                    <br />
                    <p>Insert to Pinecone</p>
                    <input
                        type="prefix" 
                        placeholder="Prefix" 
                        name="prefix" 
                        value={prefix} 
                        onChange={this.handleInputChange} 
                    />
                    <input
                        type="keywords" 
                        placeholder="Keywords" 
                        name="keywords" 
                        value={keywords} 
                        onChange={this.handleInputChange} 
                    />
                    <button onClick={this.handleInsertSubmit}>
                        Submit
                    </button>
                </div>
            </>
        )
    }
}

export default ScrapePortal