import React, {Component} from 'react'
import Navbar from '../App/Navbar';
import Footer from '../App/Footer';
import API_Root from '../Constants/API_Root';
import { Link } from 'react-router-dom';
import { auth } from '../Authentication/firebase/firebase';

class Pricing extends Component {
    state = {
        userEmail: null,
        subscriptionType: '',
        paymentMode: '',
    };

    componentDidMount() {
        auth.onAuthStateChanged(user => {
            if (user) {
              this.setState({
                userEmail: user.email,
              });
            } else {
              this.setState({
                userEmail: null,
              });
            }
        });
        
    }
    componentDidUpdate() {
        console.log(this.state.userEmail)
    }
    handleCheckoutClick = async (subscriptionType, paymentMode) => {
        if (this.state.userEmail === null) {
            // Redirect to the /login page
            window.location.href = '/login';
            return; // Stop the execution of this function
        }
        this.setState({ subscriptionType, paymentMode });
        try {
            // Show some loading indicator if you like
            const response = await fetch(API_Root + "create-checkout-session", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                // Include any necessary data in the body, if your backend requires it
                body: JSON.stringify({
                    email: this.state.userEmail,
                    subscriptionType: subscriptionType,
                    paymentMode: paymentMode,
                }),
            });
            const data = await response.json();
    
            if (response.ok) {
                // Redirect to Stripe checkout URL
                window.location.href = data.url;
            } else {
                // Handle errors, e.g., show a message to the user
                console.error('Failed to create checkout session:', data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle errors, e.g., show a message to the user
        }
    }
    
    render() {
        return (
            <div className='faq-grid-layout'>
               <Navbar />
               <div className='top-spacer' />
               <div className='banner'>
                    <div className='banner-container'>
                        <h1>Reclaim your time with myESRA™</h1>
                        <p></p>
                    </div>
               </div>
               <div className='mainsection'>
                    
                    <div className='pricing-container'>
                        <div className='pricing-item'>
                            <div>
                                <h1>Day Pass</h1>
                                <p>Experiment with myESRA using the day pass,
                                it's a perfect way to get acquainted with myESRA.</p>
                                
                            </div>
                            <p><strong>The day pass gives you:</strong></p>
                            <div className='pricing-features'>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <g filter="url(#filter0_d_336_430)">
                                            <rect x="2" width="24" height="24" rx="12" fill="#F2F6F5"/>
                                            <rect x="2.5" y="0.5" width="23" height="23" rx="11.5" stroke="url(#paint0_linear_336_430)" stroke-opacity="0.06"/>
                                        </g>
                                        <path d="M10 12.3333L12.4615 15L18 9" stroke="#3E826D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <defs>
                                            <filter id="filter0_d_336_430" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                            <feOffset dy="2"/>
                                            <feGaussianBlur stdDeviation="1"/>
                                            <feComposite in2="hardAlpha" operator="out"/>
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_336_430"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_336_430" result="shape"/>
                                            </filter>
                                            <linearGradient id="paint0_linear_336_430" x1="14" y1="0" x2="14" y2="24" gradientUnits="userSpaceOnUse">
                                            <stop offset="0.01" stop-opacity="0"/>
                                            <stop offset="1"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <h1>24-hour access to the chatbot from the time of payment</h1>
                                
                            </div>
                            <div className='pricing-features'>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <g filter="url(#filter0_d_336_430)">
                                            <rect x="2" width="24" height="24" rx="12" fill="#F2F6F5"/>
                                            <rect x="2.5" y="0.5" width="23" height="23" rx="11.5" stroke="url(#paint0_linear_336_430)" stroke-opacity="0.06"/>
                                        </g>
                                        <path d="M10 12.3333L12.4615 15L18 9" stroke="#3E826D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <defs>
                                            <filter id="filter0_d_336_430" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                            <feOffset dy="2"/>
                                            <feGaussianBlur stdDeviation="1"/>
                                            <feComposite in2="hardAlpha" operator="out"/>
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_336_430"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_336_430" result="shape"/>
                                            </filter>
                                            <linearGradient id="paint0_linear_336_430" x1="14" y1="0" x2="14" y2="24" gradientUnits="userSpaceOnUse">
                                            <stop offset="0.01" stop-opacity="0"/>
                                            <stop offset="1"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <h1>Unlimited questions (subject to T&C)</h1>
                                
                            </div>
                            <div className='pricing-features'>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <g filter="url(#filter0_d_336_430)">
                                            <rect x="2" width="24" height="24" rx="12" fill="#F2F6F5"/>
                                            <rect x="2.5" y="0.5" width="23" height="23" rx="11.5" stroke="url(#paint0_linear_336_430)" stroke-opacity="0.06"/>
                                        </g>
                                        <path d="M10 12.3333L12.4615 15L18 9" stroke="#3E826D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <defs>
                                            <filter id="filter0_d_336_430" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                            <feOffset dy="2"/>
                                            <feGaussianBlur stdDeviation="1"/>
                                            <feComposite in2="hardAlpha" operator="out"/>
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_336_430"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_336_430" result="shape"/>
                                            </filter>
                                            <linearGradient id="paint0_linear_336_430" x1="14" y1="0" x2="14" y2="24" gradientUnits="userSpaceOnUse">
                                            <stop offset="0.01" stop-opacity="0"/>
                                            <stop offset="1"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <h1>Full access to the industry’s most comprehensive knowledge base</h1>
                                
                            </div>

                            <div className='pricing-details'>
                                <div className='pricing-price'>
                                    <p>Daily</p>
                                    <h1>€15</h1>
                                </div>
                                
                                <button onClick={() => this.handleCheckoutClick('Daily', 'payment')}>
                                    <span>One-time Checkout</span>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M9 6L15 12L9 18" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className='pricing-item'>
                            <div>
                                <h1>Monthly</h1>
                                <p>Reclaim your valuable time by incorporating myESRA into your day-to-day workflow.</p>
                                
                            </div>
                            <p><strong>The monthly pass gives you:</strong></p>
                            <div className='pricing-features'>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <g filter="url(#filter0_d_336_430)">
                                            <rect x="2" width="24" height="24" rx="12" fill="#F2F6F5"/>
                                            <rect x="2.5" y="0.5" width="23" height="23" rx="11.5" stroke="url(#paint0_linear_336_430)" stroke-opacity="0.06"/>
                                        </g>
                                        <path d="M10 12.3333L12.4615 15L18 9" stroke="#3E826D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <defs>
                                            <filter id="filter0_d_336_430" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                            <feOffset dy="2"/>
                                            <feGaussianBlur stdDeviation="1"/>
                                            <feComposite in2="hardAlpha" operator="out"/>
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_336_430"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_336_430" result="shape"/>
                                            </filter>
                                            <linearGradient id="paint0_linear_336_430" x1="14" y1="0" x2="14" y2="24" gradientUnits="userSpaceOnUse">
                                            <stop offset="0.01" stop-opacity="0"/>
                                            <stop offset="1"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <h1>30 days access to the chatbot from the time of payment</h1>
                                
                            </div>
                            <div className='pricing-features'>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <g filter="url(#filter0_d_336_430)">
                                            <rect x="2" width="24" height="24" rx="12" fill="#F2F6F5"/>
                                            <rect x="2.5" y="0.5" width="23" height="23" rx="11.5" stroke="url(#paint0_linear_336_430)" stroke-opacity="0.06"/>
                                        </g>
                                        <path d="M10 12.3333L12.4615 15L18 9" stroke="#3E826D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <defs>
                                            <filter id="filter0_d_336_430" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                            <feOffset dy="2"/>
                                            <feGaussianBlur stdDeviation="1"/>
                                            <feComposite in2="hardAlpha" operator="out"/>
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_336_430"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_336_430" result="shape"/>
                                            </filter>
                                            <linearGradient id="paint0_linear_336_430" x1="14" y1="0" x2="14" y2="24" gradientUnits="userSpaceOnUse">
                                            <stop offset="0.01" stop-opacity="0"/>
                                            <stop offset="1"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <h1>Unlimited questions (subject to T&C)</h1>
                                
                            </div>
                            <div className='pricing-features'>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <g filter="url(#filter0_d_336_430)">
                                            <rect x="2" width="24" height="24" rx="12" fill="#F2F6F5"/>
                                            <rect x="2.5" y="0.5" width="23" height="23" rx="11.5" stroke="url(#paint0_linear_336_430)" stroke-opacity="0.06"/>
                                        </g>
                                        <path d="M10 12.3333L12.4615 15L18 9" stroke="#3E826D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <defs>
                                            <filter id="filter0_d_336_430" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                            <feOffset dy="2"/>
                                            <feGaussianBlur stdDeviation="1"/>
                                            <feComposite in2="hardAlpha" operator="out"/>
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_336_430"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_336_430" result="shape"/>
                                            </filter>
                                            <linearGradient id="paint0_linear_336_430" x1="14" y1="0" x2="14" y2="24" gradientUnits="userSpaceOnUse">
                                            <stop offset="0.01" stop-opacity="0"/>
                                            <stop offset="1"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <h1>Full access to the industry’s most comprehensive knowledge base</h1>
                                
                            </div>
                            <div className='pricing-features'>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <g filter="url(#filter0_d_336_430)">
                                            <rect x="2" width="24" height="24" rx="12" fill="#F2F6F5"/>
                                            <rect x="2.5" y="0.5" width="23" height="23" rx="11.5" stroke="url(#paint0_linear_336_430)" stroke-opacity="0.06"/>
                                        </g>
                                        <path d="M10 12.3333L12.4615 15L18 9" stroke="#3E826D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <defs>
                                            <filter id="filter0_d_336_430" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                            <feOffset dy="2"/>
                                            <feGaussianBlur stdDeviation="1"/>
                                            <feComposite in2="hardAlpha" operator="out"/>
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_336_430"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_336_430" result="shape"/>
                                            </filter>
                                            <linearGradient id="paint0_linear_336_430" x1="14" y1="0" x2="14" y2="24" gradientUnits="userSpaceOnUse">
                                            <stop offset="0.01" stop-opacity="0"/>
                                            <stop offset="1"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <h1>Priority for troubleshooting</h1>
                                
                            </div>
                            <div className='pricing-details-monthly'>
                                <div className='pricing-price'>
                                    <p>Annual(12 Months)</p>
                                    <h1>€120/Month</h1>
                                </div>
                                <button onClick={() => this.handleCheckoutClick('Annual', 'subscription')}>
                                    <span>Recurring Checkout (Annual)</span>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M9 6L15 12L9 18" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </button>
                                <div className='pricing-price'>
                                    <p>Monthly</p>
                                    <h1>€150/Month</h1>
                                </div>
                                <button onClick={() => this.handleCheckoutClick('Monthly', 'subscription')}>
                                    <span>Recurring Checkout (Monthly)</span>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M9 6L15 12L9 18" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className='pricing-item-enterprise'>
                            <div>
                                <h1>Enterprise</h1>
                                <p>For corporate clients with teams of over 10 users. Contact us to discuss how we can help you.</p>
                            </div>
                            
                                
                            
                            <div className='pricing-details-enterprise'>
                            <Link to="/contact-us">
                                    Contact Us
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M9 6L15 12L9 18" stroke="#3E826D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                            </Link>
                            </div>
                        </div>
                    </div>
               </div>
               <Footer />
            </div>
        )
    }
}

export default Pricing