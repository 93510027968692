import React, { useState } from 'react';
import { marked } from 'marked';
import API_Root from '../src/Constants/API_Root';

const extractFilename = (source) => {
  if (source.startsWith('s3://')) {
    const parts = source.split('/');
    return parts[parts.length - 1]; // Return the last part of the path
  }
  return source; // If not an S3 path, return the source as is
};

const aggregateUniqueFiles = (fileNames) => {
  const uniqueFiles = new Map();

  if (Array.isArray(fileNames)) {
    fileNames.forEach(file => {
      // Check if file.source is null or undefined, and default to a blank string if so
      const source = file && file.source ? file.source : '';
      const cleanedSource = extractFilename(source);
      const existingFile = uniqueFiles.get(cleanedSource);

      if (existingFile) {
        if (!existingFile.contents.includes(file.content)) {
          existingFile.contents.push(file.content);
        }
      } else {
        uniqueFiles.set(cleanedSource, { source: cleanedSource, contents: [file.content], originalSource: source });
      }
    });
  }

  return Array.from(uniqueFiles.values());
};

const FeedbackIcons = ({ onSendFeedback, conversationId, prompt, answer, onRefreshChat, handleCopyResponse }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastFeedbackType, setLastFeedbackType] = useState(null);
  const [comment, setComment] = useState("");

  const toggleModal = (type) => {
    if (modalVisible && type === lastFeedbackType) {
      // Hide the modal if it's already visible and the same button is clicked again
      setModalVisible(false);
    } else {
      // Otherwise, show the modal and update the feedback type
      setModalVisible(true);
      setLastFeedbackType(type);
    }
  };

  const handleRefresh = () => {
    onRefreshChat(prompt, conversationId);
  };

  const handleCopy = () => {
    handleCopyResponse(answer, conversationId);
  };

  const sendFeedback = () => {
    onSendFeedback(conversationId, lastFeedbackType, comment);
    setModalVisible(false); 
    setComment(""); 
  };

  return (
    <div style={{ position: 'relative' }}>
      {modalVisible && (
        <div className='feedback-modal-container'>
          <div className="feedback-modal">
            <textarea
              placeholder="Add any comments here..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <button onClick={sendFeedback}>Send Feedback</button>
          </div>
        </div>
      )}
      <div className='feedback-icon-container'>
        <div className='feedback-icon-flex'>
          <button className='copy-icon' onClick={handleCopy}></button>
          <button className='refresh-icon' onClick={handleRefresh}></button>
          <button className='thumbs-up' onClick={() => toggleModal('positive')}></button>
          <button className='thumbs-down' onClick={() => toggleModal('negative')}></button>
        </div>
      </div>
      
    </div>
  );
};

const SourcesList = ({ uniqueFiles }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const displayedFiles = isExpanded ? uniqueFiles : uniqueFiles.slice(0, 3);

  const cleanContent = (content) => {
    return content.replace(/\.{10,}/g, ''); // Replace sequences of 10 or more periods with an empty string
  };

  const handleFileClick = async (e, file) => {
    e.preventDefault(); // Prevent the default link behavior

    // Check if file.source is a URL
    if (file.originalSource.startsWith('http://') || file.originalSource.startsWith('https://')) {
      window.open(file.originalSource, '_blank');
      return;
    }

    try {
      // Prepare the request options for a POST request
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ objectKey: file.originalSource })
      };
      const response = await fetch(API_Root + 'generate-presigned-urls', requestOptions);
      const data = await response.json();
      if (data.url) {
        window.open(data.url, '_blank');
      } else {
        console.error('Failed to get pre-signed URL');
      }
    } catch (error) {
      console.error('Error fetching pre-signed URL:', error);
    }
  };

  return (
    <div className='chat-primaryfile'>
      <strong>Sources: </strong>
      <ul>
        {displayedFiles.map((file, fileIndex) => (
          <li key={fileIndex} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <div className='source-icon' />
            <a onClick={(e) => handleFileClick(e, file)} href="#" rel="noopener noreferrer" style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>{file.source}</a>
            <span className='text-tooltip'>{cleanContent(file.contents.join(' | '))}</span>
          </li>
        ))}
      </ul>
      {uniqueFiles.length > 3 && (
        <button className='expand-button' onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? 'Show less' : 'Show more sources'}
        </button>
      )}
    </div>
  );
};

const QuestionAnswer = ({ chatHistory, onSendFeedback, onRefreshChat, handleCopyResponse }) => {
  console.log(chatHistory);
  const [toggleState, setToggleState] = useState({});

  const cleanText = (text) => {
    if (typeof text !== 'string') return '';
    // Regex to match any text within 【】 and the brackets themselves
    const regex = /【.*?】/g;
    return text.replace(regex, '');
  };
  

  const parseMarkdown = (markdownText) => {
    return marked(markdownText); // Convert Markdown to HTML
  };

  const toggleAnswer = (index) => {
    setToggleState(prevState => ({ ...prevState, [index]: !prevState[index] }));
  };

  return (
    <>
      {chatHistory.slice().reverse().map((chat, index) => {
        const fileNamesKey = toggleState[index] && chat.file_names_2 ? 'file_names_2' : 'file_names';
        const uniqueFiles = aggregateUniqueFiles(chat[fileNamesKey] || []);
        // Clean the chat answers and prompts before converting them to HTML
        const cleanedAnswer = cleanText(toggleState[index] ? chat.answer_2 : chat.answer);
        const cleanedPrompt = cleanText(chat.prompt);

        const answerHtml = parseMarkdown(cleanedAnswer);
        const promptHtml = parseMarkdown(cleanedPrompt);

        return (
          <React.Fragment key={index}>
            <FeedbackIcons conversationId={chat.conversation_id} onSendFeedback={onSendFeedback} prompt={chat.prompt} answer={cleanedAnswer} onRefreshChat={onRefreshChat} handleCopyResponse={handleCopyResponse} />
            
            <div className="chat-assistant-container">
              <div className='profile-assistant-icon' />
              <div className="assistant-chatbox">
                <div className='chat-text' dangerouslySetInnerHTML={{ __html: answerHtml }}></div>
                {uniqueFiles.length > 0 && <SourcesList uniqueFiles={uniqueFiles} />}
                {chat.answer_2 && <button className='switch-answer-button' onClick={() => toggleAnswer(index)}>{toggleState[index] ? '< 2/2' : '1/2 >'}</button>}
              </div>
            </div>
            <div className="chat-user-container">
              
              <div className="user-chatbox">
                <div className='user-text' dangerouslySetInnerHTML={{ __html: promptHtml }}></div>
              </div>
              <div className='profile-user-icon' />
            </div> 
          </React.Fragment>
        );
      })}
    </>
  );
};

export default QuestionAnswer;