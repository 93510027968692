import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from '../Constants/API_Root';
import QuestionAnswer from '../QuestionAnswer';
import {toggleToast} from "../Actions/ToggleToast";
import { ThreeDots } from "react-loader-spinner";
import { generateUniqueSessionId } from '../UniqueId';
import Login from '../App/Login';
import Logout from '../App/Logout';
import ChatSessionList from '../App/ChatSessionList';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { auth } from '../Authentication/firebase/firebase';
import { Link } from 'react-router-dom';

const hardcodedQA = {
    "What is in the knowledge base?": `My knowledge base consists of thousands of documents making it the world’s most comprehensive collection of:
<ul>
    <li>IFI, DFI, EP Banks and Export Credit Agency project documents</li>
    <li>Policies and guidance notes</li>
    <li>National statistics</li>
    <li>Academic and practitioner research documents</li>
    <li>Research</li>
    <li>E&S legislation</li>
    <li>Project administration manuals, procurement plans and loan agreements</li>
</ul>
The project documents category includes all impact assessments, appendices and plans that are disclosed and publicly available for the project. Where the original language of the document is not English, we translated it before adding it to the knowledge base.

Our knowledge base is updated on a regular basis with the latest disclosed information on projects, statistics, policies and more!

We welcome your feedback on what you’d like to see added to the knowledge base, but now go ahead and check to see what myESRA knows about your projects!`,

    "What are the main gaps between the expropriation legislation in North Macedonia and EBRDs Performance Requirement 5?": `<h1>Gaps Analysis between Macedonian Legal Framework & EBRD Requirements</h1>
<p>The key gaps between the national legislation of North Macedonia and EBRD’s PR5 relevant for this Project are summarised below. This RF (including the entitlements matrix) contains measures to bridge these gaps:</p>
<ul>
    <li><strong>Informal Land Rights:</strong> Macedonian law focuses on compensation for formal land rights, whereas EBRD also covers compensation, livelihood restoration or other assistance, assistance for resettlement, and consultation for persons with no formal recognisable legal right or claim to land they occupy.</li>
    <li><strong>Participation and Consultation:</strong> EBRD PR5 sets greater requirements than Macedonian law for giving affected persons the opportunity to participate in the negotiation of the compensation packages, eligibility requirements, livelihood restoration and resettlement assistance.</li>
    <li><strong>Socio-Economic Surveys:</strong> Conducting socio-economic surveys to assess impacts on livelihoods is required by EBRD, but not required by Macedonian law.</li>
    <li><strong>Vulnerable Individuals & Groups:</strong> Macedonian law does not stipulate special measures relating to vulnerable groups. EBRD, however, requires particular attention to be paid to vulnerable groups, including during consultation, identification of entitlements and appropriate resettlement assistance.</li>
    <li><strong>Resettlement Frameworks & Plans:</strong> Macedonian law does not require the preparation of resettlement frameworks/plans. These are required for EBRD financed projects involving land acquisition and resettlement resulting in physical or economic displacement.</li>
    <li><strong>Valuation of Expropriated Assets:</strong> EBRD states that compensation for loss of assets is calculated at full replacement cost, i.e., market value of the assets plus transaction costs, and that depreciation of structures and assets should not be taken into account. However, when it comes to a facility which because of its nature is not in circulation and for which a market value is not determined (wells, fence and retaining walls and similar), Macedonian law states that compensation is established according to the market value of the material and the labour necessary for construction of facilities, decreased by the relevant amortization amount.</li>
    <li><strong>Grievance Mechanisms:</strong> Although Macedonian law establishes rights of affected citizens (those with formal land rights) to appeal to Courts during the expropriation process, it does not require development of specific grievance mechanisms. EBRD, however, requires grievance mechanisms to be set up as early as possible in the process, to receive and address in a timely fashion specific concerns about compensation and relocation that are raised by displaced persons, including a recourse mechanism designed to resolve disputes in an impartial manner. A summary of complaints and the measures taken to resolve them should be made public on a regular basis.</li>
    <li><strong>Access to Land/Property Prior to Payment of Compensation:</strong> There are several options for the Proposer of Expropriation to acquire the right of accessing land/property prior to payment of compensation, under the Expropriation Law, Law on Assessment and the Law on Construction.</li>
    <li><strong>Monitoring & Evaluation:</strong> There are no requirements for monitoring and evaluation of the resettlement and the livelihood restoration process and outcomes under Macedonian legislation.</li>
</ul>`,

    "List the assessment criteria to identify indigenous peoples according to ADB.": `<h1>According to the ADB, the characteristics that make someone an indigenous person are:</h1>
<ul>
    <li>(i) Self-identification as members of a distinct indigenous cultural group and recognition of this community by others</li>
    <li>(ii) Collective attachment to geographically distinct habitats or ancestral territories in the project area and to the natural resources in these habitats</li>
    <li>(iii) Customary cultural, economic, social, or political institutions that are separate from those of the dominant society and culture</li>
    <li>(iv) A distinct language, often different from the official language of the country or region</li>
</ul>`,

    "What is the total cost of the Masrik Solar project's LRP including the line items?": `<h2>Number/Value:</h2>
<p>The total LRP implementation budget for the Masrik Solar project is AMD 252,639,458.72 or US$ 517,703.81.</p>
<p>This includes the compensation budget, livelihood restoration measures budget, community development measures (CDM) budget, and budget for consultancy services. Here is a breakdown of the line items and costs:</p>
<ul>
    <li><strong>Compensation Budget:</strong>
        <ul>
            <li>Land acquisition (tower + interconnection equipment): AMD 348,942.43 (US$ 715.05)</li>
            <li>Land acquisition (Additional land for PV plant 32.7 ha): AMD 47,029,104.00 (US$ 96,371.11)</li>
            <li>Land Compensation for Servitude (Total for 20 years): AMD 75,839,000.43 (US$ 155,407.79)</li>
            <li>Land Compensation for Privately Rented Lands: AMD 1,006,761.29 (US$ 2,063.04)</li>
            <li>Crops: AMD 68,427.05 (US$ 140.22)</li>
            <li>Sub-Total (Compensation Budget): AMD 124,292,235.20 (US$ 254,697.20)</li>
        </ul>
    </li>
</ul>
<ul>
    <li><strong>Livelihood Restoration Measures Budget:</strong>
        <ul>
            <li>Annual Allowance for Rented Lands (Total for 20 years): AMD 6,600,000.00 (US$ 13,524.59)</li>
            <li>Registration Cost: AMD 2,259,000.00 (US$ 4,629.10)</li>
            <li>Vulnerability Allowance: AMD 330,000.00 (US$ 676.23)</li>
            <li>Sub-Total (Livelihood Restoration Measures Budget): AMD 9,189,000.00 (US$ 18,829.92)</li>
        </ul>
    </li>
</ul>
<ul>
    <li><strong>Community Development Measures (CDM) Budget:</strong>
        <ul>
            <li>Compensation for land donation (97.4ha): AMD 80,000,000.00 (US$ 163,934.43)</li>
            <li>Donation of an Excavator: AMD 13,311,000.00 (US$ 27,276.64)</li>
            <li>Sub-Total (CDM Budget): AMD 93,311,000.00 (US$ 191,211.07)</li>
        </ul>
    </li>
</ul>
<ul>
    <li><strong>Budget for Consultancy Services: AMD 2,880,000.00</strong></li>
</ul>
<br />
<p>The budget summary also includes line items with annual payments:</p>
<ul>
    <li>Servitude Payment: AMD 75,839,000.43 (US$ 155,407.79 for 20 years)</li>
    <li>Annual Allowance for Rented Lands: AMD 6,600,000.00 (US$ 13,524.59 for 20 years)</li>
    <li>Total for these annual payments: AMD 82,439,000.43 (US$ 168,932.38 for 20 years).</li>
</ul>

<p>The exchange rate used is 1 USD = 488 AMD as of March 27, 2019.</p>
<p>More information:</p>
<ul>
    <li>These details were obtained from the "LIVELIHOOD RESTORATION PLAN - FRV MASRIK CJSC" report, specifically from the sections on different budget summaries and tables related to the various compensation and development measures associated with the project.</li>
</ul>
`
};



class AppIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prompt: "",
            conversationId: 0,
            currentChatSessionId: null,
            chatHistory: [],
            fetching: false,
            abortController: null,
            error_msg: "",
            userEmail: "",
            subscription: null,
            showSubscriptionAlert: false,
            subscriptionActive: false,
            ip_address: "",
            allowedEmails: [],
            initVectorDBMessages: [],
            initVectorDBDoneMessage: "",
            sessionId: '',
            pocAccess: false,
            isSidebarVisible: true,
            isQuestionTypeVisible: false,
            showLoginModal: false,
            isLoggedIn: false,
            firstName: 'None',
            lastName: 'None',
            shouldRedirectToProfile: false,
            selectedModel: 'gpt-4o-mini',
            pineconeIndex: 'new-index',
            promptType: "",
            questionType: "general",
            systemPrompt: `You are a helpful assistant. When asked a question, use the sources given to answer to the best of your ability. Try to get your answer from the provided files.`,
            
        };
        this.textareaRef = React.createRef();
    }
    componentDidMount() {
        fetch("https://get.geojs.io/v1/ip/geo.json")
            .then(res => res.json())
            .then(data => {
                this.setState({ ip_address: data.ip });
            })
            .catch(error => {
                console.error('Error fetching IP information:', error);
            });
        // Check if sessionId already exists in localStorage
        let sessionId = localStorage.getItem('sessionId');

        // If sessionId doesn't exist, generate a new one and store it in localStorage
        sessionId = generateUniqueSessionId();
        localStorage.setItem('sessionId', sessionId);

        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        if (!isLoggedIn) {
            this.props.history.push('/login');
        }
        this.setState({ isLoggedIn });

        // Set the sessionId in the component state
        this.setState({
          sessionId: sessionId,
        });

        auth.onAuthStateChanged(user => {
            if (user) {
              this.setState({
                isLoggedIn: true,
                userEmail: user.email,
              });
              this.fetchProfileInformation(this.state.userEmail)
            } else {
              this.setState({
                isLoggedIn: false,
                userEmail: null,
              });
            }
        });
        
        if (this.props.showSidebarOnLoad) {
            this.setState({ isSidebarVisible: true });
        }
        if (this.props.chatgptPromptType) {
            this.setState({ promptType: "chatgpt" });
        }
        if (this.props.categoryPromptType) {
            this.setState({ promptType: "category" });
        }
        if (this.props.individualPromptType) {
            this.setState({ promptType: "individual" });
            this.setState({ isQuestionTypeVisible: true });
        }
        this.fetchTesterList();
    }
    handleSuccessfulLogin = (_userEmail) => {
        this.setState({ userEmail: _userEmail });
        this.setState({ isLoggedIn: true });
    };
    handleLogout = () => {
        this.setState({
            userEmail: null,
            firstName: 'None',
            lastName: 'None',
            chatHistory: [],
            isLoggedIn: false
        })
    };
    toggleLoginModal = () => {
        this.setState(prevState => ({
            showLoginModal: !prevState.showLoginModal
        }));
    }
    handleModelChange = (event) => {
        this.setState({ selectedModel: event.target.value });
    };
    fetchTesterList = () => {
        fetch(API_Root + '/get-tester-list')
        .then(response => response.json())
        .then(data => {
            if (data && data.testers) {
                // Extract emails from the tester list
                const allowedEmails = data.testers.map(tester => tester.email);
                this.setState({ allowedEmails });
            }
        })
        .catch(error => {
            console.error("Error fetching tester list:", error);
        });
    };
    initVectorDB = () => {
        fetch(API_Root + 'init-vectordb', {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json' // Set content type to JSON
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            // Handle the success response if needed
            console.log('init-vectordb response:', data);
        })
        .catch(error => {
            // Handle the error if needed
            console.error('Error in initVectorDB:', error);
        });
    };
    fetchProfileInformation = (email) => {
        fetch(API_Root + 'get-profile-information', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                ip_address: this.state.ip_address,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            this.setState({
                firstName: data.first_name || 'None',
                lastName: data.last_name || 'None',
                subscription: data.subscription,
                pocAccess: data.access || false
            }, () => {
                const { firstName, lastName, subscription } = this.state;
                if (firstName === 'None' && lastName === 'None') {
                    this.setState({ shouldRedirectToProfile: true });
                }
                if (subscription === null) {
                    this.toggleSubscriptionAlert(true);
                }
    
                let isActive = false;
                if (subscription) {
                    const now = new Date();
                    const planStartDate = new Date(subscription.planDetails.planStartDate);
                    
                    // Log dates for debugging
                    console.log('Current Date:', now);
                    console.log('Plan Start Date:', planStartDate);
                    
                    // Check if now is greater than planStartDate, isActive if now < planEndDate
                    if (now >= planStartDate) {
                        if (subscription.planType === "Date") {
                            const planEndDate = new Date(subscription.planDetails.planEndDate);
                            console.log('Plan End Date:', planEndDate);
                            isActive = now <= planEndDate;
                        } else {
                            const timeDiff = now.getTime() - planStartDate.getTime();
                            console.log('Time Difference (ms):', timeDiff);

                            // Perform checks for Daily, Monthly, and Annual plans
                            if (subscription.planType === "Daily") {
                                isActive = timeDiff <= 24 * 60 * 60 * 1000;  // 1 day
                            } else if (subscription.planType === "Monthly") {
                                isActive = timeDiff <= 30 * 24 * 60 * 60 * 1000;  // 30 days
                            } else if (subscription.planType === "Annual") {
                                isActive = timeDiff <= 365 * 24 * 60 * 60 * 1000;  // 365 days
                            }
                        }
                    }
                }

                // Log the final isActive status for debugging
                console.log('Is Active:', isActive);
    
                this.setState({ subscriptionActive: isActive }, () => {
                    if (!this.state.subscriptionActive) {
                        this.toggleSubscriptionAlert(true);
                        this.removeSubscription();
                    }
                });
            });
        })
        .catch(error => {
            console.error('Error fetching profile information:', error);
        });
    };
    
    
    removeSubscription = () => {
        const { userEmail } = this.state;
        fetch(API_Root + 'remove-subscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: userEmail,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Subscription status updated:', data);
        })
        .catch(error => {
            console.error('Error removing subscription:', error);
        });
    };
    delAssistantFiles = () => {
        fetch(API_Root + 'delfiles-assistant', {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json' // Set content type to JSON
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('delfiles-assistant response:', data);
        })
        .catch(error => {
            console.error('Error in deleteAssistantFiles:', error);
        });
    };
    handleLogin = () => {
        const username = this.state.username;
        const password = this.state.password;
    
        if (username === 'woyeradfghgh4#@#56456456' && password === 'woyera12456234dffgdfg1134##3') {
          this.setState({ isLoggedIn: true });
        } else {
          this.props.toggleToast({ show: true, type: "error", message: "Please enter both username and password." });
        }
    };
    
    handleInputChange = (event) => {
        const val = event.target.value;
        const elmName = event.target.name;

        this.setState({ [elmName]: val, emailValidationError: "" })
    };
    createNewChatSession = () => {
        const { userEmail } = this.state;
        if (!userEmail) {
            alert("Please login to create a new chat session.");
            return;
        }
    
        fetch(API_Root + 'create-chat-session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: userEmail })
        })
        .then(response => response.json())
        .then(data => {
            console.log('New chat session created:', data);
            this.setState({ 
                chatHistory: [],
                currentChatSessionId: data['chat_session_id'],
            });
            this.refreshChatSessions();
        })
        .catch(error => {
            console.error('Error creating new chat session:', error);
        });
    }
    refreshChatSessions = () => {
        this.chatSessionListRef.fetchChatSessions();
    }
    handleSendFeedback = (conversationId, isHelpful, comment) => {
        fetch(API_Root + 'record-feedback', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            conversation_id: conversationId,
            feedback: isHelpful,
            feedback_comments: comment,
          }),
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          this.props.toggleToast({ show: true, type: "success", message: "SUCCESS: Feedback sent successfully." });
        })
        .catch(error => {
          this.props.toggleToast({ show: true, type: "error", message: "ERROR: Failed to send feedback." });
        });
    };
    handleCopyResponse = async (responseText, conversationId) => {
        try {
            // Use the Clipboard API to copy the text
            await navigator.clipboard.writeText(responseText);
    
            // Prepare the analytics data
            const analyticsData = {
                actionType: "copy_response",
                chatSessionId: this.state.currentChatSessionId,
                conversationId: conversationId,
                copiedResponse: responseText,
            };
    
            // Make the POST request to the backend
            await fetch(API_Root + "store-analytics-info", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(analyticsData),
            });
    
            console.log("Copy action and analytics logging succeeded.");
        } catch (error) {
            console.error("Error handling copy response:", error);
        }
    };
    refreshChat = (prompt, conversationId) => {
        this.setState({ prompt, conversationId }, this.getChatResp);
    };
    getChatResp = (event) => {
        const answerText = hardcodedQA[this.state.prompt];
        if (answerText) {
            console.log("true")
            this.handleSampleButtonClick(this.state.prompt);
            this.setState({ 
                prompt: "" 
            });
            return;
        }
        const controller = new AbortController();
        const { signal } = controller;
        this.setState({  fetching: true, abortController: controller })

        const requestData = {
            "prompt": this.state.prompt,
            "chat_history": this.state.chatHistory,
            "sessionId": this.state.sessionId,
            "selectedModel": this.state.selectedModel,
            "pineconeIndex": this.state.pineconeIndex,
            "systemPrompt": this.state.systemPrompt,
            "promptType": this.state.promptType,
            "questionType": this.state.questionType,
            "email": this.state.userEmail,
            "conversation_id": this.state.conversationId,
            "chatSessionId": this.state.currentChatSessionId,
        };
        console.log(requestData)
        fetch(API_Root + "chat", {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json' // Set content type to JSON
            },
            body: JSON.stringify(requestData),
            signal: signal,
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            this.setState({
                answer: data['answer'],
                chatHistory: data['chat_history'],
                prompt: "",
                fetching: false,
                conversationId: 0,
                error_msg: data['error_msg'],
            });
        })
        .catch(error => {
            if (error.name === "AbortError") {
                console.log("Fetch aborted");
            } else {
                console.error('Error in getChatResp:', error);
                this.props.toggleToast({ show: true, type: "error", message: "ERROR: " + this.state.error_msg });
            }
            this.setState({ fetching: false });
        });
    };
    //function to cancel fetch 
    cancelFetch = () => {
        if (this.state.abortController) {
            this.state.abortController.abort(); // Abort the fetch request
            this.setState({ fetching: false, abortController: null }); // Reset the state
        }
    };
    handleSampleButtonClick = (promptText, fileList) => {
        const answerText = hardcodedQA[promptText];
        let source_documents = fileList.map(fileName => ({ content: "", source: fileName }));
        console.log(source_documents)
        if (answerText) {
            const mockConversationId = Date.now(); 
            
            const hardcodedEntry = {
                prompt: promptText,
                answer: answerText,
                file_names: source_documents, 
                conversation_id: mockConversationId 
            }

            // Append the constructed entry to chatHistory
            this.setState(prevState => ({
                chatHistory: [
                    ...prevState.chatHistory,
                    hardcodedEntry
                ],
            }));
            
            
            this.setState({ prompt: "" });
        } else {
            // Handle other prompts or actions
            console.log("Not a hardcoded prompt.");
        }
    };
    toggleDisplayBox = () => {
        this.setState((prevState) => ({
            isDisplayBoxExpanded: !prevState.isDisplayBoxExpanded,
        }));
    };
    handleChatSessionSelected = (conversations, chatSessionId) => {
        // Update the state with the fetched conversations
        this.setState({ 
            chatHistory: conversations,
            currentChatSessionId: chatSessionId,
        });
    };
    toggleSidebar = () => {
        this.setState((prevState) => ({
          isSidebarVisible: !prevState.isSidebarVisible,
        }));
    };

    handlePineconeIndexChange = (event) => {
        const pineconeIndex = event.target.value === 'old-index' ? 'old-index' : 'new-index';
        this.setState({ pineconeIndex });
    };

    toggleSubscriptionAlert = (show) => {
        this.setState({ showSubscriptionAlert: show });
    };
    render() {
        const { shouldRedirectToProfile, showSubscriptionAlert, pocAccess, allowedEmails } = this.state;
        // const allowedEmails = ['joseph@woyera.com', 'lizzaykim@gmail.com', 'cheezusk@gmail.com', 'ildiko@ildiko-almasi.com', 'adhaar@lovespreadsheets.com', 'mas10001@gmail.com', 'tsaunier@synergy-global.net', 'paul@shelburneconsulting.com', 'martin@birleyhia.co.uk'];
        const { userEmail } = this.state;

        console.log(this.state)
        
        if (shouldRedirectToProfile) {
            return <Redirect to="/profile" />;
        }
        const mainChatbarStyle = {
            width: this.state.isSidebarVisible ? '100%' : '100vw',
        };
        // Dropdown for question types
        const questionTypeDropdown = this.state.isQuestionTypeVisible ? (
            <select 
                className='question-type-dropdown'
                value={this.state.questionType}
                onChange={(e) => this.setState({ questionType: e.target.value })}
            >
                <option value="number_value">NUMBER/VALUE</option>
                <option value="structure_template">TEMPLATE</option>
                <option value="design_survey">DESIGN/SURVEY</option>
                <option value="gap_analysis">GAP ANALYSIS</option>
                <option value="general">GENERAL</option>
            </select>
        ) : null;
        return (
            <div className='grid-layout'>
                
                
                <div className='sidebar-toggle'>
                    
                    <span className="forward-arrow" onClick={this.toggleSidebar}></span>
                    <span className="forward-arrow" onClick={this.toggleSidebar}></span>
                </div>
                
                
                <sidebar style={{ display: this.state.isSidebarVisible ? 'block' : 'none' }}>
                    <div className='sidebar-container'>
                        <div className='sidebar-top-section'>
                            <div className='sidebar-header'>
                                <div className='sidebar-header-items'>
                                    <div className='logo' />
                                    <div className='logo-text'><Link to="/" style={{ color: '#3E826D' }}>myESRA</Link></div>
                                </div>
                                <div className='sidebar-close-button'>
                                    <span className="sidebar-forward-button" onClick={this.toggleSidebar}></span>
                                    <span className="sidebar-back-button" onClick={this.toggleSidebar}></span>
                                </div>
                            </div>
                            <button className='newchat-button' onClick={this.createNewChatSession} disabled={this.state.fetching || this.state.subscription === null || this.state.subscriptionActive === false}>New Chat</button>
                            <div className='session-container'>
                                <div className='chatsession-title'>PREVIOUS 30 DAYS</div>
                                <ChatSessionList ref={el => this.chatSessionListRef = el} onChatSessionSelected={this.handleChatSessionSelected} email={this.state.userEmail} />
                            </div>
                        </div>
                        <div className='sidebar-bottom-section'>
                            <div className='sep-line' />
                            <button className='sidebar-help-button'>
                                <div className='help-icon' />
                                <div className='help-text'><Link to="/faqs">Help</Link></div>
                            </button>
                            {pocAccess && (
                                <button className='sidebar-poc-button'>
                                    <div className='help-icon' />
                                    <div className='help-text'><Link to="/private">Private Chat</Link></div>
                                </button>
                            )}
                            {this.state.userEmail ? (
                                <Logout onLogout={this.handleLogout} email={this.state.userEmail} firstName={this.state.firstName} lastName={this.state.lastName} />
                            ) : (
                                <button className='sidebar-login-button' style={{ cursor: 'pointer', padding: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '13px' }} onClick={() => this.props.history.push('/login')}>
                                    Login Here
                                </button>
                            )}
                        </div>
                    </div>
                </sidebar>

                <main style={mainChatbarStyle}>
                    {(userEmail === "joseph@woyera.com" || userEmail === "elizabeth@woyera.com" || userEmail === "cheezusk@gmail.com") && (
                        <div className="model-selector" >
                            <select value={this.state.pineconeIndex} onChange={this.handlePineconeIndexChange}>
                                <option value="old-index">v1 Pinecone Index</option>
                                <option value="new-index">v2 Pinecone Index</option>
                            </select>
                        </div>
                    )}
                    {showSubscriptionAlert && (
                        <div className="subscription-alert">
                            <span><strong>WARNING:</strong> It seems you aren't subscribed! &nbsp;<Link to="/pricing" className="subscribe-link"> Click here to subscribe.</Link></span>
                        </div>
                    )}
                    <div className="chat-container">
                        {this.state.chatHistory.length === 0 && !this.state.fetching ? (
                            <div className='default-chat-container'>
                                <div className='default-chat-logo' />
                                <h1>What are you looking for?</h1>
                                <button onClick={() => this.handleSampleButtonClick("What is in the knowledge base?", [])}>
                                <h2>What is in the knowledge base?</h2>
                                Get to know myESRA™
                                </button>
                                <button onClick={() => this.handleSampleButtonClick("What are the main gaps between the expropriation legislation in North Macedonia and EBRDs Performance Requirement 5?", ["RPF_EBRD_2012_RepublicofMacedonia_Transport_RailwayCorridorVIIIEastern_MacedonianMinistryforTransportandCommunication_EN.pdf"])}>
                                <h2>What are the main gaps between</h2>
                                the expropriation legislation in North Macedonia and EBRD’s Performance Requirement 5?
                                </button>
                                <button onClick={() => this.handleSampleButtonClick("What is the total cost of the Masrik Solar project's LRP including the line items?", ["LRP_EAEnergyAdvisory_2019_Armenia_Energy_Masrik1SolarPower_FRVMasrikCJSC_EN.pdf"])}>
                                <h2>What is the total cost</h2>
                                of the Masrik Solar project's LRP including the line items?
                                </button>
                                <button onClick={() => this.handleSampleButtonClick("List the assessment criteria to identify indigenous peoples according to ADB.", ["IPPF_AsianDevelopmentBank_2023_India_WaterSupplyandSanitation_RajasthanSecondaryTownsDevelopment_GovernmentofRajasthan_EN.pdf", "RAP_AsianDevelopmentBank_2023_India_WastewaterManagement_JodhpurWastewaterWorksProject_RajasthanUrbanDrinkingWaterSewerageandInfrastructureCorporationLimited_EN.pdf", "RAP_AsianDevelopmentBank_2023_India_Infrastructure_RajasthanStormWaterDrainage_RajasthanUrbanDrinkingWaterSewerageandInfrastructureCorporationLimited_EN.pdf"])}>
                                <h2>List the assessment criteria</h2>
                                to identify indigenous peoples according to ADB.
                                </button>
                            </div>
                        ) : (
                            <>
                                {this.state.fetching && (
                                <>
                                    <div className="chat-assistant-container">
                                        <div className='profile-assistant-icon' />
                                        <div className='assistant-chatbox'>
                                            <div className='fetching-box'>
                                                <ThreeDots
                                                    color="#333"
                                                    height={30}
                                                    width={30}
                                                />
                                                <button onClick={this.cancelFetch} className="cancel-button">
                                                    Cancel
                                                </button>
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <div className="chat-user-container">
                                        <div className='user-chatbox'>
                                            <div className='user-text'>
                                                {this.state.prompt}
                                            </div>
                                        </div>
                                        <div className='profile-user-icon' />
                                    </div>
                                </>
                                )}
                                <QuestionAnswer
                                    chatHistory={this.state.chatHistory}
                                    onSendFeedback={this.handleSendFeedback}
                                    onRefreshChat={this.refreshChat}
                                    handleCopyResponse={this.handleCopyResponse}
                                />
                            </>
                        )}
                    </div>
                </main>
                <chatbar style={mainChatbarStyle}>
                    <div className='chatbar-container'>
                        <div className='chatbar-box'>
                            {questionTypeDropdown}
                            <textarea 
                            className='input-chat' 
                            placeholder='Ask a question' 
                            name="prompt"
                            value={this.state.prompt}
                            onChange={this.handleInputChange}
                            ref={this.textareaRef}
                            disabled={this.state.fetching}
                            />

                            <button className='button-chat' 
                                    onClick={this.getChatResp}
                                    disabled={this.state.fetching || this.state.subscription === null || this.state.subscriptionActive === false} 
                            >
                                <div className='send-icon' />
                            </button>
                        </div>
                    </div>
                </chatbar>
            </div>
            
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {toggleToast: toggleToast}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(AppIndex));