import firebase from 'firebase/app';
import 'firebase/auth';

// Initialize Firebase
const config = {
    apiKey: "AIzaSyA34mW0v8xHsT9MZ0KBlSUxiFoFf7TO-nY",
    authDomain: "test-almasi-chatbot.firebaseapp.com",
    databaseURL: "https://spreadsheet-api-f43dc.firebaseio.com",
    projectId: "test-almasi-chatbot",
    storageBucket: "test-almasi-chatbot.appspot.com",
    messagingSenderId: "120676106098",
    appId: "1:120676106098:web:e7a9182710c984bc9e67eb"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

const auth = firebase.auth();

export {
    auth,
};